<template>
  <div>
    <template v-for="(reply, i) in replies">
      <ReplyResponse
        :key="i"
        :reply="reply"
        :activityId="activityId"
        :baseResponse="baseResponse"
        :baseMorph="baseMorph"
        :baseMorphId="baseMorphId"
        :baseQuestionPosition="baseQuestionPosition"
        @toggleNotepad="$emit('toggleNotepad',$event)"
      >
      </ReplyResponse>
    </template>
  </div>
</template>

<script>
import ReplyResponse from "./ReplyResponse";

export default {
  name: "StreamReplyContainer",

  props: {
    generation: { type: Number, required: true },
    replies: { type: Array, required: true },
    activityId: { type: Number, required: true },
    baseResponse: { type: Number, required: true },
    baseMorph: { type: String, required: true },
    baseMorphId: { type: Number, required: true },
    baseQuestionPosition: { type: Number, required: false, default: 0 }
  },

  components: {
    ReplyResponse
  }
};
</script>

<template>
  <div
    @mouseenter="hovering = true"
    @mouseleave="hovering = false"
  >
    <div class="d-flex justify-content-start mb-5">
      <span
        class="label label-lg mr-4"
        :class="{ 'label-success' : netVotes >= 0, 'label-danger': netVotes < 0}"
        style="min-width:27px; min-height:27px"
      >
        {{ netVotes }}
      </span>
      <div class="w-100">
        <div :id="'response-container-' + response.id" class="w-100">
          <StreamTextMedia
            :title="response.title"
            :response="response.response"
            :media="attachments"
            :tags="response.activity_ideastorm_categories"
            :highlight="highlight"
          >
          </StreamTextMedia>
          <InlineEngagement
            ref="engagement"
            class="mt-5"
            type="ActivityIdeastormResponse"
            :sentiment="false"
            :reply="true"
            :favourite="true"
            :destroy="true"
            :notepad="true"
            :response="response"
            :activityId="activityId"
            :replyState="replyState"
            :hovering="(hovering && !hovering_replies) ? true : false"
            :baseResponse="baseResponse"
            @toggle_replies="toggle_replies()"
            @toggle_reply_input="toggle_reply_input(true)"
            @toggleNotepad="$emit('toggleNotepad',$event)"
          >
          </InlineEngagement>          
        </div>

        <Replies
          ref="replies"
          :activityId="activityId"
          :replies="response.replies"
          repliable_type="ActivityIdeastormResponse"
          :repliable_id="response.id"
          :replyState="replyState"
          :baseResponse="baseResponse"
          baseMorph="ActivityIdeastormResponse"
          :baseMorphId="response.id"
          @cancel-reply-input="replyState.showReplyInput = false"
          @hovering-replies="hovering_replies = $event"
          @toggleNotepad="$emit('toggleNotepad',$event)"
        >
        </Replies>
      </div>
    </div>
  </div>
</template>

<script>
import StreamTextMedia from "@/view/components/stream-responses/TextMedia";
import InlineEngagement from "@/view/components/stream-responses/InlineEngagement";
import Replies from "@/view/components/stream-responses/Replies";

export default {
  name: "streamResponseIdeastorm",

  props: {
    baseResponse: { type: Number, required: true },
    response: { type: Object, required: true },
    attachments: { type: Array, required: true },
    activityId: { type: Number, required: true },
    highlight: { type: String, required: false, default: null }
  },

  data() {
    return {
      hovering: false,
      hovering_replies: false,
      replyState: {
        showReplies: false,
        showReplyInput: false
      }
    }
  },

  components: {
    StreamTextMedia,
    InlineEngagement,
    Replies
  },

  methods: {
    toggle_replies: function() {
      if (this.replyState.showReplies) {
        this.toggle_reply_input(false);
      } else {
        this.replyState.showReplies = true;
      }
    },
    toggle_reply_input: function(bool) {
      this.replyState.showReplies = bool;
      this.replyState.showReplyInput = bool;
    }
  },

  watch: {
    replyCount: {
      handler(val) {
        if (val == 0) {
          this.toggle_reply_input(false);
        }
      },
      immediate: false
    }
  },

  computed: {
    netVotes: function() {
      let positiveVotes = this.response.votes.filter(function( vote ) {
        return vote.vote == 1;
      }).length;

      let negativeVotes = this.response.votes.filter(function( vote ) {
        return vote.vote == 0;
      }).length;

      return positiveVotes - negativeVotes;
    },
    replyCount: function() {
      return this.response.replies;
    }
  }
}
</script>
<template>
  <div>
    <template v-for="(chunk, c) in streamResults.responses">
      <div :key="c">
        <template v-for="(response) in chunk">
          <div :key="`${c}-${response.id}`" class="row d-flex justify-content-center my-5 mx-0">
            <StreamResponseDetails
              :response="response"
              class="col-2"
              @filter_participant_by="$emit('filter_participant_by',$event)"
              @filter_activity_by="$emit('filter_activity_by',$event)"
              @open_profile_modal="openProfileModal"
              @open_activity_modal="openActivityModal"
            >
            </StreamResponseDetails>
            <StreamResponseLayout
              class="col-7"
              :type="getComponentName(response.activity.system_activity_type_id)"
              :response="response"
              :schemaType="(response.activity.system_activity_type_id === 10) ? 2 : 1"
              :highlight="highlight"
              @toggleNotepad="$emit('toggleNotepad',$event)"
              @open-chart-modal="openChartModal"
            >
            </StreamResponseLayout>
            <div class="col-xxl-2"></div>
          </div>
        </template>
      </div>
    </template>

    <UserProjectProfile
      :userId="modals.userId"
      ref="userProjectProfileModal"
    >
    </UserProjectProfile>

    <ActivityDetails
      :activityId="modals.activityId"
      ref="activityDetailsModal"
    >
    </ActivityDetails>

    <QuestionChart
      :activityId="modals.activityId"
      :question="modals.question"
      :activity="modals.activity"
      :markupQuestion="modals.markupQuestion"
      ref="questionChartModal"
    >
    </QuestionChart>
  </div>
</template>

<script>
import { mapGetters } from  "vuex";

import StreamResponseDetails from "./stream_responses/Details";
import StreamResponseLayout from "./stream_responses/Layout";
import UserProjectProfile from "@/view/content/modals/UserProjectProfile";
import ActivityDetails from "@/view/content/modals/ActivityDetails";
import QuestionChart from "@/view/content/modals/QuestionChart";

export default {
  name: "StreamResponseContainer",

  props: {
    highlight: { type: String, required: false, default: null }
  },

  data() {
    return {
      modals: {
        userId: null,
        activityId: null,
        question: null,
        activity: null,
        markupQuestion: null
      }
    }
  },

  components: {
    StreamResponseDetails,
    StreamResponseLayout,
    UserProjectProfile,
    ActivityDetails,
    QuestionChart
  },

  methods: {
    getComponentName: function(activityTypeId) {
      switch (activityTypeId) {
        case 1:
          return "StreamResponseBlog";
        case 2:
          return "StreamResponseDiary";
        case 3:
          return "StreamResponseDiscussion";
        case 4:
          return "StreamResponseSurvey";
        case 5:
          return "StreamResponsePictureBook";
        case 6:
          return "StreamResponseIdeastorm";
        case 7:
          return "StreamResponseScreenRecording";
        case 8:
          return "StreamResponseMarkupLegacy";
        case 9:
          return "StreamResponsePoll";
        case 10:
          return "StreamResponseMarkup";
      }
    },
    openProfileModal: function(val) {
      this.modals.userId = val;
      this.$refs.userProjectProfileModal.$refs.modal.show();
    },
    openActivityModal: function(val) {
      this.modals.activityId = val;
      this.$refs.activityDetailsModal.$refs.modal.show();
    },
    openChartModal: function(val) {
      this.modals.activityId = val.activityId;
      this.modals.question = val.question;
      this.modals.activity = val.activity;
      this.modals.markupQuestion = (val.markupQuestion != undefined) ? val.markupQuestion : null;
      this.$refs.questionChartModal.$refs.modal.show();
    }
  },

  computed: {
    ...mapGetters([
      'streamResults'
    ])
  }
}
</script>
<template>
  <div>
    <b-dropdown
      :text="computedButtonText | stripHTML"
      variant="outline-primary"
      :class="calculatedClassList"
      size="sm"
      v-b-tooltip.hover.top
      :title="$t('REVIEW.STREAM.FILTER_ACTIVITIES_TOOLTIP')"
      no-caret
      :disabled="reloadingResponses"
    >
      <input
        v-model="search"
        type="text"
        :placeholder="$t('GENERAL.INPUT.SEARCH')"
        class="form-control form-control-solid py-3 px-5 mb-5"
        style="height:35px !important; margin-top:4px !important"
      />
      <div :style="'height:' + calculatedScrollHeight + 'px'">
        <vuescroll>
          <template v-for="(activity, i) in computedActivities">
            <b-form-group
              :key="'activity-' + ((i + 1) * resets)"
              class="mb-0 mx-2"
              :id="'activity-check-group' + activity.id"
              :label="activity.title | stripHTML"
              :label-sr-only="true"
              :label-for="'activity-check-group' + activity.id"
            >
              <b-form-checkbox
                :id="'activity-check' + activity.id"
                value="1"
                unchecked-value="0"
                size="lg"
                @change="onChange($event, activity)"
              >
              <div class="pt-1 mb-2">
                <i :class="icon(activity)" class="icon-sm d-inline"></i>
                <p class="pl-2 d-inline">{{ activity.title | stripHTML }}</p>
              </div>
              </b-form-checkbox>
            </b-form-group>
          </template>
        </vuescroll>
      </div>
      <p v-if="computedActivities.length == 0" class="mx-2">{{ $t('REVIEW.STREAM.NO_SEARCH_PLACEHOLDER') }}</p>
      <div class="w-100 d-flex justify-content-center mt-3">
        <a
          v-if="activityCount > 0"
          href="javascript:void(0)"
          @click="onReset()"
          class="text-center font-size-sm"
        >
          {{ $t('REVIEW.TEXT_ANALYSIS.RESET') }}
        </a>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from  "vuex";
import { mapMutations } from 'vuex'
import vuescroll from 'vuescroll';
import SystemIconHelper from "@/modules/together-helpers/helpers/SystemIconHelper";

export default {
  name: "ActivitySelect",

  props: {
    filter: { type: Object, required: true },
    reloadingResponses: { type: Boolean, required: true }
  },

  data() {
    return {
      search: "",
      checkedActivities: [],
      resets: 1
    }
  },

  components: {
    vuescroll
  },

  mounted() {
    this.onReset();
    this.setList(this.computedActivityFilterList);
  },

  methods: {
    icon: function(activity) {
      return SystemIconHelper.activityType(activity.activity_type, activity.recording_override);
    },
    onReset: function(initial = false) {
      this.filterReset('activities');
      this.resets = (initial) ? 1 : this.resets += this.activityList.length;  
    },
    onChange: function(event, activity) {
      let payload = { key: 'activities', id: activity.id, display: activity.title };
      (event == 1) ? this.filterAdd(payload) : this.filterStrip(payload);
    },
    setList: function(activities) {
      activities.forEach(function (activity) {
        document.getElementById('activity-check' + activity.id).click();
      });
    },
    ...mapMutations({
      filterAdd: 'addToFilter',
      filterStrip: 'stripFromFilter',
      filterReset: 'resetFilterList'
    }),
  },

  watch:{
    'filter.reset': function (newVal, oldVal)
    {
      if (oldVal == false && newVal == true) {
        this.onReset();
      }
    }
  },

  computed: {
    ...mapGetters([
      "activityList",
      "legacyActivityList",
      "streamFilter"
    ]),
    ...mapState({
      legacy: state => state.projectStream.legacy
    }),
    computedButtonText: function() {
      if (this.filter.activities.length == 0) {
        return `${this.$t('REVIEW.STREAM.FILTER_ACTIVITIES')}`;
      } else if (this.filter.activities.length == 1) {
        return this.filter.activities[0].display;
      } else {
        return this.filter.activities.length + ' ' + `${this.$t('REVIEW.STREAM.FILTER_ACTIVITIES')}`;
      }
    },
    activityCount: function() {
      return this.filter.activities.length;
    },
    calculatedClassList: function() {
      let classList = "";

      if (this.computedActivities.length == 0 || this.computedActivities.length == 1) {
        classList = classList + "sf-height-1";
      } else if (this.computedActivities.length > 6) {
        classList = classList + "sf-height-6";
      } else {
        classList = classList + "sf-height-" + this.computedActivities.length;
      }

      if (this.activityCount == 0) {
        classList = classList + " null";
      } else {
        classList = classList + " selected";
      }

      return classList;
    },
    calculatedScrollHeight: function() {
      if (this.computedActivities.length == 1 || this.computedActivities.length == 0) {
        return 28.75;
      } else if (this.computedActivities.length >= 6) {
        return 28.75 * 6;
      } else {
        return this.computedActivities.length * 28.75;
      }
    },
    computedActivities: function() {
      let refinedActivityList = [];

      if (this.legacy) {
        refinedActivityList = this.legacyActivityList.filter(function( activity ) {
          return activity.system_activity_type_id != 7 || (activity.system_activity_type_id == 7 && activity.recording_override == true)
        });
      } else {
        refinedActivityList = this.activityList.filter(function( activity ) {
          return activity.system_activity_type_id != 7 || (activity.system_activity_type_id == 7 && activity.recording_override == true)
        });
      }
      
      var self = this;
      if (self.search.length == 0) {
        return refinedActivityList;
      } else {
        return refinedActivityList.filter(function( activity ) {
          return activity.title.toLowerCase().includes(self.search.toLowerCase()) ||
          activity.activity_type.name.toLowerCase().includes(self.search.toLowerCase()) ||
          activity.activity_stage.name.toLowerCase().includes(self.search.toLocaleLowerCase())
        });
      }
    },
    computedActivityFilterList: function() {
      return this.streamFilter.activities;
    }
  },
}
</script>

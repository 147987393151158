<template>
  <div class="card-toolbar">
    <b-dropdown id="dropdown-dropleft" size="sm" dropleft text="Drop-Left" variant="primary-light" class="m-0" no-caret>
      <template #button-content>
        <i class="fas fa-bars p-0"></i>
      </template>
      <b-dropdown-item
        @click="toggleLegacy"
        v-if="hasLegacy"
        href="javascript:void(0)"
      >
        {{ (legacy) ? $t('REVIEW.STREAM.FILTER_CURRENT') : $t('REVIEW.STREAM.FILTER_LEGACY') }}
      </b-dropdown-item>
      <b-dropdown-item
        href="javascript:void(0)"
        @click="toggleFullscreen"
      >
        {{ focusModeText }}
      </b-dropdown-item>
      <b-dropdown-item
        v-if="currentUser.relation_to_project != 'observer'"
        href="javascript:void(0)"
        v-b-modal.report-modal-1
      >
        {{ $t('GENERAL.TEXT.EXPORT') }}
      </b-dropdown-item>
    </b-dropdown>

    <ReportModal
      v-if="currentUser.relation_to_project != 'observer'"
      :id="1"
      ref="stream_export_modal"
      scope="ActivitySummary"
      :users="streamFilter.participants"
      :dateRange="streamFilter.dateRange"
      :activities="streamFilter.activities"
      :segments="streamFilter.segments"
      :segmentOption="streamFilter.segment_option"
      :tags="streamFilter.tags"
      :media="streamFilter.medias"
      :favourites="streamFilter.favourite"
      :notes="streamFilter.note"
    >
    </ReportModal>

  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapMutations } from 'vuex'
import { mapGetters } from  "vuex";

import ReportModal from "@/view/content/reports/ReportModal.vue";

export default {
  name: "StreamOptions",

  data() {
    return {
      fullscreenEnabled: false
    }
  },

  components: {
    ReportModal
  },

  beforeDestroy() {
    this.disableFullScreen();
  },

  methods: {
    ...mapMutations({
      setLegacy: 'setLegacy'
    }),
    toggleFullscreen: function() {
      if (this.fullscreenEnabled) {
        this.disableFullScreen();
      } else {
        this.enableFullScreen();
      }
    },
    toggleLegacy: function() {
      let legacy = (this.legacy) ? 0 : 1;
      this.setLegacy(legacy)
    },
    disableFullScreen: function() {
      this.fullscreenEnabled = false;
      document.body.classList.remove("fullscreen");
    },
    enableFullScreen: function() {
      this.fullscreenEnabled = true;
      document.body.classList.add("fullscreen");
    }
  },

  computed: {
    ...mapState({
      legacy: state => state.projectStream.legacy
    }),
    ...mapGetters([
      "currentUser",
      "streamFilter",
      "projectInfo"
    ]),
    focusModeText: function() {
      if (this.fullscreenEnabled) {
        return "Disable Full Screen"
      } else {
        return "Enable Full Screen"
      }
    },
    hasLegacy: function() {
      return (this.projectInfo.legacy_data_stack != null && this.projectInfo.legacy_project_id != null) ? true : false;
    }
  }
}
</script>
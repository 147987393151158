<template>
  <div
    id="review-stream-container"
    class="col-xl-12 px-0"
  >

    <div
      id="review-filter-container"
      v-if="loadingComplete && !error.active"
      class="card card-custom card-stretch gutter-b"
    >
      <div class="card-header border-0 py-2 px-5">
        <StreamFilter
          ref="stream_filter"
          :reloadingResponses="pageLoader.reloadingResponses"
          :filter="$v.filter"
          @filter_applied="applyFilter"
          @reset_triggered="triggerReset"
          @date_filter_removed="filter.dateRange = { startDate: null, endDate: null }"
          @segment_option_changed="filter.segment_option = $event"
          @tag_filter_changed="filter.tags = removeFilterDuplicates($event,item => item.id)"
          @media_filter_changed="filter.medias = removeFilterDuplicates($event,item => item.id)"
          @date_filter_changed="dateFilterChanged($event)"
        >
        </StreamFilter>
        <StreamOptions></StreamOptions>
      </div>

      <div
        v-if="legacy"
        class="card-header bg-warning border-0 py-0 px-5 d-flex justify-content-center align-items-center"
      >
        <span class="font-weight-bold">Viewing Legacy Data</span>
      </div>
    </div>

    <b-spinner
      v-if="loadingComplete == false && !error.active"
      variant="primary"
      label="Spinning"
      class="card-loader"
    >
    </b-spinner>

    <StreamAggregateContainer
      v-if="loadingComplete && !error.active && streamResults.aggregated.length > 0"
      @filter_participant_by="fastFilterParticipant"
      @toggleNotepad="toggleNotepad"
      class="mt-15"
    >
    </StreamAggregateContainer>

    <div v-if="loadingComplete" class="row d-flex justify-content-center mb-5 mx-0">
      <div class="col-xxl-2"></div>
      <StreamSort
        class="col-xxl-7"
        :pageLoader="pageLoader"
        :loadingComplete="loadingComplete"
      >
      </StreamSort>
      <div class="col-xxl-2"></div>
    </div>

    <StreamResponseContainer
      v-if="loadingComplete && !error.active"
      :highlight="highlight"
      @filter_participant_by="fastFilterParticipant"
      @filter_activity_by="fastFilterActivity"
      @toggleNotepad="toggleNotepad"
    >
    </StreamResponseContainer>

    <b-spinner
      v-observe-visibility="visibilityChanged"
      v-if="loadingComplete == true && !pageLoader.noMoreResponses"
      variant="primary"
      label="Spinning"
      class="card-loader"
    >
    </b-spinner>

    <a ref="notepad_toggle" id="notepad_toggle" style="display:none !important">hidden notepad toggle</a>
    <div
      id="notepad_container"
      ref="notepad_container"
      class="offcanvas offcanvas-right p-7"
    >

      <NotepadContainer
        ref="notepad"
        style="height:100%"
      >
      </NotepadContainer>
    </div> 
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from  "vuex";
import { mapMutations } from 'vuex'
import { mapActions } from "vuex";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import { POPULATE_PROJECT_USERS_LIST, POPULATE_LEGACY_PROJECT_USERS_LIST } from "@/core/services/store/project/project_users.module";
import { POPULATE_PROJECT_SEGMENTATION_LIST, POPULATE_LEGACY_PROJECT_SEGMENTATION_LIST } from "@/core/services/store/project/project_segmentations.module";
import {
  POPULATE_PROJECT_TAG_LIST,
  POPULATE_PROJECT_CUSTOM_TAG_LIST,
  POPULATE_LEGACY_PROJECT_CUSTOM_TAG_LIST
} from "@/core/services/store/project/project_tags.module";
import { POPULATE_ACTIVITY_LIST, POPULATE_LEGACY_ACTIVITY_LIST } from "@/core/services/store/activity/activity.module";
import {
  POPULATE_RESPONSE_LIST,
  CLEAR_STREAM_RESULTS
} from "@/core/services/store/project/project_stream.module";

import StreamSort from "./stream/StreamSort";
import StreamFilter from "./stream/StreamFilter";
import StreamOptions from "./stream/StreamOptions";
import StreamAggregateContainer from "./stream/StreamAggregateContainer";
import StreamResponseContainer from "./stream/StreamResponseContainer";
import NotepadContainer from "@/view/components/stream-responses/engagement/notepad/NotepadContainer";

import LoadingCompleteHelper from "@/helpers/LoadingCompleteHelper";
import NotepadOffcanvas from "@/assets/js/layout/extended/notepad.js";

export default {
  mixins: [validationMixin],

  name: "Stream",

  data() {
    return {
      error: {
        active: false,
        message: ""
      },
      highlight: null
    }
  },

  validations: {
    filter: {
      sort: { required },
      dateRange: {
        startDate: {},
        endDate: {}
      }
    }
  },

  components: {
    StreamSort,
    StreamFilter,
    StreamOptions,
    StreamAggregateContainer,
    StreamResponseContainer,
    NotepadContainer
  },

  mounted() {
    let self = this;
    this.loadPhase1(function() {
      self.loadPhase2(function() {
        self.fetchResponses();
      });
    });
  },

  destroyed() {
    this.filter.preLoading = 0;
    this.filter.search = "";
    this.filter.new = false;
    this.filter.favourite = false;
    this.filter.note = false;
    this.filter.dateRange.startDate = null;
    this.filter.dateRange.endDate = null;
    this.filter.segment_option = 1;
    this.filter.sort = 1;
    this.filter.questions = [];
    this.pageLoader.offset = 1;
    this.legacy = 0;
  },

  methods: {
    ...mapMutations({
      resetStream: 'resetStreamResults',
      setFilter: 'setFilterReset',
      setLegacy: 'setLegacy',
      setEngagementTagList: "setEngagementTagList"
    }),

    loadPhase1: function(callback) {
      NotepadOffcanvas.init(this.$refs["notepad_container"]);
      this.resetStream();
      this.setLegacy(0);
      this.pageLoader.componentsCompleted += 2;
      callback();
    },

    loadPhase2: function(callback) {
      this.fetchProjectUsers();
      this.fetchActivities();
      this.fetchProjectSegmentations();
      this.fetchProjectTags();
      this.fetchCustomTags();
      this.fetchEngagementTags();

      if (this.$route.params.id != undefined) {
        this.filter.id = this.$route.params.id;
      };
      
      callback();
    },

    clearFilterResults: function() {
      this.$store.dispatch(CLEAR_STREAM_RESULTS)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
    },
    applyFilter: function() {
      this.filter.id = null;
      this.$store.dispatch(CLEAR_STREAM_RESULTS)
        .then(() => {
          this.pageLoader.offset = 1;
          this.fetchResponses(this.filter);
        })
    },
    visibilityChanged: function(visible) {
      if (visible && !this.pageLoader.fetchingMoreResponses) {
        this.fetchResponses(this.filter);
      }
    },
    toggleNotepad: function(val) {
      this.$refs.notepad.setTab(val.tab);
      this.$refs.notepad.setMorph(val.morphType, val.morphId, val.activityId, (val.baseResponse != undefined) ? val.baseResponse : null);
      this.$refs.notepad_toggle.click();
    },
    triggerReset: function() {
      this.filter.preLoading = 0;
      this.$store.dispatch(CLEAR_STREAM_RESULTS)
        .then(() => {
          this.pageLoader.reloadingResponses = true;
          this.filter.id = null;
          this.filter.reset = true;
          this.filter.search = "";
          this.filter.new = false;
          this.filter.favourite = false;
          this.filter.note = false;
          this.filter.dateRange.startDate = null;
          this.filter.dateRange.endDate = null;
          this.filter.segment_option = 1;
          this.filter.sort = 1;
          this.filter.questions = [];
          this.filter.activities = [];
          this.pageLoader.offset = 1;

          this.filter = JSON.parse(JSON.stringify(this.filterReset));
          this.pageLoader.offset = 1;

          this.fetchResponses();
        })
    },
    fetchProjectUsers: function() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          x_filter_roles: [1],
          x_filter_status: [1],
          projectProfiles: 'with',
          assignedProjectInvites: 'with',
          paginate: false
        }
      };

      this.$store
        .dispatch(POPULATE_PROJECT_USERS_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project users list, please check back later or contact the helpdesk";
        })
    },
    fetchActivities: function() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          activityType: 'with',
          activityStage: 'with',
          paginate: false
        }
      };

      this.$store
        .dispatch(POPULATE_ACTIVITY_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the activity list, please check back later or contact the helpdesk";
        })
    },
    fetchProjectSegmentations: function() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          projectSegments: 'with',
          paginate: false
        }
      };

      this.$store
        .dispatch(POPULATE_PROJECT_SEGMENTATION_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project segmentations list, please check back later or contact the helpdesk";
        });
    },
    fetchProjectTags: function() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          paginate: false
        }
      };

      this.$store
        .dispatch(POPULATE_PROJECT_TAG_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project tags list, please check back later or contact the helpdesk";
        })
    },
    fetchCustomTags: function() {
      if (this.legacy) {
        let payload = {
          projectId: this.projectInfo.id
        };

        this.$store
          .dispatch(POPULATE_LEGACY_PROJECT_CUSTOM_TAG_LIST, payload)
          .then(() => {
            this.pageLoader.componentsCompleted++;
          })
          .catch(() => {
            this.error.active = true;
            this.error.message =
              "We're having some issues retrieving the legacy project tags list, please check back later or contact the helpdesk";
          })
      } else {
        let payload = {
          projectId: this.projectInfo.id
        };

        this.$store
          .dispatch(POPULATE_PROJECT_CUSTOM_TAG_LIST, payload)
          .then(() => {
            this.pageLoader.componentsCompleted++;
          })
          .catch(() => {
            this.error.active = true;
            this.error.message =
              "We're having some issues retrieving the project tags list, please check back later or contact the helpdesk";
          })
      }
    },
    fetchEngagementTags: function() {
      let payload = {
        route: `api/v1/projects/${this.projectInfo.id}/engagement_interactions`,
        filters: {
          system_interaction_type_id: 5
        },
        params: {
          paginate: false
        }
      };

      this.genericResource(payload).then((res) => {
        this.setEngagementTagList(res._embedded.project_interactions);
      })
    },
    fetchResponses: function(filter = this.filter) {
      filter = JSON.parse(JSON.stringify(filter));

      this.highlight    = null;
      let newHighlight = (filter.search.length > 0) ? filter.search : null;

      this.pageLoader.noMoreResponses = false;
      this.pageLoader.reloadingResponses = true;
      this.pageLoader.fetchingMoreResponses = true;

      filter.search = (filter.search.length > 0) ? encodeURIComponent(filter.search) : "";
      filter.favourite_local = (filter.favourite) ? 1 : 0;
      filter.note_local = (filter.note) ? 1 : 0;
      filter.new_local = (filter.new) ? 1 : 0;
      filter.offset = this.pageLoader.offset;

      let payload = {
        projectId: this.projectInfo.id,
        params: filter
      };

      this.$store
        .dispatch(POPULATE_RESPONSE_LIST, payload)
        .then((res) => {
          this.pageLoader.componentsCompleted++;
          this.pageLoader.offset+=1;

          if (res.length != 10 ) {
            this.pageLoader.noMoreResponses = true;
          }

          this.highlight = newHighlight;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the response list, please check back later or contact the helpdesk";
        })
        .finally(() => {
          this.pageLoader.reloadingResponses = false;
          this.pageLoader.fetchingMoreResponses = false;
          this.filter.reset = false;
        })
    },
    fastFilterParticipant: function(val) {
      this.$store.dispatch(CLEAR_STREAM_RESULTS)
        .then(() => {
          let filter = this.filter;
          filter.participants = [val];
          this.pageLoader.offset = 1;
          this.fetchResponses(filter);
          this.$refs.stream_filter.$refs.participant_select.setList([val]);
        })
    },
    fastFilterActivity: function(val) {
      this.$store.dispatch(CLEAR_STREAM_RESULTS)
        .then(() => {
          let filter = this.filter;
          filter.activities = [val];
          this.pageLoader.offset = 1;
          this.fetchResponses(filter);
          this.$refs.stream_filter.$refs.activity_select.setList([val]);
        })
    },
    removeFilterDuplicates: function(data, key) {
      return [
        ...new Map(data.map(item => [key(item), item])).values()
      ]
    },
    dateFilterChanged(dates) {
      this.filter.dateRange = dates.utcDates;
    },
    ...mapActions({
      genericResource: "genericResource"
    })
  },

  watch: {
    selectedSort: {
      handler() {
        this.applyFilter();
      }
    },
    legacy: {
      handler() {
        this.clearFilterResults();
        this.fetchProjectUsers();
        this.fetchActivities();
        this.fetchProjectSegmentations();
        this.fetchCustomTags();
        this.pageLoader.offset = 1;
        this.fetchResponses();
      }
    }
  },

  computed: {
    ...mapGetters([
      'projectInfo',
      'activityStats',
      'projectRooms',
      'streamFilter',
      'streamResults'
    ]),
    ...mapState({
      pageLoader: state => state.projectStream.loader,
      filter: state => state.projectStream.filter,
      filterReset: state => state.projectStream.filterReset,
      legacy: state => state.projectStream.legacy
    }),
    loadingComplete: function() {
      return LoadingCompleteHelper.loadingComplete(this.pageLoader, this.error);
    },
    selectedSort: function() {
      return this.filter.sort;
    },
    filterActive: function() {
      if (
        this.streamFilter.search.length > 0 ||
        this.streamFilter.new ||
        this.streamFilter.dateRange.startData != null ||
        this.streamFilter.dateRange.endData != null ||
        this.streamFilter.participants.length > 0 ||
        this.streamFilter.activities.length > 0 ||
        this.streamFilter.questions.length > 0 ||
        this.streamFilter.segments.length > 0 ||
        this.streamFilter.segment_option != 1 ||
        this.streamFilter.tags.length > 0 ||
        this.streamFilter.medias.length > 0 ||
        this.streamFilter.favourite ||
        this.streamFilter.note
      ) return true; else {
        return false;
      }
    }
  }
};
</script>

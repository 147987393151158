<template>
  <b-modal
    size="xl"
    ref="modal"
    :hide-footer="!loadingComplete"
    :hide-header="!loadingComplete"
  >
    <template #modal-title>
      <AnonymisedUsername :user="projectUser"></AnonymisedUsername>
      <span :class="calculatedStatus.class">
        {{ calculatedStatus.text }}
      </span>
    </template>
    <template #modal-footer>
      <div class="d-flex justify-content-end">
        <button
          @click="$refs.modal.hide()"
          class="btn btn-outline-primary mr-3"
        >
          Cancel
        </button>
        <router-link
          v-if="currentUser.menu_items.includes('AddEntity') && !legacy"
          tag="a"
          :to="{ name: 'edit_project_user', params: { id: userId } }"
          class="btn btn-primary"
        >
          Edit User
        </router-link>
      </div>
    </template>
    <b-spinner
      v-if="loadingComplete == false"
      variant="primary"
      label="Spinning"
      class="card-loader"
    >
    </b-spinner>
    <div v-if="loadingComplete">
      <div class="d-flex justify-content-between">
        <div class="col-7 pl-5 pr-10" style="border-right: 1px solid #ebf3eb">
          <!--start::Basic User Information-->
          <div class="d-flex justify-content-start">
            <UserThumbnail :user="projectUser" size="75"></UserThumbnail>
            <div class="ml-3">
              <p class="mb-1 font-weight-bold">
                <AnonymisedUsername :user="projectUser"></AnonymisedUsername>
              </p>
              <p class="mb-2">
                <AnonymisedEmail
                  :user="projectUser"
                  :profile="projectUser.projectProfile"
                >
                </AnonymisedEmail>
              </p>
              <div class="d-flex justify-content-start">
                <p class="mb-0 text-muted mr-2">Last Login:</p>
                <GenericDatetimeDisplay
                  v-if="projectUser.last_login != null"
                  class="text-muted"
                  :long="true"
                  :date="projectUser.last_login"
                  :lastUpdated="true"
                  :relative="true"
                >
                </GenericDatetimeDisplay>
                <span v-else class="text-muted">Never</span>
              </div>
            </div>
          </div>
          <!--end::Basic User Information-->
          <div class="pb-5 pt-10">
            <!--start::Country Code-->
            <div
              v-if="calculatesProjectProfileCounter != null"
              class="d-flex justify-content-start py-1"
            >
              <p class="font-weight-bold mr-2 mb-0">Country:</p>
              <p class="mb-0">{{ calculatesProjectProfileCounter }}</p>
            </div>
            <!--end::Country Code-->
            <!--start::Timezone Code-->
            <div class="d-flex justify-content-start py-1">
              <p class="font-weight-bold mr-2 mb-0">Timezone:</p>
              <p class="mb-0">{{ projectUser.timezone.name }}</p>
            </div>
            <!--end::Timezone Code-->
            <!--start::Language Code-->
            <div class="d-flex justify-content-start py-1">
              <p class="font-weight-bold mr-2 mb-0">Language:</p>
              <p class="mb-0">{{ projectUser.language.name }}</p>
            </div>
            <!--end::Language Code-->
          </div>
          <!--start::External Reference-->
          <div
            v-if="calculatedExternalReference != null"
            class="d-flex justify-content-start py-5"
          >
            <p class="font-weight-bold mr-2 mb-0">External Reference:</p>
            <p class="mb-0">{{ calculatedExternalReference }}</p>
          </div>
          <!--end::External Reference-->
          <!--start::Note-->
          <div v-if="calculatedNote != null" class="py-5">
            <p class="font-weight-bold mr-2 mb-1">Notes:</p>
            <p class="mb-0">{{ calculatedNote }}</p>
          </div>
          <!--end::Note-->
        </div>
        <div class="col-5 pl-10 pr-5">
          <p class="font-weight-bold mb-0">Segments</p>
          <p class="mb-3">{{ calculatedSegmentText }}</p>
          <ul class="px-10" v-if="projectUser.active_segments.length > 0">
            <template v-for="(segment, i) in projectUser.active_segments">
              <li :key="i">{{ segment.name }}</li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapState, mapMutations } from "vuex";

import { POPULATE_PROJECT_USER_INFO } from "@/core/services/store/project/project_users.module";
import { POPULATE_LEGACY_PROJECT_USER_INFO } from "@/core/services/store/project/project_users.module";

import UserThumbnail from "@/modules/together-helpers/components/generic-displays/GenericUserThumbnail";
import GenericDatetimeDisplay from "@/modules/together-helpers/components/generic-displays/GenericDatetimeDisplay";

import LoadingCompleteHelper from "@/helpers/LoadingCompleteHelper";

export default {
  name: "UserProjectProfile",

  props: {
    userId: { type: Number, required: false },
  },

  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 1,
      },
      error: {
        active: false,
        message: "",
      },
    };
  },

  components: {
    UserThumbnail,
    GenericDatetimeDisplay,
  },

  methods: {
    fetchUserInfo: function (userId) {
      let payload = {
        route: `api/v1/projects/${this.projectInfo.id}/users/${userId}`,
        relations: {
          timezone: "with",
          language: "with",
          country: "with",
        },
      };
      this.$store
        .dispatch(GENERIC_RESOURCE, payload)
        .then((res) => {
          this.setProjectUsersInfo(res);
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the user information, please check back later or contact the helpdesk";
        });
    },
    fetchLegacyUserInfo: function (userId) {
      let payload = {
        projectId: this.projectInfo.id,
        userId: userId,
      };
      this.$store
        .dispatch(POPULATE_LEGACY_PROJECT_USER_INFO, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the legacy user information, please check back later or contact the helpdesk";
        });
    },
    ...mapMutations({
      setProjectUsersInfo: "setProjectUsersInfo",
    }),
  },

  watch: {
    userId: {
      handler(val) {
        if (val != null) {
          this.pageLoader.componentsCompleted = 0;
          if (this.legacy) {
            this.fetchLegacyUserInfo(val);
          } else {
            this.fetchUserInfo(val);
          }
        }
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters([
      "projectInfo",
      "systemCountries",
      "projectUser",
      "currentUser",
    ]),
    ...mapState({
      legacy: (state) => state.projectStream.legacy,
    }),
    loadingComplete: function () {
      return LoadingCompleteHelper.loadingComplete(this.pageLoader, this.error);
    },
    calculatesProjectProfileCounter: function () {
      let code = (this.computedInvite != null) ? this.computedInvite.system_country_code : this.computedProfile.system_country_code;
      let country = this._.find(this.systemCountries, function (o) {
        return (o.code == code);
      });

      return (country === undefined) ? null : country.name;
    },
    calculatedStatus: function () {
      let status = {};

      if (this.projectUser.suspended) {
        status.text = "Blocked";
        status.class = "label label-danger label-pill label-inline ml-1";
      } else if (this.projectUser.assigned_project_invites.length > 0) {
        status.text = "Invited";
        status.class = "label label-warning label-pill label-inline ml-1";
      } else {
        status.text = "Subscribed";
        status.class = "label label-success label-pill label-inline ml-1";
      }

      return status;
    },
    calculatedExternalReference: function () {
      if (this.projectUser.project_profiles.length > 0) {
        return this.projectUser.project_profiles[0].external_reference;
      } else if (this.projectUser.assigned_project_invites.length > 0) {
        return this.projectUser.assigned_project_invites[0].external_reference;
      } else {
        return null;
      }
    },
    calculatedNote: function () {
      if (this.projectUser.project_profiles.length > 0) {
        return this.projectUser.project_profiles[0].notes;
      } else if (this.projectUser.assigned_project_invites.length > 0) {
        return this.projectUser.assigned_project_invites[0].notes;
      } else {
        return null;
      }
    },
    calculatedSegmentText: function () {
      if (this.projectUser.project_profiles.length == 0) {
        return "This user will be assigned to segments once their profile has been completed";
      } else if (this.projectUser.active_segments.length > 0) {
        return "This user has been assigned to the below segments";
      } else {
        return "This user has not been assigned to any segments";
      }
    },
    computedInvite: function() {
      const self = this;
      let invites = this.projectUser.assigned_project_invites.filter(function(projectInvite) {
        return projectInvite.project_id = self.projectInfo.id;
      });

      return (invites.length == 0) ? null : invites[0]; 
    },
    computedProfile: function() {
      const self = this;
      let invites = this.projectUser.project_profiles.filter(function(projectProfile) {
        return projectProfile.project_id = self.projectInfo.id;
      });

      return (invites.length == 0) ? null : invites[0]; 
    }
  },
};
</script>

<template>
  <b-modal
    size="xl"
    ref="modal"
    hide-footer
    @shown="resetModal()"
  >
    <template #modal-title>
      <p
        v-html="calculatedTitle"
        class="question-modal-title ml-2 mb-0"
      />
      <div
        v-if="activity.system_activity_type_id == 8"
        class="d-flex justify-content-start question-modal-subtitle"
      >
        <small class="ml-2 mb-0">
          Question:
        </small>
        <small class="ml-2 mb-0" v-html="calculatedMarkupQuestionTitle" />
      </div>
    </template>
    
    <div class="row d-flex justify-content-end mx-5">
      <amChartsExport
        v-if="chart != null && activity.system_activity_type_id != 8 && currentUser.relation_to_project != 'observer'"
        position="left"
        :dataTypes="['png','jpg','svg','json','csv']"
        :chart="chart"
        :title="calculatedDownloadTitle"
      >
      </amChartsExport>
    </div>

    <component
      v-if="surveyResponseInfo != null && activity.system_activity_type_id != 8"
      v-show="chart != null"
      ref="chartContainer"
      :is="chartComponent"
      :loadingComplete="loadingComplete"
      :chartData="surveyResponseInfo"
      @chart-generated="chart = $event"
    />

    <div v-else-if="flattenedMarkupPinDataArray != null && activity.system_activity_type_id == 8">
      <div
        v-if="showMarkupToggle"
        class="d-flex justify-content-center w-100 my-10"
      >
        <div class="btn-group" role="group">
          <button
            @click="markupViewType = 1"
            type="button"
            class="btn btn-sm"
            :class="{ 'btn-outline-primary' : markupViewType == 2, 'btn-primary' : markupViewType == 1}"
          >
            Heat Map
          </button>
          <button
            @click="markupViewType = 2"
            type="button"
            class="btn btn-sm"
            :class="{ 'btn-outline-primary' : markupViewType == 1, 'btn-primary' : markupViewType == 2}"
          >
            Pins
          </button>
        </div>
      </div>


      <component
        :is="(markupViewType == 2 || showMarkupToggle === false) ? 'ResponseAggregatedPins' : 'ResponseHeatmap'"
        :index="activity.id"
        :loadingComplete="true"
        :chartData="flattenedMarkupPinDataArray.data"
        :activityId="activity.id"
        :response="flattenedMarkupPinDataArray"
        :media="[flattenedMarkupPinDataArray.data.stimuli[0].projectAsset]"
        :questions="[]"
        :aggregated="true"
      />
    </div>

    <b-spinner
      v-else
      variant="primary"
      label="Spinning"
      class="card-loader"
    >
    </b-spinner>
  </b-modal>
</template>

<script>
import {
  mapGetters,
  mapMutations
} from  "vuex";

import LoadingCompleteHelper from "@/helpers/LoadingCompleteHelper";

import { POPULATE_SURVEY_RESPONSE_INFO } from "@/core/services/store/activity/survey_response.module";

import ResponseChartMultipleChoice from "@/view/content/chart/MultipleChoice";
import ResponseChartSingleChoice from "@/view/content/chart/SingleChoice";
import ResponseChartRanking from "@/view/content/chart/Ranking";
import amChartsExport from "@/view/components/AMChartExport";
import ResponseHeatmap from "@/view/content/chart/MarkupHeatmap";
import ResponseAggregatedPins from "@/view/components/stream-responses/Markup.vue";

export default {
  name: 'QuestionChart',

  props: {
    activityId: { type: Number, required: false },
    question: { type: Object, required: false },
    activity: { type: Object, required: false },
    markupQuestion: { type: Number, required: false }
  },

  data() {
    return {
      pageLoader: {
        componentsRequired: 0,
        componentsCompleted: 0
      },
      error: {
        active: false,
        message: ""
      },
      chartData: {},
      chart: null,
      markupViewType: 1
    }
  },

  components: {
    ResponseChartMultipleChoice,
    ResponseChartSingleChoice,
    ResponseChartRanking,
    amChartsExport,
    ResponseHeatmap,
    ResponseAggregatedPins
  },

  methods: {
    resetModal: function() {
      this.chart = null;
      this.setSurveyResponseInfo(null);
      this.fetchChartData();
    },
    fetchChartData: function() {
      let payload = {
        activityId: this.activityId,
        activity_type: this.chartComponent,
        questionId: (this.question == null) ? null : this.question.id
      };

      this.$store
        .dispatch(POPULATE_SURVEY_RESPONSE_INFO, payload)
        .then(() => {
          this.$refs['chartContainer'].generateChart();
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the survey data, please check back later or contact the helpdesk";
        })
    },
    ...mapMutations({
      setSurveyResponseInfo: "setSurveyResponseInfo",
    })
  },

//   watch: {
//     chartComponent: {
//       handler() {
//         this.fetchChartData();
//       },
//       deep: true,
//       immediate: false
//     }
//   },

  computed: {
    ...mapGetters([
      'surveyResponseInfo',
      'currentUser'
    ]),
    loadingComplete: function() {
      return LoadingCompleteHelper.loadingComplete(this.pageLoader, this.error);
    },
    activityObject: function() {
      return {
        activityId: this.activityId,
        question: this.question
      }
    },
    calculatedTitle: function() {
      if (this.activity.system_activity_type_id == 8) {
        return this.activity.title;
      } else {
        return (this.question == null) ? null : this.question.question;
      }
    },
    calculatedDownloadTitle: function() {
      let baseTitle = `chart_${this.activityId}_${this.question.id}`;

      let suffixTitle = null;

      switch (this.question.system_answer_type_id) {
        case 2:
          suffixTitle = "multiple_choice";
          break;
        case 3:
          suffixTitle = "single_choice";
          break;
        case 5:
          suffixTitle = "ranking";
          break;
      };

      return `${baseTitle}_${suffixTitle}`;
    },
    chartComponent: function() {
      let component = null;

      if (this.question != null) {
        switch (this.question.system_answer_type_id) {
          case 2:
            component = "ResponseChartMultipleChoice"
            break;
          case 3:
            component = "ResponseChartSingleChoice"
            break;
          case 5:
            component = "ResponseChartRanking"
            break;
        }
      } else if (this.activity.system_activity_type_id == 8) {
          component = "ResponseChartMarkup";
      } else {
        component = null;
      }

      return component;
    },
    flattenedMarkupPinDataArray: function() {
      if (this.surveyResponseInfo == null) {
        return null;
      } else if (this.markupQuestion != null) {
        if (this.markupQuestion == 0) {
          let baseQuestion = this.surveyResponseInfo.questions[0];
          let iterationCount = 0;

          this.surveyResponseInfo.questions.forEach(function(question) {
            if (iterationCount > 0) {
              baseQuestion.data.responses = baseQuestion.data.responses.concat(question.data.responses);
            }
            iterationCount++;
          });

          baseQuestion.markup_question_responses = baseQuestion.data.responses;

          return baseQuestion;
        } else {
          const self = this;
          const filteredQuestion = this.surveyResponseInfo.questions.filter(function(question) {
            return question.id == self.markupQuestion;
          });

          if (filteredQuestion.length == 0) {
            return null;
          } else {
            let refinedQuestion = filteredQuestion[0];
            refinedQuestion.markup_question_responses = refinedQuestion.data.responses;

            return refinedQuestion;
          }
        }
      } else {
        return null;
      }
    },
    calculatedMarkupQuestionTitle: function() {
      if (this.markupQuestion == 0) {
        return "All Questions"
      } else {
        if (this.flattenedMarkupPinDataArray == null) {
          return "";
        } else {
          return this.flattenedMarkupPinDataArray.question_short;
        }
      }
    },
    showMarkupToggle: function() {
      if (
        this.activity.system_activity_type_id == 8 &&
        this.flattenedMarkupPinDataArray != null &&
        this.flattenedMarkupPinDataArray.data.stimuli != undefined &&
        this.flattenedMarkupPinDataArray.data.stimuli[0].projectAsset.type == 2
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style>
  .question-modal-title p {
    margin-bottom: 0px !important;
  }

  .question-modal-subtitle p {
    margin-bottom: 0px !important;
  }
</style>

<template>
  <div
    @mouseenter="hovering = true"
    @mouseleave="hovering = false"
  >
    <span class="py-5 text-warning" v-if="authIsObserver">You are unable to view uploaded media due to your user role</span>
    <div v-else class="row d-flex justify-content-start mx-0 px-10 py-5">
      <template v-for="(post, i) in response">
        <PictureBookThumb
          :key="i"
          :post="post"
          :response="post.activity_response"
          class="col-4 mb-5"
        >
        </PictureBookThumb>
      </template>
    </div>

    <b-popover :target="'response-container-' + response.id" triggers="hover" placement="right" class="p-0">
      <EngagementNotepad
        :activityId="activityId"
        morphType="ActivityPictureBookResponse"
        :response="response"
        @toggleNotepad="$emit('toggleNotepad',$event)"
      >
      </EngagementNotepad>
    </b-popover> 
  </div>
</template>

<script>
import { mapGetters } from  "vuex";

import EngagementNotepad from "@/view/components/stream-responses/engagement/Notepad";
import PictureBookThumb from "@/modules/together-helpers/activities/response/picture_book/PictureBookThumb";

export default {
  name: "streamResponsePictureBook",

  props: {
    baseResponse: { type: Number, required: true },
    response: { type: Array, required: true },
    attachments: { type: Array, required: true },
    activityId: { type: Number, required: true }
  },

  data() {
    return {
      hovering: false,
      hovering_replies: false,
      replyState: {
        showReplies: false,
        showReplyInput: false
      }
    }
  },

  components: {
    EngagementNotepad,
    PictureBookThumb
  },

  methods: {
    toggle_replies: function() {
      if (this.replyState.showReplies) {
        this.toggle_reply_input(false);
      } else {
        this.replyState.showReplies = true;
      }
    },
    toggle_reply_input: function(bool) {
      this.replyState.showReplies = bool;
      this.replyState.showReplyInput = bool;
    }
  },

  computed: {
    ...mapGetters([
      "currentUser"
    ]),

    authIsObserver: function() {
      return (this.currentUser.projectProfile != undefined && this.currentUser.projectProfile.system_project_role_id == 2) ? true : false;
    }
  }
}
</script>
<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-7">
      <PinSelectDropdown
        :pinResponses="pinResponses"
        :engage="engage"
        @toggle-option="$emit('prompt-react')"
      />
      <a
        href="javascript:void(0)"
        class="d-flex justify-content-end align-items-center"
        @click="$refs['aggregate-modal'].show();"
      >
        View Chart
      </a>
    </div>

    <b-modal
      ref="aggregate-modal"
      size="xl"
      :hide-footer="true"
    >
      <template #modal-title>
        {{ `${activity.title} - View Aggregated Chart` }}
      </template>

      <MarkupAggregate
        :activity="activity"
        :imageUrl="imageUrl"
      />
    </b-modal>
  </div>

</template>

<script>
import Engage from "@/core/services/EngageService";

import PinSelectDropdown from "./PinSelectDropdown.vue";
import MarkupAggregate from "./MarkupAggregate.vue";

export default {
  name: "MarkupTitle",

  props: {
    pinResponses: { type: Array, required: true },
    engage:       { type: Engage, required: true },
    activity:     { type: Object, required: true },
    imageUrl:     { type: String, required: true },
  },

  components: {
    PinSelectDropdown,
    MarkupAggregate
  }
}
</script>
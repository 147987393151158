<template>
  <div class="d-flex justify-content-center pt-5">
    <b-spinner
      v-if="!loadingComplete"
      variant="primary"
      label="Spinning"
      class="table-loader my-3"
    >
    </b-spinner>

    <div
      v-else
      class="w-100 px-5"
    >
      <div class="d-flex justify-content-between align-items-center mb-5">
        <PinSelectDropdown
          v-if="engage.response.markup_pins != undefined"
          :pinResponses="engage.response.markup_pins"
          :engage="engage"
          @toggle-option="reactHandler += 1"
        />

        <div class="toggle-button-group" style="width: fit-content">
          <a  
            href="javascript:void(0)"
            class="btn btn-sm btn-outline"
            :class="{ 'btn-primary' : summaryType === 1 }"
            @click="summaryType = 1"
          >
            Heat Map
          </a>

          <a  
            href="javascript:void(0)"
            class="btn btn-sm btn-outline"
            :class="{ 'btn-primary' : summaryType === 2 }"
            @click="summaryType = 2"
          >
            Pins
          </a>

          <b-dropdown
            class="mb-0 col-5 p-0 d-inline ml-3"
            toggle-class="btn-sm"
          >
            <template #button-content>
              Export
            </template>

            <div
              v-for="(downloadType) in possibleExportType"
              :key="downloadType.id"
              class="w-100"
            >
              <a
                href="javascript:void(0)"
                class="dropdown-item d-flex align-items-center"
                @click="exportAggregate(downloadType.id)"
              >
                {{ downloadType.name }}
                <b-spinner
                  v-if="downloadType.id === 4 && downloadingExcel"
                  variant="primary"
                  style="font-size:.1rem; height:12px; width:12px; margin-left: 10px; margin-bottom: 2px"
                />
              </a>
            </div>
          </b-dropdown>
        </div>
      </div>

      <img
        :ref="`image-object-aggregate-${activity.id}`"
        class="w-100"
        style="height:0px"
        :src="imageUrl"
        @load="onImageLoaded()"
      >

      <PinnedImage
        v-if="engage.response.markup_pins != undefined"
        v-show="loadingComplete && summaryType === 2"
        type="review"
        id="markup_pins"
        ref="pinContainer"
        :engage="engage"
        :activity="activity"
        :imageUrl="base64Image"
        :showTally="true"
        :reactHandler="reactHandler"
      />

      <div
        ref="heatmapContainer"
        v-show="loadingComplete && summaryType === 1"
      >
        <div
          id="heatmap"
          class="mark-up-image rounded"
          :style="{
            backgroundImage: `url('${base64Image}')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            position: 'relative',
            height: `${imageInputHeight}px`,
            width: `${imageInputWidth}px`
          }"
          v-observe-visibility="onVisibilityChange"
        />        
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapActions
} from "vuex";

import ApiService from "@/core/services/api.service";

import Engage from "@/core/services/EngageService";
import CanvasExport from "@/core/services/CanvasExportService";

import PinSelectDropdown from "@/modules/together-helpers/activities/response/markup-components/PinSelectDropdown.vue";
import PinnedImage from "@/modules/together-helpers/activities/response/answer-types/PinnedImage.vue";

import heatmap from 'heatmap.js';

export default {
  name: "MarkupAggregate",

  props: {
    activity:     { type: Object, required: true },
    imageUrl:     { type: String, required: true },
  },

  data() {
    return {
      summaryType: 2,
      pageLoader: {
        componentsRequired: 2,
        componentsCompleted: 0
      },
      engage: new Engage(
        this,
        null,
        null,
        null
      ),
      canvasExport: new CanvasExport(),
      imageInputWidth: null,
      imageInputHeight: null,
      originalResponse: [],
      heatmapRendered: false,
      base64Image: "",
      reactHandler: 1,
      downloadingExcel: false
    }
  },

  components: {
    PinSelectDropdown,
    PinnedImage
  },

  mounted() {
    this.fetchActivityAggregate();
    this.fetchBase64Image();
  },

  methods: {
    fetchActivityAggregate: function() {
      let payload = {
        route: `api/v1/projects/${this.activity.project_id}/activities/${this.activity.id}`,
        params: {
          engagementQuestionPinResponses: 'with'
        }
      };

      this.genericResource(payload).then((res) => {
        this.originalResponse = JSON.parse(JSON.stringify(res.engagement_question_pin_responses));

        this.engage.setResponseValue("markup_pin_filter", []);
        this.engage.setResponseValue("markup_pins", res.engagement_question_pin_responses);

        this.pageLoader.componentsCompleted++;
        });
    },
    fetchBase64Image: function() {
      let payload = {
        route: `api/v1/project_assets/url_base64`,
        params: {
          imageUrl: this.imageUrl
        }
      };

      this.genericResource(payload).then((res) => {
        this.pageLoader.componentsCompleted++;
        this.base64Image = `data:image/jpeg;base64,${res.trim()}`;
      })
    },
    generateHeatmap: function() {
      const elements = document.getElementsByClassName('heatmap-canvas');
      const elementsArray = Array.from(elements);

      elementsArray.forEach(element => {
        element.remove();
      });

      const filteredPinIds = this.engage.response.markup_pin_filter.map(obj => obj.id);

      const heatmapData = [
        {x: 1,y: 1,value: 0}
      ];

      const self = this;
      this.originalResponse.forEach(function(pinResponse) {
        if (filteredPinIds.length === 0 || filteredPinIds.includes(pinResponse.engagement_question_pin_id)) {
          heatmapData.push({
            x: self.imageInputWidth * (pinResponse.pin_x / 100),
            y: self.imageInputHeight * (pinResponse.pin_y / 100),
            value: 65
          })          
        }
      })

      const heatmapInstance = heatmap.create({
        container: document.getElementById('heatmap'),
        radius: 20
      });

      heatmapInstance.setData({max: 100, data: heatmapData });
      this.heatmapRendered = true;
    },
    onImageLoaded: function() {
      let imageObject = this.$refs[`image-object-aggregate-${this.activity.id}`];

      let naturalWidth = imageObject.naturalWidth;
      let naturalHeight = imageObject.naturalHeight;

      let ratio = imageObject.clientWidth / naturalWidth;

      this.imageInputWidth = this.$refs[`image-object-aggregate-${this.activity.id}`].clientWidth;
      this.imageInputHeight = naturalHeight * ratio;
    },
    onVisibilityChange: function(isVisible) {
      if (isVisible) {
        this.generateHeatmap();
      }
    },
    exportAggregate: function(downloadTypeId) {
      if (downloadTypeId === 4) {
        this.downloadExcelSummary();
        return;
      }

      let el = (this.summaryType === 2) ? this.$refs['pinContainer'].getExportElement() : this.$refs['heatmapContainer'];
      let suffix  = (this.summaryType === 2) ? 'Pins' : 'Heatmap';
      
      let filename  = this.activity.title.replace(/[^\w\s]/g, '');

      if (filename.length === 0) {
        filename = this.activity.id;
      };

      filename = `${filename} - ${suffix}`;

      switch (downloadTypeId) {
        case 1:
          this.canvasExport.exportPNG(el, filename)
          break;
        case 2:
          this.canvasExport.exportJPEG(el, filename);
          break;
        case 3:
          this.canvasExport.exportSVG(el, filename);
          break;
      }
    },
    downloadExcelSummary: function() {
      let payload = {
        route: `api/v1/activities/${this.activity.id}/markup_response/Excel`,
        data: {
          pinQuestions: this.engage.response.markup_pin_filter
        }
      };

      this.downloadingExcel = true;

      this.genericResource(payload).then((res) => {
        window.open(res);
      }).finally(() => {
        this.downloadingExcel = false;
      })
    },
    ...mapActions({
      genericResource: "genericResource"
    })
  },

  watch: {
    reactHandler: {
      handler() {
        if (this.summaryType === 1) {
          setTimeout(() => {
            this.generateHeatmap();
          }, "200");
        }
      },
      deep: true
    }
  },

  computed: {
    possibleExportType: function() {
      return [{id: 1, name: 'PNG'},{id: 2, name: 'JPEG'},{id: 4, name: 'Excel'}]
    },
    loadingComplete: function() {
      return this.pageLoader.componentsCompleted >= this.pageLoader.componentsRequired;
    }
  }
}
</script>
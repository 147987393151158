<template>
  <b-alert
    class="mb-10" 
    show
    dismissible
    variant="light"
  >
    <p class="pt-2">{{ message }}</p>
  </b-alert>
</template>

<script>
export default {
  name: "WarningBox",

  props: {
    message: { type: String, required: true }
  }
};
</script>

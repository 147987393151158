<template>
  <b-form-checkbox
    class="checkbox"
    v-model="filter.note"
    name="check-button"
    button
    button-variant="outline-primary"
    size="sm"
    v-b-tooltip.hover.top
    :title="$t('REVIEW.STREAM.FILTER_NOTES_TOOLTIP')"
    :disabled="reloadingResponses"
  >
    {{ $t('REVIEW.STREAM.FILTER_NOTES') }}
  </b-form-checkbox>
</template>

<script>
export default {
  name: "NoteToggle",

  props: {
    filter: { type: Object, required: true },
    reloadingResponses: { type: Boolean, required: true }
  }
};
</script>

<template>
  <div
    @mouseenter="hovering = true"
    @mouseleave="hovering = false"
  >
    <div :id="'response-container-' + response.id">
      <StreamSurvey
        :response="response"
        :questions="questions"
        :activityId="activityId"
        :baseResponse="baseResponse"
        activityType="poll"
        @open-chart-modal="$emit('open-chart-modal',$event)"
        @toggleNotepad="$emit('toggleNotepad',$event)"
      >
      </StreamSurvey>
      <InlineEngagement
        ref="engagement"
        type="ActivityPollResponse"
        :sentiment="false"
        :reply="false"
        :favourite="false"
        :destroy="true"
        :notepad="false"
        :hovering="(hovering && !hovering_replies) ? true : false"
        :baseResponse="baseResponse"
        :response="response"
        :activityId="activityId"
        :slim="true"
        @toggleNotepad="$emit('toggleNotepad',$event)"
      >
      </InlineEngagement>      
    </div>
  </div>
</template>

<script>
import StreamSurvey from "@/modules/together-helpers/activities/response/StreamSurvey";
import InlineEngagement from "@/view/components/stream-responses/InlineEngagement";

export default {
  name: "streamResponsePoll",

  props: {
    baseResponse: { type: Number, required: true },
    response: { type: Object, required: true },
    questions: { type: Array, required: true },
    activityId: { type: Number, required: true }
  },

  data() {
    return {
      hovering: false,
      hovering_replies: false,
    }
  },

  components: {
    StreamSurvey,
    InlineEngagement
  }
}
</script>
<template>
  <div class="w-100 px-20 mt-10">
    <h6 class="mb-5">
      Pin Detail
      <b-button
        variant="link"
        class="text-info"
        v-if="!isMarkUpImage"
        @click="playVideoByPin"
        >{{ `@ ${pinTime}` }}</b-button
      >
    </h6>
    <div class="row px-0 mx-0 d-flex justify-content-start">
      <span class="font-weight-bolder pr-2">Posted By: </span>
      <span>{{ pinDetail.user.display_name }}</span>
    </div>
    <div class="row px-0 mx-0 d-flex justify-content-start">
      <span class="font-weight-bolder pr-2">Posted On: </span>
      <GenericDatetimeDisplay
        :long="true"
        :date="pinDetail.created_at"
        :createdAt="true"
        :lastUpdated="false"
        :relative="false"
      >
      </GenericDatetimeDisplay>
    </div>
    <div v-if="pinDetail.text_response">
      <span
        class="d-flex w-100 font-weight-bolder mb-5"
        v-if="text_response_prompt"
        >Q: {{ text_response_prompt }}
      </span>
      <span class="d-flex w-100 text-secondary mb-5"
        >A: {{ pinDetail.text_response }}
      </span>
    </div>
    <div v-if="pinDetail.sentiment">
      <span
        class="d-flex w-100 font-weight-bolder mb-5"
        v-if="sentiment_response_prompt"
        >Q: {{ sentiment_response_prompt }}
      </span>
      <div class="mb-5">
        <template v-for="(icon, index) in sentimentsIcon">
          <button
            type="button"
            v-bind:key="index"
            :class="
              `btn btn-icon btn-outline-${icon.labelColor} btn-circle btn-lg mr-2` +
                (pinDetail.sentiment == icon.value ? ' active' : '')
            "
          >
            <i :class="`fa ${icon.icon}`"></i>
          </button>
        </template>
      </div>
    </div>
    <div v-if="pinDetail.sentiment_text_response">
      <span
        class="d-flex w-100 font-weight-bolder mb-5"
        v-if="sentiment_text_response_prompt"
        >Q: {{ sentiment_text_response_prompt }}
      </span>
      <span class="d-flex w-100 text-secondary mb-5"
        >A: {{ pinDetail.sentiment_text_response }}
      </span>
    </div>
  </div>
</template>

<script>
import GenericDatetimeDisplay from "@/modules/together-helpers/components/generic-displays/GenericDatetimeDisplay"

export default {
  name: "MarkUpPinsDetail",

  props: {
    pinDetail: { type: Object, required: true },
    isMarkUpImage: { type: Boolean, required: true },
    allQuestion: { type: Array, required: true }
  },

  data() {
    return {
      sentimentsIcon: [
        {
          value: 1,
          active: false,
          icon: "fa fa-smile",
          labelColor: "success"
        },
        {
          value: 2,
          active: false,
          icon: "fa fa-meh",
          labelColor: "warning"
        },
        {
          value: 3,
          active: false,
          icon: "fa fa-frown",
          labelColor: "danger"
        }
      ]
    };
  },

  components: {
    GenericDatetimeDisplay
  },

  methods: {
    getSecondsToTimeString(number, needHours = false) {
      var sec_num = parseInt(number, 10); // don't forget the second param
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - hours * 3600) / 60);
      var seconds = sec_num - hours * 3600 - minutes * 60;

      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return needHours
        ? hours + ":" + minutes + ":" + seconds
        : minutes + ":" + seconds;
    },
    playVideoByPin() {
      let pin = {
        pinId: this.pinDetail.id,
        pinTimestamp: this.pinDetail.timestamp
      };
      this.$emit("playVideoByPin", pin);
    }
  },

  computed: {
    responseId() {
      return this.pinDetail.activity_markup_response_id;
    },
    pinTime() {
      if (this.pinDetail.timestamp !== null) {
        let time = this.getSecondsToTimeString(this.pinDetail.timestamp);
        return time;
      } else {
        return "00:00";
      }
    },
    text_response_prompt() {
      if (this.pinDetail.activity_markup_question_id) {
        let found = this.allQuestion.find(
          q => q.id === this.pinDetail.activity_markup_question_id
        );
        if (found !== -1) {
          return found.text_response_prompt ? found.text_response_prompt : null;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    sentiment_response_prompt() {
      if (this.pinDetail.activity_markup_question_id) {
        let found = this.allQuestion.find(
          q => q.id === this.pinDetail.activity_markup_question_id
        );
        if (found !== -1) {
          return found.sentiment_response_prompt
            ? found.sentiment_response_prompt
            : null;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    sentiment_text_response_prompt() {
      if (this.pinDetail.activity_markup_question_id) {
        let found = this.allQuestion.find(
          q => q.id === this.pinDetail.activity_markup_question_id
        );
        if (found !== -1) {
          return found.sentiment_text_response_prompt
            ? found.sentiment_text_response_prompt
            : null;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
  }
};
</script>

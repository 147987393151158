<template>
  <b-dropdown
    ref="pin-input-select"
    id="pin-input-select"
    class="mb-0 col-5 p-0"
    toggle-class="d-flex justify-content-start align-items-center"
  >
    <template #button-content>
      <div
        class="d-flex justify-content-start align-items-center"
        style="min-height: 23px; background: transparent; border-color: transparent"
      >
        <span class="pr-2">
          {{ `Display: ${(model.length === 0) ? 'All Pins' : ''}`}}
        </span>
        <div
          v-if="model.length > 0"
        >
          <span
            v-for="(selectedPin, pt) in model"
            :key="selectedPin.id"
            class="mr-2"
          >
            <PinIcon
              :type="selectedPin.system_markup_pin_type_id"
              :hoverable="false"
              :height="23"
              :width="23"
              class="mr-1"
            />
            <span>
              {{ `${selectedPin.label} ${(pt + 1 >= model.length) ? '' : ','}` }}
            </span>
            
          </span>
        </div>        
      </div>
    </template>

    <div
      v-for="(pinType) in eligiblePinTypes"
      :key="pinType.id"
      class="d-flex justify-content-start align-items-center form-check form-checkbox form-check-custom form-check-solid p-1 mx-1"
    >
      <input
        class="form-check-input h-15px w-15px bg-gray-700"
        type="checkbox"
        style="border-radius: 0px"
        :checked="selectedPinIds.includes(pinType.id)"
        :id="`${pinType.id}-pin-type-select`"
        @change="toggleOption(pinType)"
      />

      <a
        href="javascript:void(0)"
        class="form-check-label"
        :for="`${pinType.id}-input-random-questions`"
        @click="toggleOption(pinType)"
      >
        <PinIcon
          :type="pinType.system_markup_pin_type_id"
          :hoverable="false"
          :height="30"
          :width="30"
        />

        {{ pinType.label }}
      </a>
    </div>
  </b-dropdown>
</template>

<script>
import {
  mapGetters
} from "vuex";

import Engage from "@/core/services/EngageService";

import PinIcon from "./PinIcon.vue";

export default {
  name: "MarkupPinSelectDropdown",

  props: {
    pinResponses: { type: Array, required: true },
    engage:       { type: Engage, required: true },
  },

  data() {
    return {
      model: []
    }
  },

  components: {
    PinIcon
  },

  methods: {
    toggleOption: function(engagementQuestionPin) {
      let matchingSelectedPins = this.model.filter(function(matchingSelectedPin) {
        return matchingSelectedPin.id === engagementQuestionPin.id
      });

      if (matchingSelectedPins.length === 0) {
        this.model.push(engagementQuestionPin);
      } else {
        this.model = this.model.filter(function(selectedPin) {
          return selectedPin.id != engagementQuestionPin.id
        });
      }

      this.$emit('toggle-option');
    }
  },

  watch: {
    model: {
      handler(newModelValue) {
        if (newModelValue != this.formValue) {
          this.engage.setResponseValue('markup_pin_filter', []);
          this.engage.setResponseValue('markup_pin_filter', newModelValue);
        }
      }
    },
    formValue: {
      handler(newFormValue) {
        if (newFormValue != this.model && newFormValue != undefined) {
          this.model = newFormValue
        }
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters([
      "systemMarkupPinTypes"
    ]),
    formValue: function() {
      return this.engage.response.markup_pin_filter
    },
    eligiblePinTypes: function() {
      const uniquePinsMap = this.pinResponses.reduce((acc, obj) => {
        if (!acc[obj.engagement_question_pin_id]) {
            acc[obj.engagement_question_pin_id] = obj.engagement_question_pin;
        }
        return acc;
      }, {});

      return Object.values(uniquePinsMap);
    },
    selectedPinIds: function() {
      return this.model.map(function(pin) { return pin.id; })
    }
  }
}
</script>

<style>
#pin-input-select__BV_toggle_ {
  background: #f0f1f2 !important;
  border-color: #f0f1f2 !important;
}

#pin-input-select .dropdown-menu.show {
  width: 100% !important;
  padding-left: 23px !important;
}
</style>
<template>
  <div>
    <div
      :id="'reply-container-' + reply.id"
      class="row w-100 mx-0 stream-reply px-3"
      @mouseenter="hovering = true"
      @mouseleave="hovering = false"
    >
      <div class="d-flex justify-content-start mx-0 py-3 mb-5 w-100">
        <UserThumbnail :user="reply.user" size="40"></UserThumbnail>
        <div class="ml-3 w-100">
          <div class="row d-flex justify-content-between mx-0">
            <p class="mb-1 font-weight-bold">
              <AnonymisedUsername :user="reply.user"></AnonymisedUsername>
            </p>
            <p class="mb-1 text-muted font-size-sm">
              <GenericDatetimeDisplay
                :long="true"
                :date="reply.created_at"
                :createdAt="true"
                :lastUpdated="false"
                :relative="true"
              >
              </GenericDatetimeDisplay>
            </p>
          </div>
          <div class="row mx-0 mb-3">
            {{ reply.response }}
          </div>
          <InlineEngagement
            ref="engagement"
            type="ActivityResponseReply"
            :sentiment="true"
            :reply="true"
            :favourite="true"
            :destroy="true"
            :notepad="true"
            :response="reply"
            :activityId="activityId"
            :replyState="replyState"
            :hovering="hovering"
            :baseResponse="baseResponse"
            :baseMorph="baseMorph"
            :baseMorphId="baseMorphId"
            :baseQuestionPosition="baseQuestionPosition"
            @toggle_replies="toggle_replies()"
            @toggle_reply_input="toggle_reply_input(true)"
            @toggleNotepad="$emit('toggleNotepad',$event)"
          >
          </InlineEngagement>
          <Replies
            ref="replies"
            :name="'ResponseReply-' + reply.id"
            :activityId="activityId"
            :replies="reply.replies"
            repliable_type="ActivityResponseReply"
            :repliable_id="reply.id"
            :replyState="replyState"
            :baseResponse="baseResponse"
            :baseMorph="baseMorph"
            :baseMorphId="baseMorphId"
            :baseQuestionPosition="baseQuestionPosition"
            @cancel-reply-input="replyState.showReplyInput = false"
          >
          </Replies>
        </div>
      </div>
    </div>  
  </div>

</template>

<script>
import UserThumbnail from "@/modules/together-helpers/components/generic-displays/GenericUserThumbnail";
import InlineEngagement from "@/view/components/stream-responses/InlineEngagement";
import GenericDatetimeDisplay from "@/modules/together-helpers/components/generic-displays/GenericDatetimeDisplay"

export default {
  name: "StreamReplyResponse",

  props: {
    reply: { type: Object, required: true },
    activityId: { type: Number, required: true },
    baseResponse: { type: Number, required: true },
    baseMorph: { type: String, required: true },
    baseMorphId: { type: Number, required: true },
    baseQuestionPosition: { type: Number, required: false, default: 0 }
  },

  data() {
    return {
      hovering: false,
      replyState: {
        showReplies: false,
        showReplyInput: false
      }
    }
  },

  components: {
    UserThumbnail,
    InlineEngagement,
    GenericDatetimeDisplay
  },

  methods: {
    toggle_replies: function() {
      if (this.replyState.showReplies) {
        this.toggle_reply_input(false);
      } else {
        this.replyState.showReplies = true;
      }
    },
    toggle_reply_input: function(bool) {
      this.replyState.showReplies = bool;
      this.replyState.showReplyInput = bool;
    }
  },

  watch: {
    replyCount: {
      handler(val) {
        if (val == 0) {
          this.toggle_reply_input(false);
        }
      },
      immediate: false
    }
  },

  computed: {
    replyCount: function() {
      return this.reply.replies;
    }
  }
}
</script>
<template>
  <div>
    <b-dropdown
      :text="computedButtonText"
      variant="outline-primary"
      class="sf-height-4 no-search"
      :class="{ 'null' : mediaCount == 0, 'selected' : mediaCount > 0 }"
      size="sm"
      v-b-tooltip.hover.top
      :title="$t('REVIEW.STREAM.FILTER_MEDIA_TOOLTIP')"
      no-caret
      :disabled="reloadingResponses"
    >
      <template v-for="(media, i) in supportedMedia">
        <b-form-group
          :key="i"
          class="mb-0 mx-2"
          :id="'media-check-group' + media.id"
          :label="media.name"
          :label-sr-only="true"
          :label-for="'media-check-group' + media.id"
        >
          <b-form-checkbox
            :id="'media-check' + media.id"
            value="1"
            unchecked-value="0"
            size="lg"
            @change="emitChange($event, media)"
          >
            <p class="pt-1 mb-2">{{ media.name }}</p>
          </b-form-checkbox>
        </b-form-group>
      </template>
      <div class="w-100 d-flex justify-content-center mt-3">
        <a
          v-if="mediaCount > 0"
          href="javascript:void(0)"
          @click="clearMedia()"
          class="text-center font-size-sm"
        >
          {{ $t('REVIEW.TEXT_ANALYSIS.RESET') }}
        </a>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: "MediaSelect",

  props: {
    filter: { type: Object, required: true },
    reloadingResponses: { type: Boolean, required: true }
  },

  data() {
    return {
      checkedMedia: []
    }
  },

  methods: {
    clearMedia: function() {
      this.checkedMedia.forEach(function (mediaId) {
        document.getElementById('media-check' + mediaId).click();
      });
      this.$emit('media_filter_changed',[]);
    },
    emitChange: function(event, media)
    {
      if (event == 1) {
        let payload = {
          id: media.id,
          display: media.name
        };
        let newMediaArray = this.filter.medias;
        newMediaArray.push(payload);
        this.$emit('media_filter_changed',newMediaArray)
        this.checkedMedia.push(media.id);
      } else {
        let newMediaArray = this.filter.medias.filter(function( obj ) { return obj.id !== media.id });
        this.$emit('media_filter_changed',newMediaArray);
        const index = this.checkedMedia.indexOf(media.id);
        if (index > -1) {
          this.checkedMedia.splice(index, 1);
        }
      }
    }
  },

  watch:{
    'filter.reset': function (newVal, oldVal){
      if (oldVal == false && newVal == true) {
        this.clearMedia();
      }
    }
  },

  computed: {
    computedButtonText: function() {
      if (this.filter.medias.length == 0) {
        return `${this.$t('REVIEW.STREAM.FILTER_MEDIA')}`;
      } else if (this.filter.medias.length == 1) {
        return this.filter.medias[0].display;
      } else {
        return this.filter.medias.length + ' ' + `${this.$t('REVIEW.STREAM.FILTER_MEDIA')}`;
      }
    },
    mediaCount: function() {
      return this.filter.medias.length;
    },
    supportedMedia: function() {
      return [
        { id: 1, name: "Videos" },
        { id: 2, name: "Images" },
        { id: 3, name: "Audio" },
        { id: 4, name: "Files" }
      ]
    }
  },
};
</script>

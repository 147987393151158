<template>
  <div>
    <b-dropdown
      id="segmentation-filter"
      :text="computedButtonText"
      variant="outline-primary"
      :class="calculatedClassList"
      size="sm"
      v-b-tooltip.hover.top
      :title="$t('REVIEW.STREAM.FILTER_SEGMENTS_TOOLTIP')"
      no-caret
      :disabled="reloadingResponses"
    >
      <div class="row px-5 mb-5">
        <FilterOptionsSegment
          v-if="computedSegmentations.length > 0"
          :segmentFilterOption="segmentOption"
          :minimal="true"
          @filterOptionChanged="changeSegmentFilterOption"
        >
        </FilterOptionsSegment>
      </div>
      <input
        v-model="search"
        type="text"
        :placeholder="$t('GENERAL.INPUT.SEARCH')"
        class="form-control form-control-solid py-3 px-5 mb-5"
        style="height:35px !important; margin-top:4px !important"
      />
      <div :style="'height:' + calculatedScrollHeight + 'px'">
        <vuescroll>
          <template v-for="(segmentation, i) in computedSegmentations">
            <div :key="'segmentation-' + i" style="margin-bottom: 10px">
              <p class="mx-2" style="margin-bottom:5px">{{ segmentation.name }}</p>
              <template v-for="(segment, s) in segmentation.project_segments">
                <b-form-group
                  :key="'segmentation-' + ((i + 1) * resets) + '-segment-' + s"
                  class="mb-0 mx-2"
                  :id="'project_segment-check-group' + segment.id"
                  :label="segment.name"
                  :label-sr-only="true"
                  :label-for="'project_segment-check-group' + segment.id"
                >
                  <b-form-checkbox
                    :id="'project_segment-check' + segment.id"
                    value="1"
                    unchecked-value="0"
                    size="lg"
                    @change="onChange($event, segment)"
                    :disabled="segmentSelectDisabled"
                  >
                    <p class="pt-1" style="margin-bottom:5px">{{ segment.name }}</p>
                  </b-form-checkbox>
                </b-form-group>
              </template>
            </div>
          </template>
        </vuescroll>
      </div>
      <p v-if="computedSegmentations.length == 0" class="mx-2">{{ $t('REVIEW.STREAM.NO_SEARCH_PLACEHOLDER') }}</p>
      <div v-if="segmentCount > 0" class="w-100 d-flex justify-content-center my-3">
        <a
          v-if="segmentCount > 0"
          href="javascript:void(0)"
          @click="onReset()"
          class="text-center font-size-sm"
        >
          {{ $t('REVIEW.TEXT_ANALYSIS.RESET') }}
        </a>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from  "vuex";
import { mapMutations } from 'vuex'
import vuescroll from 'vuescroll';
import FilterOptionsSegment from "@/view/components/form-inputs/FilterOptionsSegments";

export default {
  name: "SegmentSelect",

  props: {
    filter: { type: Object, required: true },
    reloadingResponses: { type: Boolean, required: true }
  },

  data() {
    return {
      search: "",
      segmentOption: 1,
      segmentSelectDisabled: false,
      checkedSegments: [],
      resets: 1
    }
  },

  components: {
    vuescroll,
    FilterOptionsSegment
  },

  methods: {
    onReset: function(initial = false) {
      this.filterReset('segments');
      this.resets = (initial) ? 1 : this.resets += this.projectSegmentations.length;
      this.segmentOption = 1;
    },
    onChange: function(event, segment) {
      let payload = { key: 'segments', id: segment.id, display: segment.name };
      (event == 1) ? this.filterAdd(payload) : this.filterStrip(payload);
    },
    changeSegmentFilterOption: function(value) {
      this.segmentOption = value;
      if (value == 4) {
        this.segmentSelectDisabled = true;
      } else {
        this.segmentSelectDisabled = false;
      }
      this.$emit('segment_option_changed',value);
    },
    ...mapMutations({
      filterAdd: 'addToFilter',
      filterStrip: 'stripFromFilter',
      filterReset: 'resetFilterList'
    }),
  },

  watch:{
    'filter.reset': function (newVal, oldVal){
      if (oldVal == false && newVal == true) {
        this.onReset();
      }
    }
  },


  computed: {
    ...mapGetters([
      "projectSegmentations",
      "legacyProjectSegmentations"
    ]),
    ...mapState({
      legacy: state => state.projectStream.legacy
    }),
    computedButtonText: function() {
      if (this.filter.segments.length == 0) {
        return `${this.$t('REVIEW.STREAM.FILTER_SEGMENTS')}`;
      } else if (this.filter.segments.length == 1) {
        return this.filter.segments[0].display;
      } else {
        return this.filter.segments.length + ' ' + `${this.$t('REVIEW.STREAM.FILTER_SEGMENTS')}`;
      }
    },
    segmentCount: function() {
      return this.filter.segments.length;
    },
    calculatedClassList: function() {
      let classList = "";

      if (this.segmentCount == 0) {
        classList = classList + " null";
      } else {
        classList = classList + " selected";
      }

      return classList;
    },
    calculatedScrollHeight: function() {
      let calculatedScrollHeight = 0;

      this.computedSegmentations.forEach(function (segmentation) {
        if (calculatedScrollHeight < 172.5) {
          calculatedScrollHeight += 35;
          segmentation.project_segments.forEach(function () {
            calculatedScrollHeight += 27.25;
          })
        }
      });

      return calculatedScrollHeight;
    },
    computedSegmentations: function() {
      var self = this;
      if (self.search.length == 0) {
        return this.legacy ? self.legacyProjectSegmentations: self.projectSegmentations;
      } else {
        if (this.legacy) {
          return self.legacyProjectSegmentations.filter(function( segmentation ) {
            return segmentation.name.toLowerCase().includes(self.search.toLowerCase()) ||
            segmentation.project_segments.filter(function( segment ) {
              return segment.name.toLowerCase().includes(self.search.toLowerCase())
            }).length > 0
          });
        } else {
          return self.projectSegmentations.filter(function( segmentation ) {
            return segmentation.name.toLowerCase().includes(self.search.toLowerCase()) ||
            segmentation.project_segments.filter(function( segment ) {
              return segment.name.toLowerCase().includes(self.search.toLowerCase())
            }).length > 0
          });
        }
        
      }
    }
  },
}
</script>

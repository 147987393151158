<template>
  <div class="p-5">
    <template v-for="(response, i) in responseData">
      <OpenEnded
        :key="i"
        :index="i"
        :response="response"
        :baseResponse="response.activityResponse.id"
        :activityId="response.activityResponse.activity_id"
        :user="response.activityResponse.user"
        :morphType="getMorphType(response.activityResponse)"
        @toggleNotepad="$emit('toggleNotepad',$event)"
        @filter_participant_by="$emit('filter_participant_by', $event)"
        @open_profile_modal="$emit('open_profile_modal',$event)"
      >
      </OpenEnded>
      <div :key="'seperator-' + i" v-if="showSeparator(i)" class="col-12 separator separator-solid separator-gray separator-border-1 mb-7 mt-10"></div>
    </template>
  </div>
</template>

<script>
import OpenEnded from "@/view/content/aggregate/OpenEnded";

export default {
  name: "OpenEndedAggregate",

  props: {
    responseData: { type: Array, required: true }
  },

  components: {
    OpenEnded
  },

  methods: {
    getMorphType: function(activityResponse) {
      let morphType = null;

      switch (activityResponse.system_activity_type_id) {
        case 2:
          morphType = 'DiaryQuestionResponse';
          break;
        case 4:
          morphType = "SurveyQuestionResponse";
          break;
      }

      return morphType;
    },
    showSeparator: function(index) {
      let totalResponses = this.responseData.length;

      if (index >= totalResponses - 1) {
        return false;
      } else {
        return true;
      };
    }
  }
}
</script>

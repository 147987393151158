<template>
  <div
    :id="`aggregate-container-${question.id}`"
    class="card card-custom gutter-b"
    v-if="!videoMarkup"
  >
    <div class="card-body">
      <div class="d-flex justify-content-between">
        <div class="row d-flex justify-content-start mx-0">
          <p class="mb-2 font-weight-bolder">Question {{ question.question_number }}: </p>
          <p v-if="question.system_answer_type_id != 8" class="ml-2 mb-10 text-muted">
            <span>{{ displayedQuestion | stripHTML }}</span>
          </p>

          <p v-else class="ml-2 mb-10 text-muted">
            <span class="fitb" v-html="displayedQuestion"></span>
          </p>
        </div>
        <AMChartsExport
          v-if="![1,7,8].includes(question.system_answer_type_id)"
          position="left"
          :dataTypes="['png','jpg','svg','json','csv']"
          :index="index"
          :title="question.question | stripHTML"
        >
        </AMChartsExport>
      </div>

      <div v-if="question.system_answer_type_id == 7" class="d-flex justify-content-center w-100 my-10">
        <div class="btn-group" role="group">
          <button
            @click="markup_aggregate_type = 1"
            type="button"
            class="btn btn-sm"
            :class="{ 'btn-outline-primary' : markup_aggregate_type == 2, 'btn-primary' : markup_aggregate_type == 1}"
          >
            Heat Map
          </button>
          <button
            @click="markup_aggregate_type = 2"
            type="button"
            class="btn btn-sm"
            :class="{ 'btn-outline-primary' : markup_aggregate_type == 1, 'btn-primary' : markup_aggregate_type == 2}"
          >
            Pins
          </button>
        </div>
      </div>

      <component
        v-if="isChart(question.system_answer_type_id)"
        :index="index"
        :is="getAggregateComponent(question.system_answer_type_id)"
        :loadingComplete="true"
        :chartData="question.data"
      >
      </component>
      
      <component
        v-else
        :is="getAggregateComponent(question.system_answer_type_id)"
        :loadingComplete="true"
        :responseData="question.data"
        @open_profile_modal="openProfileModal"
        @filter_participant_by="$emit('filter_participant_by',$event)"
        @toggleNotepad="$emit('toggleNotepad',$event)"
      >
      </component>

    </div>

    <UserProjectProfile
      :userId="modals.userId"
      ref="userProjectProfileModal"
    >
    </UserProjectProfile>
  </div>
</template>

<script>
import { mapGetters } from  "vuex";

import ResponseAggregatedOpenEnded from "@/view/content/aggregate/OpenEndedAggregate";
import ResponseChartMultipleChoice from "@/view/content/chart/MultipleChoice";
import ResponseChartSingleChoice from "@/view/content/chart/SingleChoice";
import ResponseChartRanking from "@/view/content/chart/Ranking";
import ResponseHeatmap from "@/view/content/chart/MarkupHeatmap";
import ResponseAggregatedPins from "@/view/content/chart/MarkupPins";
import ResponseAggregatedFITB from "@/view/content/aggregate/FITBAggregate";

import UserProjectProfile from "@/view/content/modals/UserProjectProfile";

export default {
  name: "StreamAggregateQuestionContainer",

  props: {
    question: { type: Object, required: true },
    index: { type: Number, required: true }
  },

  components: {
    UserProjectProfile
  },

  data() {
    return {
      show_full_question: false,
      selectedSlot: null,
      markup_aggregate_type: 1,
      modals: {
        userId: null
      }
    }
  },

  mounted() {
    let ellipses = document.getElementById(`aggregate-container-${this.question.id}`).getElementsByClassName("ellipses-link");
    if (ellipses.length > 0) {
      ellipses = ellipses[0];
      const self = this;
      ellipses.addEventListener('click', function() {
        self.selectedSlot = null;
        self.show_full_question = true;
      });
    }

    this.bindFITBAnchors();
  },

  methods: {
    bindFITBAnchors: function() {
      let questionSlots = document.getElementById(`aggregate-container-${this.question.id}`).getElementsByClassName("fitb-anchor");
      const self = this;
      questionSlots.forEach(function(anchor) {
        anchor.addEventListener("click", function() {
          self.selectedSlot = (self.selectedSlot == anchor.id) ? null : anchor.id;
        });
      });
    },
    isChart: function(systemAnswerTypeId) {
      switch (systemAnswerTypeId) {
        case 1:
          return false;
        case 2:
        case 3:
        case 5:
        case 7:
          return true;
      }
    },
    getAggregateComponent: function(systemAnswerTypeId) {
      switch (systemAnswerTypeId) {
        case 1:
          return ResponseAggregatedOpenEnded;
        case 2:
          return ResponseChartMultipleChoice;
        case 3:
          return ResponseChartSingleChoice;
        case 5:
          return ResponseChartRanking;
        case 7:
          if (this.markup_aggregate_type == 1) {
            return ResponseHeatmap;
          } else {
            return ResponseAggregatedPins;
          }
        case 8:
          return ResponseAggregatedFITB;
      }
    },
    openProfileModal: function(val) {
      this.modals.userId = val;
      this.$refs.userProjectProfileModal.$refs.modal.show();
    },
    applySelectedSlot: function(newSelectedSlot) {
      let responseSlots = document.getElementById(`aggregate-container-${this.question.id}`).getElementsByClassName("fitb-response");
      responseSlots.forEach(function(responseSlot) {
        if (newSelectedSlot == null || newSelectedSlot != null && responseSlot.id != newSelectedSlot) {
          responseSlot.classList.remove("selected");
        }
        if (responseSlot.id == newSelectedSlot) {
          responseSlot.classList.add("selected");
        }
      });

      let questionSlots = document.getElementById(`aggregate-container-${this.question.id}`).getElementsByClassName("fitb-question");
      questionSlots.forEach(function(questionSlot) {
        if (newSelectedSlot == null || newSelectedSlot != null && questionSlot.id != newSelectedSlot) {
          questionSlot.classList.remove("selected");
        }
        if (questionSlot.id == newSelectedSlot) {
          questionSlot.classList.add("selected");
        }
      });
    }
  },

  watch: {
    selectedSlot: {
      handler(newSelectedSlot) {
        this.applySelectedSlot(newSelectedSlot);
      }
    },
    displayedQuestion: {
      handler() {
        const self = this;
        setTimeout(function(){
          self.bindFITBAnchors()
        }, 500);
      }
    }
  },

  computed: {
    ...mapGetters([
      'streamCharts'
    ]),
    displayedQuestion: function() {
      if (this.question.system_answer_type_id == 8) {
        return (this.show_full_question) ? this.question.question_fitb : this.question.question_short;
      } else {
        return (this.show_full_question) ? this.question : this.question.question_short;
      }
    },
    videoMarkup: function() {
      if (this.question.system_answer_type_id == 7) {
        let projectAsset = this.question.data.stimuli[0].projectAsset;
        if (projectAsset.type == 1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    chartData: function() {
      if (this.streamCharts.length > 0) {
        return this.streamCharts[this.index];        
      } else {
        return null;
      }
    }
  }
}
</script>
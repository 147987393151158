<template>
  <div>
    <template v-for="(activity, a) in streamResults.aggregated">
      <div :key="a" class="row d-flex justify-content-center my-5 mx-0">
        <div class="col-2">
          <div class="row d-flex justify-content-start mx-0">
            <a href="javascript:void(0)" :id="'activity-popover-' + a">
              <p class="ml-2 mb-0 font-size-lg font-weight-bolder">
                {{ activity.activity_title | stripHTML }}</p>
            </a>
          </div>
          <div class="stream-detail-separator my-2"></div>
        </div>
        <div class="col-7">
          <template v-for="(question, q) in activity.questions">
            <StreamAggregateQuestionContainer
              :key="q"
              :index="q"
              :question="question"
              @filter_participant_by="$emit('filter_participant_by',$event)"
              @toggleNotepad="$emit('toggleNotepad',$event)"
            >
            </StreamAggregateQuestionContainer>
          </template>
        </div>
        <div class="col-2"></div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from  "vuex";

import StreamAggregateQuestionContainer from "./stream_responses/StreamAggregateQuestionContainer";

export default {
  name: "StreamAggregateContainer",

  components: {
    StreamAggregateQuestionContainer
  },

  computed: {
    ...mapGetters([
      'streamResults'
    ])
  }
}
</script>
<template>
  <div>
    <p class="mb-2 mt-5">{{ $t('REVIEW.STREAM.CUSTOM_TAGS') }}</p>
    <div v-if="legacy">
      <template v-for="(tag, idx) in customTags">
        <span class="badge badge-primary mx-1 py-2 my-1" :key="idx"> {{ tag }} </span>
      </template>
    </div>
    <tagify
      v-else
      class="py-2 px-2 mb-10"
      :tags="customTags"
      :placeholder="$t('REVIEW.STREAM.CUSTOM_TAGS')"
    >
    </tagify>
  </div>
</template>

<script>
import Tagify from 'tagify-vue';
import { mapState } from "vuex";

import {
  CREATE_TAG_ENGAGEMENTS,
  DELETE_TAG_ENGAGEMENTS
} from "@/core/services/store/activity/activity_tag_engagements.module";

export default {
  name: "CustomTags",

  props: {
    tags: { type: Array, required: true },
    morph: { type: Object, required: true },
    newSchema: { type: Boolean, required: false, default: false }
  },

  data() {
    return {
      customTags: [],
      localAdded: []
    }
  },

  components: {
    Tagify
  },

  methods: {
    addCustomTag: function(tag) {
      let payload = {
        activityId: this.morph.activityId,
        data: {
          taggable_type: this.morph.type,
          taggable_id: this.morph.id,
          tag: tag
        }
      };

      this.$store
        .dispatch(CREATE_TAG_ENGAGEMENTS, payload)
        .then((res) => {
          this.localAdded.push(res);
        })
    },
    removeCustomTag: function(removedTag) {

      let existingCustomTags = this.tags.filter(function( tag ) {
        return tag.tag == removedTag;
      });

      let existingCustomLocalTags = this.localAdded.filter(function( tag ) {
        return tag.tag == removedTag;
      });

      let tagEngagementId = null;

      if (existingCustomTags.length > 0) {
        tagEngagementId = existingCustomTags[0].id;
      } else if (existingCustomLocalTags.length > 0) {
        tagEngagementId = existingCustomLocalTags[0].id;
      } else {
        return;
      }

      let payload = {
        activityId: this.morph.activityId,
        activityTagEngagementId: tagEngagementId
      };

      this.$store
        .dispatch(DELETE_TAG_ENGAGEMENTS, payload)
        .then(() => {
          this.localAdded = this.localAdded.filter(function( tag ) {
            return tag.tag != removedTag;
          });
        })
    }
  },

  watch: {
    tags: {
      handler(val) {
        let field = (this.newSchema) ? 'value' : 'tag';
        this.customTags = val.map(a => a[field]);
      },
      deep: true,
      immediate: true
    },
    customTags: {
      handler(val) {
        let field = (this.newSchema) ? 'value' : 'tag';

        let originalTags = this.tags.map(a => a[field]);
        let originalLocalTags = this.localAdded.map(a => a[field]);
        let combinedTags = originalTags.concat(originalLocalTags);
        let removedTags = combinedTags.filter(x => !val.includes(x));
        let addedTags = val.filter(x => !combinedTags.includes(x));

        if (addedTags.length > 0) {
          if (this.newSchema) {
            this.$emit('on-tag-submit',addedTags[0]);
          } else {
            this.addCustomTag(addedTags[0]);
          }
        }

        if (removedTags.length > 0) {
          if (this.newSchema) {
            this.$emit('on-tag-removed',removedTags[0]);
          } else {
            this.removeCustomTag(removedTags[0]);
          }
        }
      }
    }
  },

  computed: {
     ...mapState({
      legacy: state => state.projectStream.legacy
    })
  }
};
</script>

<template>
  <div>
    <InputGenericTextArea
      class="mb-3"
      :model="$v.form.reply"
      :submissionStates="submissionStates"
      rows="6"
      :slim="true"
    >
    </InputGenericTextArea>
    <div class="row d-flex justify-content-between mx-0">
      <div class="d-flex justify-content-start">
        <InputGenericCheckbox
          :id="`request_reply_check-${repliable_id}`"
          class="mr-4 mb-0 pt-1"
          label="Request Reply"
          :model="$v.form.probe"
          :submissionStates="submissionStates"
          :slim="true"
        >
        </InputGenericCheckbox>
        <!-- <InputGenericCheckbox
          class="mb-0 pt-1"
          label="Mark As Private"
          :model="$v.form.private"
          :slim="true"
          :submissionStates="submissionStates"
        >
        </InputGenericCheckbox> -->
      </div>
      <div class="d-flex justify-content-end">
        <button
          class="btn btn-outline-primary mr-2"
          @click="$emit('reply-input-closed')"
        >
          {{ $t('GENERAL.BUTTONS.CANCEL') }}
        </button>
        <button
          class="btn btn-primary"
          @click="onSubmit"
        >
          {{ $t('GENERAL.BUTTONS.SAVE') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import {
  CREATE_REPLY_ENGAGEMENTS
} from "@/core/services/store/activity/activity_reply_engagements.module";

import InputGenericTextArea from "@/modules/together-helpers/components/generic-inputs/GenericTextArea";
import InputGenericCheckbox from "@/modules/together-helpers/components/generic-inputs/GenericCheckbox";

export default {
  mixins: [validationMixin],

  name: "ReplyInput",

  props: {
    activityId: { type: Number, required: true },
    repliable_type: { type: String, required: true },
    repliable_id: { type: Number, required: true }
  },

  data() {
    return {
      form: {
        reply: "",
        probe: 1,
        private: 0
      },
      submissionStates: {
        submitting: false
      },
    }
  },

  validations: {
    form: {
      reply: { required },
      probe: { required },
      private: { required }
    }
  },

  components: {
    InputGenericTextArea,
    InputGenericCheckbox
  },

  methods: {
    onSubmit: function() {

      if (this.submitting || this.$v.form.$anyError) {
        return;
      }

      this.submitting = true;

      let action = CREATE_REPLY_ENGAGEMENTS;
      let payload = {
        activityId: this.activityId,
        data: {
          response: this.form.reply,
          repliable_id: this.repliable_id,
          repliable_type: this.repliable_type,
          attachments: [],
          private: this.form.private,
          probe: this.form.probe
        }
      };

      this.$store
        .dispatch(action, payload)
        .then((res) => {
          (this.form.private) ? this.toastPrivateReply() : this.toastPublicReply();
          this.form = { reply: "", probe: "1", private: "0" };
          this.$emit('reply-added',res);
          this.$emit('reply-input-closed');
        })
        .finally(() => {
          this.submitting = false;
        })
    },
    toastPublicReply: function() {
      this.$root.$bvToast.toast(`${this.$t("TOASTS.ADD_REPLY.MESSAGE")}`, {
        title: `${this.$t("TOASTS.ADD_REPLY.TITLE")}`,
        autoHideDelay: 5000,
        appendToast: false,
        variant: "success"
      });
    },
    toastPrivateReply: function() {
      this.$root.$bvToast.toast(`${this.$t("TOASTS.ADD_REPLY_PRIVATE.MESSAGE")}`, {
        title: `${this.$t("TOASTS.ADD_REPLY_PRIVATE.TITLE")}`,
        autoHideDelay: 5000,
        appendToast: false,
        variant: "success"
      });
    }
  }
}
</script>
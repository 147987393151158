<template>
  <div>
    <div class="row d-flex justify-content-between mb-3 mr-0">
      <div class="d-flex justify-content-start">
        <UserThumbnail
          :user="user" 
          size="40"
        ></UserThumbnail>
        <a href="javascript:void(0)" :id="'user-popover-' + response.id">
          <div class="font-weight-bold mx-3 py-3">
            <AnonymisedUsername :user="user"></AnonymisedUsername>
          </div>
        </a>
      </div>
      <div class="d-flex justify-content-end">
        <GenericDatetimeDisplay
            :date="response.updated_at"
            :createdAt="true"
            :lastUpdated="true"
            :relative="true"
        >
        </GenericDatetimeDisplay>
      </div>
    </div>
    <div :id="'response-container-' + response.id">

      <div class="row mx-5 fitb">
        <span class="mb-5" v-html="response.fitbResponse"></span>
      </div>

      <InlineEngagement
        ref="engagement"
        :type="morphType"
        :sentiment="true"
        :reply="true"
        :favourite="true"
        :destroy="true"
        :notepad="true"
        :response="response"
        :replyState="replyState"
        :activityId="activityId"
        :hovering="(hovering && !hovering_replies) ? true : false"
        :baseResponse="baseResponse"
        :slim="true"
        @toggle_replies="toggle_replies()"
        @toggle_reply_input="toggle_reply_input(true)"
        @toggleNotepad="$emit('toggleNotepad',$event)"
      >
      </InlineEngagement>
    </div>

    <Replies
      ref="replies"
      :activityId="activityId"
      :replies="response.replies"
      :repliable_type="morphType"
      :repliable_id="response.id"
      :replyState="replyState"
      :baseResponse="baseResponse"
      :baseMorph="morphType"
      :baseMorphId="response.id"
      :baseQuestionPosition="index"
      @cancel-reply-input="replyState.showReplyInput = false"
      @hovering-replies="hovering_replies = $event"
      @toggleNotepad="$emit('toggleNotepad',$event)"
    >
    </Replies>
    <b-popover :target="'user-popover-' + response.id" triggers="focus" placement="bottom" class="p-0">
      <a
        class="dropdown-item"
        href="javascript:void(0)"
        @click="$emit('filter_participant_by',{ id: user.id, display: userLabel(user) })"
      >
          View responses by
          <AnonymisedUsername :user="user"></AnonymisedUsername>
      </a>
      <a
        class="dropdown-item"
        href="javascript:void(0)"
        @click="$emit('open_profile_modal',user.id)"
      >
        View Profile
      </a>
    </b-popover>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import UserThumbnail from "@/modules/together-helpers/components/generic-displays/GenericUserThumbnail";
import GenericDatetimeDisplay from "@/modules/together-helpers/components/generic-displays/GenericDatetimeDisplay";
import InlineEngagement from "@/view/components/stream-responses/InlineEngagement";

export default {
  name: "FITB",

  props: {
    response: { type: Object, required: true },
    baseResponse: { type: Number, required: true },
    activityId: { type: Number, required: true },
    user: { type: Object, required: true },
    morphType: { type: String, required: true },
    index: { type: Number, required: true }
  },

  data() {
    return {
      hovering: false,
      hovering_replies: false,
      replyState: {
        showReplies: false,
        showReplyInput: false
      }
    }
  },

  components: {
    GenericDatetimeDisplay,
    UserThumbnail,
    InlineEngagement
  },

  methods: {
    toggle_replies: function() {
      if (this.replyState.showReplies) {
        this.toggle_reply_input(false);
      } else {
        this.replyState.showReplies = true;
      }
    },
    toggle_reply_input: function(bool) {
      this.replyState.showReplies = bool;
      this.replyState.showReplyInput = bool;
    },
    userLabel: function(projectUser) {
      if (this.currentUser.projectProfile != undefined && this.currentUser.projectProfile.system_project_role_id == 2) {
        return "participant_" + this.user.projectProfile.id;
      } else {
        return projectUser.display_name;
      }
    }
  },

  computed: {
    ...mapGetters([
      "currentUser"
    ])
  }
}
</script>
<template>
  <div
    v-if="loadingComplete"
    @mouseenter="hovering = true"
    @mouseleave="hovering = false"
  >
    <div :id="'response-container-' + response.id">
      <StreamMarkup
        :response="response"
        :questions="questions"
        :media="media"
        :activityId="activityId"
        @open-chart-modal="$emit('open-chart-modal',$event)"
      />
      <InlineEngagement
        ref="engagement"
        type="ActivityMarkupResponse"
        :sentiment="false"
        :reply="true"
        :favourite="true"
        :destroy="true"
        :notepad="true"
        :response="response"
        :activityId="activityId"
        :replyState="replyState"
        :hovering="hovering && !hovering_replies ? true : false"
        :baseResponse="baseResponse"
        @toggle_replies="toggle_replies()"
        @toggle_reply_input="toggle_reply_input(true)"
        @toggleNotepad="$emit('toggleNotepad',$event)"
      >
      </InlineEngagement>
    </div>
    <Replies
      ref="replies"
      :activityId="activityId"
      :replies="response.replies"
      :initialReplies="response.replies"
      repliable_type="ActivityMarkupResponse"
      :repliable_id="response.id"
      :replyState="replyState"
      :baseResponse="baseResponse"
      baseMorph="ActivityMarkupResponse"
      :baseMorphId="response.id"
      @cancel-reply-input="replyState.showReplyInput = false"
      @hovering-replies="hovering_replies = $event"
      @toggleNotepad="$emit('toggleNotepad', $event)"
    >
    </Replies>
  </div>

  <b-spinner v-else variant="primary" label="Spinning" class="card-loader">
  </b-spinner>
</template>

<script>
import { mapGetters } from  "vuex";

import StreamMarkup from "@/view/components/stream-responses/Markup";
import InlineEngagement from "@/view/components/stream-responses/InlineEngagement";
import LoadingCompleteHelper from "@/helpers/LoadingCompleteHelper";
import Replies from "@/view/components/stream-responses/Replies";

import { POPULATE_MARKUP_ACTIVITY_INFO } from "@/core/services/store/activity/activity.module";
export default {
  name: "streamResponseMarkup",

  props: {
    baseResponse: { type: Number, required: true },
    response: { type: Object, required: true },
    questions: { type: Array, required: true },
    activityId: { type: Number, required: true }
  },

  mounted() {
    this.fetchMarkUpImage();
  },

  data() {
    return {
      pageLoader: {
        componentsRequired: 1,
        componentsCompleted: 0
      },
      error: {
        active: false,
        message: ""
      },
      hovering: false,
      hovering_replies: false,
      media: null,
      replyState: {
        showReplies: false,
        showReplyInput: false
      }
    };
  },

  components: {
    StreamMarkup,
    InlineEngagement,
    Replies
  },

  methods: {
    fetchMarkUpImage() {
      let payload = {
        activityId: this.activityId,
        projectId: this.projectInfo.id,
        params: {
          stimulusAssets: "with",
          appendSignedUrls: 1
        }
      };
      this.$store
        .dispatch(POPULATE_MARKUP_ACTIVITY_INFO, payload)
        .then(res => {
          this.media = res.stimulus_assets;
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the activity information, please check back later or contact the helpdesk";
        });
    },
    toggle_replies: function() {
      if (this.replyState.showReplies) {
        this.toggle_reply_input(false);
      } else {
        this.replyState.showReplies = true;
      }
    },
    toggle_reply_input: function(bool) {
      this.replyState.showReplies = bool;
      this.replyState.showReplyInput = bool;
    }
  },

  watch: {
    replyCount: {
      handler(val) {
        if (val == 0) {
          this.toggle_reply_input(false);
        }
      },
      immediate: false
    }
  },

  computed: {
    ...mapGetters([
      "projectInfo"
    ]),
    replyCount: function() {
      return this.response.replies;
    },
    loadingComplete: function() {
      return LoadingCompleteHelper.loadingComplete(this.pageLoader, this.error);
    }
  }
};
</script>

<template>
  <div>
    <p class="mb-2 mt-5">{{ $t('REVIEW.STREAM.PROJECT_TAGS') }}</p>
    <div class="checkbox-list">
      <template v-for="(projectTag, i) in projectTagList">
        <label v-if="projectTag.project_id != undefined" :key="i" class="checkbox">
          <input
            type="checkbox"
            name="Checkboxes1"
            :checked="projectTagChecked(projectTag.id)"
            @change="toggleProjectTag(projectTag.id)"
          />
          <span></span>
          {{ projectTag.tag }}
        </label>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import {
  CREATE_TAG_ENGAGEMENTS,
  DELETE_TAG_ENGAGEMENTS
} from "@/core/services/store/activity/activity_tag_engagements.module";

export default {
  name: "ProjectTags",

  props: {
    tags: { type: Array, required: true },
    morph: { type: Object, required: true }
  },
  
  methods: {
    projectTagChecked: function(projectTagId) {
      let tagEntries = this.tags.filter(function( tag ) {
        return tag.project_tag_id == projectTagId;
      });

      return (tagEntries.length > 0) ? true : false;
    },
    toggleProjectTag: function(projectTagId) {
      if (this.projectTagChecked(projectTagId)) {
        this.deleteProjectTagEngagement(projectTagId);
      } else {
        this.createProjectTagEngagement(projectTagId);
      }
    },
    createProjectTagEngagement: function(projectTagId) {
      let payload = {
        activityId: this.morph.activityId,
        data: {
          taggable_type: this.morph.type,
          taggable_id: this.morph.id,
          tag: null,
          project_tag_id: projectTagId
        }
      };

      this.$store
        .dispatch(CREATE_TAG_ENGAGEMENTS, payload)
        .then((res) => {
          this.$emit('tag-added',res);
        })
    },
    deleteProjectTagEngagement: function(projectTagId) {
      let tagEntries = this.tags.filter(function( tag ) {
        return tag.project_tag_id == projectTagId;
      });

      let payload = {
        activityId: this.morph.activityId,
        activityTagEngagementId: tagEntries[0].id
      };

      this.$store
        .dispatch(DELETE_TAG_ENGAGEMENTS, payload)
        .then(() => {
          this.$emit('tag-removed',projectTagId);
        })
    }
  },

  computed: {
    ...mapGetters(["projectTagList"]),
  }
};
</script>

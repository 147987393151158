<template>
  <b-modal
    :id="'report-modal-' + id"
    :ref="'report-modal-' + id"
    size="lg"
    :title="$t('TABLES.PROJECT_USER_NAVS.GENERATE_REPORT')"
    body-class="px-10 py-5"
    @show="preLoadModal"
  >

    <!--start::Error Box-->
    <ErrorBox :errors="projectReportsErrors" @clear_errors="clearErrors">
    </ErrorBox>
    <!--end::Error Box-->

    <!--start::Warning Box-->
    <WarningBox
      v-if="computedScope == 'Full'"
      message="Full project exports can take a long time to process and download"
    >
    </WarningBox>
    <!--end::Warning Box-->

    <!--start::Report Information-->
    <div id="report-information">
      <!--start::Report Title-->
      <div class="row col-xl-12 mb-5 mt-2 px-0 mx-0">
        <InputGeneric
          id="input-title"
          class="col-12 px-3"
          :model="$v.form.title"
          :label="$t('FORMS.INPUTS.TITLE')"
          :placeholder="$t('FORMS.INPUTS.TITLE')"
          :showInline="false"
          :submissionStates="submissionStates"
          :error="$t('FORMS.ERROR.REQUIRED')"
        >
        </InputGeneric>
      </div>
      <!--end::Report Title-->
    
      <!--start::Report Notes-->
      <div class="row col-xl-12 mb-5 px-0 mx-0">
        <InputGenericTextArea
          class="col-12 px-3"
          id="input-notes"
          :model="$v.form.notes"
          :submissionStates="submissionStates"
          rows="10"
          :label="$t('FORMS.INPUTS.NOTES')"
          displayLabel="0"
        >
        </InputGenericTextArea>
      </div>
      <!--end::Report Notes-->

      <!--start::Report Media Options-->
      <div class="row col-xl-12 mb-5 px-0 mx-0">
        <InputGenericRadio
          class="col-12 px-3"
          id="input-media-type"
          :model="$v.form.settings.media_type"
          :submissionStates="submissionStates"
          :label="$t('FORMS.INPUTS.MEDIA_TYPE')"
          :error="$t('FORMS.ERROR.REQUIRED')"
          :options="[
            {
              value: 1,
              text: `${$t('FORMS.INPUTS.MEDIA_TYPE_1')}`
            },
            {
              value: 2,
              text: `${$t('FORMS.INPUTS.MEDIA_TYPE_2')}`
            }
          ]"
        />
      </div>
      <!--end::Report Notes-->
    </div>
    <!--end::Report Information-->

    <!-- start::Report Settings-->
    <!-- <div id="report-settings" class="row d-flex justify-content-start px-3"> -->
      <!--start::Export Type-->
      <!-- <div v-if="computedExportTypeOptions.length > 0" class="col-4">
        <p class="mb-2 font-weight-bold">{{ $t('EXPORT.SETTING_HEADERS.TYPE') }}</p>
        <InputGenericRadio
          class="col-xl-12 px-0 pl-2"
          id="input-export-type"
          :model="$v.form.settings.export_type"
          :displayLabel="false"
          :submissionStates="submissionStates"
          :options="computedExportTypeOptions"
          :error="$t('FORMS.ERROR.REQUIRED')"
          value="value"
          text="label"
        >
        </InputGenericRadio>
      </div> -->
      <!--end::Export Type-->

      <!--start::Export Settings-->
      <!-- <div :class="{ 'col-12' : computedExportTypeOptions == 0, 'col-8' : computedExportTypeOptions > 0 }">
        <p class="mb-2 font-weight-bold">{{ $t('EXPORT.SETTING_HEADERS.SETTINGS') }}</p> -->
        <!--start::Export Setting (Separate Multimedia)-->
        <!-- <div class="row col-xl-12 mb-0 px-0 mx-0">
          <InputGenericCheckbox
            class="col-xl-12 pr-0 pl-2"
            id="input-separate-mm"
            :label="$t('EXPORT.SETTING_OPTIONS.SEPARATE_MM')"
            :model="$v.form.settings.separate_mm"
            :submissionStates="submissionStates"
          >
          </InputGenericCheckbox>
        </div> -->
        <!--end::Export Setting (Separate Multimedia)-->

        <!--start::Export Setting (Separate Tags)-->
        <!-- <div v-if="computedType == 1 && scope != 'UserSummary'" class="row col-xl-12 mb-0 px-0 mx-0">
          <InputGenericCheckbox
            class="col-xl-12 pr-0 pl-2"
            id="input-separate-tags"
            :label="$t('EXPORT.SETTING_OPTIONS.SEPARATE_TAGS')"
            :model="$v.form.settings.separate_tags"
            :submissionStates="submissionStates"
          >
          </InputGenericCheckbox>
        </div> -->
        <!--end::Export Setting (Separate Tags)-->

        <!--start::Export Setting (Anonymise)-->
        <!-- <div v-if="scope != 'UserSummary'" class="row col-xl-12 mb-0 px-0 mx-0">
          <InputGenericCheckbox
            class="col-xl-12 pr-0 pl-2"
            id="input-anonymise"
            :label="$t('EXPORT.SETTING_OPTIONS.ANONYMISE')"
            :model="$v.form.settings.anonymise"
            :submissionStates="submissionStates"
          >
          </InputGenericCheckbox>
        </div> -->
        <!--end::Export Setting (Anonymise)-->
      <!-- </div> -->
      <!--end::Export Settings-->

    <!-- </div> -->
    <!--end::Report Settings -->

    <!--start::Report Footer-->
    <template #modal-footer class="py-0">
      <div class="d-flex justify-content-between my-0 py-0">
        <a
          href="javascript:void(0)"
          class="btn btn-sm btn-secondary mr-2"
          @click="$refs['report-modal-' + id].hide();"
        >
          {{ $t('GENERAL.BUTTONS.CANCEL') }}
        </a>
        <a
          href="javascript:void(0)"
          class="btn btn-sm btn-primary mr-2"
          @click="onSubmit"
        >
          {{ $t('GENERAL.BUTTONS.GENERATE') }}
        </a>
      </div>
    </template>
    <!--end::Report Footer-->

  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { maxLength, required } from "vuelidate/lib/validators";

import {
  CREATE_PROJECT_REPORT,
  CLEAR_PROJECT_REPORTS_ERRORS
} from "@/core/services/store/project/project_reports.module";

import AlertHelper from "@/modules/together-helpers/helpers/AlertHelper";

import ErrorBox from "@/view/components/ErrorBox";
import WarningBox from "@/view/components/WarningBox";
import InputGeneric from "@/view/components/form-inputs/GenericInput";
import InputGenericTextArea from "@/modules/together-helpers/components/generic-inputs/GenericTextArea";
// import InputGenericCheckbox from "@/modules/together-helpers/components/generic-inputs/GenericCheckbox";
import InputGenericRadio from "@/modules/together-helpers/components/generic-inputs/GenericRadioOption";

export default {
  mixins: [validationMixin],

  name: "ReportModal",

  props: {
    id: { type: Number, required: false, default: 1},
    scope: { type: String, required: true },
    users: { type: Array, required: false, default: () => [] },
    dateRange: { type: Object, required: false, default: () => { return { endDate: null, startDate: null } } },
    activities: { type: Array, required: false, default: () => [] }, 
    segments: { type: Array, required: false, default: () => [] },
    segmentOption: { type: Number, required: false, default: 1 },
    tags: { type: Array, required: false, default: () => [] },
    media: { type: Array, required: false, default: () => [] },
    favourites: { type: Boolean, required: false, default: false },
    notes: { type: Boolean, required: false, default: false }
  },

  data() {
    return {
      submissionStates: {
        submitting: false
      },
      form: {
        title: "",
        notes: "",
        settings: {
          export_type: 1,
          separate_mm: 0,
          anonymise: 0,
          separate_tags: 0,
          media_type: 1
        }
      }
    }
  },

  validations: {
    form: {
      title: {
        required,
        maxLength: maxLength(255)
      },
      notes: {},
      settings: {
        export_type: {
          required
        },
        separate_mm: {
          required
        },
        anonymise: {
          required
        },
        separate_tags: {
          required
        },
        media_type: {
          required
        }
      }
    }
  },

  components: {
    ErrorBox,
    WarningBox,
    InputGeneric,
    InputGenericTextArea,
    // InputGenericCheckbox,
    InputGenericRadio
  },

  methods: {
    preLoadModal: function() {

      this.clearErrors();

      if (this.scope == "UserSummary") {
        this.form.title = "User Summary Report";
        this.form.notes = "A snapshot summary of all registered and invited users";
      } else if (this.scope == "ActivitySummary") {
        if (this.computedScope == "Users") {
          this.form.title = "User Activity Report - " + this.users.length + ((this.users.length == 1) ? " user" : " users");
        } else if (this.computedScope == "Activities") {
          this.form.title = "Activity Report - " + this.activities.length + ((this.activities.length == 1) ? " activity" : " activities");
        } else if (this.computedScope == "Project") {
          this.form.title = "Scoped Project Report";
        } else if (this.computedScope == "Full") {
          this.form.title = "Full Project Report";
        }
        this.form.notes = this.generateNoteAutofill();
      }
    },
    generateNoteAutofill: function() {
      let notes = "";

      if (this.providedParameters.includes('dates')) {
        notes = notes + "Date Range: " + this.dateRange.startDate + " - " + this.dateRange.endDate + "\r";
      }
      if (this.providedParameters.includes('users')) {
        let userNameArray = this.users.map(function (user) { return user.display; });
        notes = notes + "Users: " + userNameArray.toString().replace(',',', ') + "\r";
      }
      if (this.providedParameters.includes('activities')) {
        let activityNameArray = this.activities.map(function (activity) { return activity.display });
        notes = notes + "Activities: " + activityNameArray.toString().replace(',',', ') + "\r";
      }
      if (this.providedParameters.includes('segments')) {
        let segmentNameArray = this.segments.map(function (segment) { return segment.display });
        notes = notes + "Segments: " + segmentNameArray.toString().replace(',',', ') + "\r";
      }
      if (this.providedParameters.includes('tags')) {
        let tagNameArray = this.tags.map(function (tag) { return tag.display });
        notes = notes + "Tags: " + tagNameArray.toString().replace(',',', ') + "\r";
      }
      if (this.providedParameters.includes('media')) {
        let mediaNameArray = this.media.map(function (mediaType) { return mediaType.display });
        notes = notes + "Media: " + mediaNameArray.toString().replace(',',', ') + "\r";
      }
      if (this.providedParameters.includes('favourites')) {
        notes = notes + "Favourited: Yes" + "\r";
      }
      if (this.providedParameters.includes('notes')) {
        notes = notes + "Noted: Yes" + "\r";
      }

      return notes;
    },
    onSubmit: function() {
      this.clearErrors();

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.submissionStates.submitting = true;

      let formData = JSON.parse(JSON.stringify(this.form));

      formData.type = 1;
      formData.notes = formData.notes.replace("\r", '<br/>');
      formData.params = {};

      if (this.scope == "UserSummary") {
        formData.type = 1;
        formData.params.users = this.users.map(function (user) { return user.id; });
      } else if (this.scope == "HealthSummary") {
        formData.type = 2;
      } else if (this.scope == "ActivitySummary") {
        formData.type = 3;

        formData.params = this.streamFilter;
        formData.params.favourite_local = (this.streamFilter.favourite) ? 1 : 0;
        formData.params.note_local = (this.streamFilter.note) ? 1 : 0;
        formData.params.new_local = (this.streamFilter.new) ? 1 : 0;
      };

      let payload = {
        projectId: this.projectInfo.id,
        data: formData
      };
      this.$store
        .dispatch(CREATE_PROJECT_REPORT, payload)
        .then(() => {
          this.$refs['report-modal-' + this.id].hide();
          AlertHelper.alertSuccess(this,"GENERATE_REPORT_SUCCESS");
        })
        .catch(() => {
          AlertHelper.alertFailure(this,"GENERATE_REPORT_FAIL");
        })
        .finally(() => {
          this.submissionStates.submitting = false;
        })
    },
    clearErrors: function() {
      this.$store.dispatch(CLEAR_PROJECT_REPORTS_ERRORS);
    }
  },

  computed: {
    ...mapGetters([
        "projectInfo",
        "projectReportsErrors",
        "streamFilter"
    ]),

    computedScope: function() {
      if (this.scope == "ActivitySummary") {
        if (this.providedParameters.includes('users') && this.providedParameters.length == 1) {
          return "Users";
        } else if (this.providedParameters.includes('activities')) {
          return "Activities";
        } else if (this.providedParameters.length > 0) {
          return "Project";
        } else {
          return "Full";
        }
      } else if (this.scope == "UserSummary") {
        return "Users";
      } else if (this.scope == "HealthSummary") {
        return "Health";
      } else {
        return "Error";
      }
    },

    computedType: function() {
      return this.form.settings.export_type;
    },

    computedExportTypeOptions: function() {
      if (this.scope == "UserSummary" || this.scope == "HealthSummary") {
        return [];
      } else {
        return [
          { value: 1, label: "Excel" },
          { value: 2, label: "Word" },
          { value: 3, label: "PDF" },
        ]
      }
    },

    providedParameters: function()
    {
      let availableParameters = [
        { 'parameter' : 'users', 'provided' : (this.users.length > 0) ? true : false },
        { 'parameter' : 'dates', 'provided' : (this.dateRange.startDate != null || this.dateRange.endDate != null) ? true : false },
        { 'parameter' : 'activities', 'provided' : (this.activities.length > 0) ? true : false },
        { 'parameter' : 'segments', 'provided' : (this.segments.length > 0) ? true : false },
        { 'parameter' : 'tags', 'provided' : (this.tags.length > 0) ? true : false },
        { 'parameter' : 'media', 'provided' : (this.media.length > 0) ? true : false },
        { 'parameter' : 'favourites', 'provided' : (this.favourites) ? true : false },
        { 'parameter' : 'notes', 'provided' : (this.notes) ? true : false }
      ];

      let providedParameters = availableParameters.filter(function( parameter ) {
        return parameter.provided == true;
      });

      providedParameters = providedParameters.map(function (param) { return param.parameter; });

      return providedParameters;
    }
  }
};
</script>

<template>
  <b-form-checkbox
    class="checkbox"
    v-model="filter.favourite"
    name="check-button"
    button
    button-variant="outline-primary"
    size="sm"
    v-b-tooltip.hover.top
    :title="$t('REVIEW.STREAM.FILTER_FAVOURITES_TOOLTIP')"
    :disabled="reloadingResponses"
  >
    {{ $t('REVIEW.STREAM.FILTER_FAVOURITES') }}
  </b-form-checkbox>
</template>

<script>
export default {
  name: "FavouriteToggle",

  props: {
    filter: { type: Object, required: true },
    reloadingResponses: { type: Boolean, required: true }
  }
};
</script>

<template>
  <div>
    <b-dropdown
      :text="computedButtonText"
      variant="outline-primary"
      :class="calculatedClassList"
      size="sm"
      v-b-tooltip.hover.top
      :title="$t('REVIEW.STREAM.FILTER_QUESTIONS_TOOLTIP')"
      no-caret
      :disabled="reloadingResponses"
    >
      <input
        v-model="search"
        type="text"
        :placeholder="$t('GENERAL.INPUT.SEARCH')"
        class="form-control form-control-solid py-3 px-5 mb-5"
        style="height:35px !important; margin-top:4px !important"
      />
      <div :style="'height:' + calculatedScrollHeight + 'px'">
        <vuescroll>
          <template v-for="(activity, a) in computedQuestions">
            <div :key="a">
              <div v-if="search.length == 0" class="pt-1 mb-2">
                <p class="pl-2 d-inline">{{ activity.activity.title | stripHTML }}</p>
              </div>
              <template v-for="(question, i) in activity.questions">
                <b-form-group
                  :key="'activity-' + ((a + 1) * resets) + '-question-' +  i"
                  class="mb-0 mr-2 ml-4"
                  :id="'question-check-group' + question.id"
                  :label="question.question"
                  :label-sr-only="true"
                  :label-for="'question-check-group' + question.id"
                >
                  <b-form-checkbox
                    :id="'question-check' + question.id"
                    value="1"
                    unchecked-value="0"
                    size="lg"
                    :checked="questionSelected(question)"
                    @change="onChange($event, question, activity.activity)"
                  >
                  <div class="pt-1 mb-2">
                    <p v-if="question.system_answer_type_id != 8" class="pl-2 d-inline">{{ question.shortenedQuestion | stripHTML }}</p>
                    <p v-else class="fitb pl-2 d-inline" v-html="`${question.shortenedQuestion}`"></p>
                  </div>
                  </b-form-checkbox>
                </b-form-group>
              </template>
            </div>
          </template>
        </vuescroll>
      </div>
      <p v-if="computedQuestionsExpanded.length == 0" class="mx-2">{{ $t('REVIEW.STREAM.NO_SEARCH_PLACEHOLDER') }}</p>
      <div class="w-100 d-flex justify-content-center mt-3">
        <a
          v-if="questionCount > 0"
          href="javascript:void(0)"
          @click="onReset()"
          class="text-center font-size-sm"
        >
          {{ $t('REVIEW.TEXT_ANALYSIS.RESET') }}
        </a>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import { mapGetters } from  "vuex";
import { mapState } from "vuex";
import { mapMutations } from 'vuex'
import vuescroll from 'vuescroll';

export default {
  name: "QuestionSelect",

  props: {
    reloadingResponses: { type: Boolean, required: true }
  },

  data() {
    return {
      search: "",
      resets: 1
    }
  },

  components: {
    vuescroll
  },

  mounted() {
    this.setList(this.streamFilter.questions);
  },

  methods: {
    setList: function(questions) {
      questions.forEach(function (question) {
        document.getElementById('question-check' + question.id).click();
      });
    },
    onReset: function(initial = false) {
      this.filterReset('questions');
      this.resets = (initial) ? 1 : this.resets += this.computedQuestions.length;  
    },
    onChange: function(event, question, activity) {
      let payload = { key: 'questions', id: question.id, display: question.question, systemActivityTypeId: activity.system_activity_type_id, activityId: activity.id };
      (event == 1) ? this.filterAdd(payload) : this.filterStrip(payload);
    },
    questionSelected: function(question) {
      let matchingFilters = this.filter.questions.filter(function( filteredQuestion ) {
        return filteredQuestion.activityId == question.activity_id && filteredQuestion.id == question.id;
      }).length;

      return (matchingFilters > 0) ? 1 : 0;
    },
    ...mapMutations({
      filterAdd: 'addToFilter',
      filterStrip: 'stripFromFilter',
      filterReset: 'resetFilterList'
    }),
  },

  computed: {
    ...mapGetters([
      "streamResults",
      "streamFilter"
    ]),
    ...mapState({
      filter: state => state.projectStream.filter
    }),
    computedButtonText: function() {
      if (this.filter.questions.length == 0) {
        return `${this.$t('REVIEW.STREAM.FILTER_QUESTIONS')}`;
      } else {
        return this.filter.questions.length + ' ' + `${this.$t('REVIEW.STREAM.FILTER_QUESTIONS')}`;
      }
    },
    questionCount: function() {
      return this.filter.questions.length;
    },
    calculatedClassList: function() {
      let classList = "";

      if (this.computedQuestionsExpanded.length == 0 || this.computedQuestionsExpanded.length == 1) {
        classList = classList + "sf-height-2";
      } else if (this.computedQuestionsExpanded.length > 6) {
        classList = classList + "sf-height-6";
      } else {
        classList = classList + "sf-height-" + (this.calculatedScrollHeight/28.75);
      }

      if (this.questionCount == 0) {
        classList = classList + " null";
      } else {
        classList = classList + " selected";
      }

      return classList;
    },
    calculatedScrollHeight: function() {
      if (this.computedQuestionsExpanded.length == 1 || this.computedQuestionsExpanded.length == 0) {
        return 28.75 * 2;
      } else if (this.computedQuestionsExpanded.length >= 6) {
        return 28.75 * 6;
      } else {
        let questions = [];
        let activities = [];

        this.computedQuestionsExpanded.forEach(function(question) {
          questions.push(question.id);
          activities.push(question.activity_id);
        })

        let distinctActivities = [...new Set(activities)];
        return (questions.length + distinctActivities.length) * 28.75;
      }
    },
    computedQuestions: function() {
      return this.streamResults.questions;
    },
    computedQuestionsExpanded: function() {
      let questions = [];
      this.computedQuestions.forEach((activity) => {
        questions = questions.concat(activity.questions);
      });
      return questions;
    },
  },
}
</script>

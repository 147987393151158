<template>
  <div
    v-on:mouseover="hovering = true"
    v-on:mouseleave="hovering = false"
  >
    <MarkupTitle
      :pinResponses="response.engagement_question_pin_responses"
      :engage="engage"
      :activity="response.activity"
      :imageUrl="response.activity.engagement_stimuli_assets[0].signedUrl"
      @prompt-react="react_tmp += 1"
    />

    <PinnedImage
      type="review"
      id="markup_pins"
      ref="pinContainer"
      :engage="engage"
      :activity="response.activity"
      :imageUrl="response.activity.engagement_stimuli_assets[0].signedUrl"
      :reactHandler="react_tmp"
    />

    <InlineEngagement
      ref="engagement"
      type="base"
      :allowInteractions="['Sentiment','Reply','Favourite','Note','Tag']"
      :allowDestroy="true"
      :baseResponse="response"
      :response="response"
      :hovering="hovering"
      morph="EngagementResponse"
      domain="admin"
      @toggle-notepad="$emit('toggleNotepad',$event)"
      @on-response-deletion="handleResponseDeletion"
    />   
  </div>
</template>

<script>
import {
  mapGetters,
  mapMutations
} from  "vuex";

import Engage from "@/core/services/EngageService";

import MarkupTitle from "@/modules/together-helpers/activities/response/markup-components/MarkupTitle.vue";
import PinnedImage from "@/modules/together-helpers/activities/response/answer-types/PinnedImage.vue";
import InlineEngagement from "@/modules/together-helpers/activities/response/InlineEngagementNewSchema.vue";

export default {
  name: "StreamResponseMarkupNew",

  props: {
    response: { type: Object, required: true }
  },

  data() {
    return {
      engage: new Engage(
        this,
        null,
        null,
        null
      ),
      react_tmp: 1,
      replyState: {
        showReplies: false,
        showReplyInput: false
      },
      hovering: false
    }
  },

  components: {
    MarkupTitle,
    PinnedImage,
    InlineEngagement
  },

  mounted() {
    this.engage.setResponseValue("markup_pins", this.response.engagement_question_pin_responses);
    this.engage.setResponseValue("markup_pin_filter", []);
  },

  methods: {
    handleResponseDeletion: function() {
      this.removeResponseFromList(this.response.id);
    },
    ...mapMutations({
      removeResponseFromList: "removeResponseFromList"
    })
  },

  computed: {
    ...mapGetters([
      "projectInfo"
    ])
  }
}
</script>
<template>
  <div>
    <div class="card card-custom gutter-b px-0" style="min-height:100px">
      <div class="card-body p-7">
        <div>
          <component
            v-if="schemaType === 1"
            :is="type"
            :baseResponse="response.id"
            :response="response.expandedResponse"
            :questions="response.expandedResponse.questions"
            :attachments="response.project_assets"
            :activityId="response.activity_id"
            :highlight="highlight"
            @toggleNotepad="$emit('toggleNotepad', $event)"
            @open-chart-modal="$emit('open-chart-modal', { ...$event, ...{ activity: response.activity } })"
          />

          <component
            v-else-if="schemaType === 2"
            :is="type"
            :response="response"
            @toggleNotepad="$emit('toggleNotepad', $event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StreamResponseBlog from "./Blog";
import StreamResponseDiary from "./Diary";
import StreamResponseDiscussion from "./Discussion";
import StreamResponseSurvey from "./Survey";
import StreamResponsePictureBook from "./PictureBook";
import StreamResponseIdeastorm from "./Ideastorm";
import StreamResponseScreenRecording from "./ScreenRecord";
import StreamResponseMarkupLegacy from "./Markup";
import StreamResponsePoll from "./Poll";
import StreamResponseMarkup from "./MarkupNew";

export default {
  name: "StreamResponseLayout",

  props: {
    type:       { type: String, required: true },
    response:   { type: Object, required: true },
    highlight:  { type: String, required: false, default: null },
    schemaType: { type: Number, required: true }
  },

  components: {
    StreamResponseBlog,
    StreamResponseDiary,
    StreamResponseDiscussion,
    StreamResponseSurvey,
    StreamResponsePictureBook,
    StreamResponseIdeastorm,
    StreamResponseScreenRecording,
    StreamResponseMarkupLegacy,
    StreamResponsePoll,
    StreamResponseMarkup
  }
};
</script>

<template>
  <div>
    <div class="row d-flex justify-content-start mx-0">
      <i
        :class="icon"
        v-b-tooltip.hover.top
        :title="$t(label)"
      >
      </i>
      <a href="javascript:void(0)" :id="'activity-popover-' + response.id">
        <p class="ml-2 mb-0 font-size-lg font-weight-bolder">{{ response.activity.title | stripHTML }}</p>
      </a>
    </div>
    <div class="stream-detail-separator my-2"></div>
    <div class="row d-flex justify-content-start mx-0">
      <UserThumbnail :user="response.user" size="50"></UserThumbnail>
      <div class="mx-5 py-2">
        <div class="row mx-0">
          <a href="javascript:void(0)" :id="'user-popover-' + response.id">
            <p class="mb-1 font-weight-bold">
              <AnonymisedUsername :user="response.user"></AnonymisedUsername>
            </p>
          </a>
        </div>
        <div class="row mx-0">
          <p class="mb-0">
            <GenericDatetimeDisplay
              :long="true"
              :date="response.created_at"
              :createdAt="true"
              :lastUpdated="false"
              :relative="false"
            >
            </GenericDatetimeDisplay>
          </p>
        </div>
      </div>
    </div>

    <b-popover :target="'user-popover-' + response.id" triggers="focus" placement="bottom" class="p-0">
      <a
        class="dropdown-item"
        href="javascript:void(0)"
        @click="$emit('filter_participant_by',{ id: response.user.id, display: userLabel })"
      >
          View responses by
          <AnonymisedUsername :user="response.user"></AnonymisedUsername>
      </a>
      <a
        class="dropdown-item"
        href="javascript:void(0)"
        @click="$emit('open_profile_modal',response.user.id)"
      >
        View Profile
      </a>
    </b-popover>

    <b-popover :target="'activity-popover-' + response.id" triggers="focus" placement="bottom">
      <a
        class="dropdown-item"
        href="javascript:void(0)"
        @click="$emit('filter_activity_by',{ id: response.activity.id, display: response.activity.title })"
      >
        View responses in {{ response.activity.title | stripHTML }}
      </a>
      <a
        class="dropdown-item"
        href="javascript:void(0)"
        @click="$emit('open_activity_modal',response.activity.id)"
      >
        View Activity Details
      </a>
    </b-popover>
  </div>
</template>

<script>
import { mapGetters } from  "vuex";

import SystemIconHelper from "@/modules/together-helpers/helpers/SystemIconHelper";
import SystemLabelHelper from "@/modules/together-helpers/helpers/SystemLabelHelper";
import UserThumbnail from "@/modules/together-helpers/components/generic-displays/GenericUserThumbnail";
import GenericDatetimeDisplay from "@/modules/together-helpers/components/generic-displays/GenericDatetimeDisplay"

export default {
  name: "StreamResponseDetails",

  props: {
    response: { type: Object, required: true }
  },

  components: {
    UserThumbnail,
    GenericDatetimeDisplay
  },

  computed: {
    ...mapGetters([
      "currentUser"
    ]),
    icon: function() {
      return SystemIconHelper.activityType(this.response.activity.activity_type, this.response.activity.recording_override);
    },
    label: function() {
      return SystemLabelHelper.activityType(this.response.activity.activity_type, this.response.activity.recording_override);
    },
    userLabel: function(projectUser) {
      if (this.currentUser.projectProfile != undefined && this.currentUser.projectProfile.system_project_role_id == 2) {
        return "participant_" + this.response.project_profile.id;
      } else {
        return projectUser.display_name;
      }
    }
  }
}
</script>
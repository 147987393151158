<template>
  <div ref="date-range-stream-filter">
    <b-dropdown
      :text="computedButtonText"
      :class="calculatedClass"
      v-b-tooltip.hover.top
      size="sm"
      variant="outline-primary"
      :title="$t('REVIEW.STREAM.FILTER_DATE_TOOLTIP')"
      no-caret
    >
      <InputGenericDateRangePicker
        class="px-0 text-left mt-2"
        id="date-filter"
        :model="filter.dateRange"
        :showInline="false"
        :submissionStates="submissionStates"
        :minDate="legacy ? null : projectInfo.starts_on"
        :clearable="true"
        :placeholder="$t('REVIEW.STREAM.FILTER_DATE')"
        :disabled="reloadingResponses"
        @selection_cleared="$emit('selection_cleared')"
        @update="onUpdate"
      >
      </InputGenericDateRangePicker>
    </b-dropdown>
  </div>
</template>

<script>
import { mapGetters } from  "vuex";
import { mapState } from "vuex";

import InputGenericDateRangePicker from "@/modules/together-helpers/components/generic-inputs/GenericDateRangePicker"

export default {
  name: "DateSelect",

  props: {
    filter: { type: Object, required: true },
    reloadingResponses: { type: Boolean, required: true }
  },

  data() {
    return {
      lowerRange: null,
      upperRange: null,
      submissionStates: {
        submitting: false
      }
    }
  },

  methods: {
    onUpdate(event) {
      this.lowerRange = event.lowerRange;
      this.upperRange = event.upperRange;
      this.$emit('update',event);
    }
  },

  components: {
    InputGenericDateRangePicker
  },

  computed: {
    ...mapGetters([
      'projectInfo'
    ]),
    ...mapState({
      legacy: state => state.projectStream.legacy
    }),
    dataPresent: function() {
      if (this.filter.dateRange.startDate.$model == null && this.filter.dateRange.endDate.$model == null) {
        return false;
      } else {
        return true;
      }
    },
    computedButtonText: function() {
      if (this.filter.$model.dateRange.endDate == null || this.filter.$model.dateRange.startDate == null) {
        return `${this.$t('REVIEW.STREAM.FILTER_DATE')}`;
      } else {
        return this.lowerRange + '-' + this.upperRange;
      }
    },
    calculatedClass: function() {
      let className = "";

      (!this.dataPresent) ? className = "null" : className = "active";
      (this.reloadingResponses) ? className = className + " disabled" : null;

      return className;
    }
  }
};
</script>

<template>
  <div
    id="stream-filter"
    class="d-flex justify-content-start"
    style="width: 85% !important"
  >
    <!--BEGIN::GeneralInput-->
    <SFGeneralInput
      :filter="filter.$model"
      :reloadingResponses="reloadingResponses"
    >
    </SFGeneralInput>
    <!--END::GeneralInput-->

    <!--BEGIN::New Toggle-->
    <SFNewToggle
      :filter="filter.$model"
      :reloadingResponses="reloadingResponses"
    >
    </SFNewToggle>
    <!--END::New Toggle-->

    <!--BEGIN::Date Select-->
    <SFDateSelect
      :filter="filter"
      :reloadingResponses="reloadingResponses"
      @selection_cleared="emitDateSelectionCleared"
      @update="$emit('date_filter_changed',$event)"
    >
    </SFDateSelect>
    <!--END::Date Select-->
    
    <!--BEGIN::Participant Select-->
    <SFParticipantSelect
      ref="participant_select"
      :filter="filter.$model"
      :reloadingResponses="reloadingResponses"
      @participant_filter_changed="$emit('participant_filter_changed',$event)"
    >
    </SFParticipantSelect>
    <!--END::Participant Select-->

    <!--BEGIN::Activity Select-->
    <SFActivitySelect
      ref="activity_select"
      :filter="filter.$model"
      :reloadingResponses="reloadingResponses"
      @activity_filter_changed="$emit('activity_filter_changed',$event)"
    >
    </SFActivitySelect>
    <!--END::Activity Select-->

    <!--START::Question Select-->
    <SFQuestionSelect
      ref="question_select"
      v-if="streamFilter.questions.length > 0 || streamResults.questions.length > 0"
      :filter="filter.$model"
      :reloadingResponses="reloadingResponses"
    >
    </SFQuestionSelect>
    <!--END::Question Select-->

    <!--BEGIN::Segment Select-->
    <SFSegmentSelect
      :filter="filter.$model"
      :reloadingResponses="reloadingResponses"
      @segment_filter_changed="$emit('segment_filter_changed',$event)"
      @segment_option_changed="$emit('segment_option_changed',$event)"
    >
    </SFSegmentSelect>
    <!--END::Segment Select-->

    <!--BEGIN::Tag Select-->
    <SFTagSelect
      :filter="filter.$model"
      :reloadingResponses="reloadingResponses"
      @tag_filter_changed="$emit('tag_filter_changed',$event)"
    >
    </SFTagSelect>
    <!--END::Tag Select-->

    <!--BEGIN::Media Select-->
    <SFMediaSelect
      :filter="filter.$model"
      :reloadingResponses="reloadingResponses"
      @media_filter_changed="$emit('media_filter_changed',$event)"
    >
    </SFMediaSelect>
    <!--END::Media Select-->

    <!--BEGIN::Favourite Toggle-->
    <SFFavouriteToggle
      :filter="filter.$model"
      :reloadingResponses="reloadingResponses"
    >
    </SFFavouriteToggle>
    <!--END::Favourite Toggle-->

    <!--BEGIN::Note Toggle-->
    <SFNoteToggle
      :filter="filter.$model"
      :reloadingResponses="reloadingResponses"
    >
    </SFNoteToggle>
    <!--END::Note Toggle-->

    <!-- BEGIN::Reset Filter -->
    <b-button
      variant="outline-primary"
      size="sm"
      style="height:35px !important; margin-top:4px !important"
      class="px-5"
      :disabled="reloadingResponses"
      @click="$emit('reset_triggered')"
    >
      {{ $t('REVIEW.TEXT_ANALYSIS.RESET') }}
    </b-button>
    <!-- END::Reset Filter -->

    <!-- BEGIN::Apply Filter -->
    <b-button
      variant="primary"
      size="sm"
      style="height:35px !important; margin-top:4px !important"
      class="px-5"
      :disabled="reloadingResponses"
      @click="emitSubmit"
    >
      {{ $t('REVIEW.STREAM.FILTER_APPLY') }}
    </b-button>
    <!-- END::Apply Filter -->

  </div>
</template>

<script>
import { mapGetters } from  "vuex";

import SFGeneralInput from "@/view/components/form-inputs/stream_filters/GeneralInput";
import SFNewToggle from "@/view/components/form-inputs/stream_filters/NewToggle";
import SFDateSelect from "@/view/components/form-inputs/stream_filters/DateSelect";
import SFParticipantSelect from "@/view/components/form-inputs/stream_filters/ParticipantSelect";
import SFActivitySelect from "@/view/components/form-inputs/stream_filters/ActivitySelect";
import SFQuestionSelect from "@/view/components/form-inputs/stream_filters/QuestionSelect";
import SFSegmentSelect from "@/view/components/form-inputs/stream_filters/SegmentSelect";
import SFTagSelect from "@/view/components/form-inputs/stream_filters/TagSelect";
import SFMediaSelect from "@/view/components/form-inputs/stream_filters/MediaSelect";
import SFFavouriteToggle from "@/view/components/form-inputs/stream_filters/FavouriteToggle";
import SFNoteToggle from "@/view/components/form-inputs/stream_filters/NoteToggle";

export default {
  name: "StreamFilter",

  props: {
    reloadingResponses: { type: Boolean, required: true },
    filter: { type: Object, required: true }
  },

  components: {
    SFGeneralInput,
    SFNewToggle,
    SFDateSelect,
    SFParticipantSelect,
    SFActivitySelect,
    SFQuestionSelect,
    SFSegmentSelect,
    SFTagSelect,
    SFMediaSelect,
    SFFavouriteToggle,
    SFNoteToggle
  },

  methods: {
    emitSubmit: function() {
      this.$emit("filter_applied");
    },
    emitDateSelectionCleared: function() {
      this.$emit("date_filter_removed");
    }
  },

  computed: {
    ...mapGetters([
      'streamResults',
      'streamFilter'
    ]),
  }
}
</script>
<template>
  <div
    :class="{ 'mt-10 mx-0' : replyState.showReplies }"
    @mouseenter="$emit('hovering-replies',true)"
    @mouseleave="$emit('hovering-replies',false)"
  >
    <transition name="fade">
      <div v-if="replyState.showReplies">
        <transition name="fade">
          <ReplyInput
            class="mb-5"
            v-if="replyState.showReplyInput && currentUser.relation_to_project != 'observer'"
            :activityId="activityId"
            :repliable_type="repliable_type"
            :repliable_id="repliable_id"
            @reply-added="replies.unshift($event)"
            @reply-input-closed="$emit('cancel-reply-input')"
          >
          </ReplyInput>
        </transition>
        <ReplyContainer
          :generation="0"
          :replies="replies"
          :activityId="activityId"
          :baseResponse="baseResponse"
          :baseMorph="baseMorph"
          :baseMorphId="baseMorphId"
          :baseQuestionPosition="baseQuestionPosition"
          @toggleNotepad="$emit('toggleNotepad',$event)"
        ></ReplyContainer>
      </div>
    </transition>
  </div>  
</template>

<script>
import { mapGetters } from "vuex";

import ReplyInput from "./engagement/reply/ReplyInput";
import ReplyContainer from "./engagement/reply/ReplyContainer";

export default {
  name: "StreamReplies",

  props: {
    activityId: { type: Number, required: true },
    replies: { type: Array, required: true },
    repliable_type: { type: String, required: true },
    repliable_id: { type: Number, required: true },
    replyState: { type: Object, required: true },
    baseResponse: { type: Number, required: true },
    baseMorph: { type: String, required: true },
    baseMorphId: { type: Number, required: true },
    baseQuestionPosition: { type: Number, required: false, default: 0 }
  },

  components: {
    ReplyInput,
    ReplyContainer
  },

  computed: {
    ...mapGetters(["currentUser"]),

    replyData: function() {
      let replyData = {count: 0, myReplies: 0};
      let currentUser = this.currentUser;

      replyData.count = this.replies.length;

      replyData.myReplies = this.replies.filter(function( reply ) {
        return reply.user_id == currentUser.id;
      }).length;

      return replyData;
    }
  }
};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
<template>
  <input
    id="review_stream_search"
    v-model="filter.search"
    type="text"
    :placeholder="$t('GENERAL.INPUT.SEARCH')"
    class="form-control form-control-solid py-3 px-5 mr-2"
    style="height:40px !important; margin-top:4px !important; width: 20% !important"
    :disabled="reloadingResponses"
  />
</template>

<script>
export default {
  name: "GeneralInput",

  props: {
    filter: { type: Object, required: true },
    reloadingResponses: { type: Boolean, required: true }
  }
};
</script>

<template>
  <div>
    <InputGenericTextArea
      id="note-input"
      class="mb-2"
      :model="$v.form.note"
      :submissionStates="submissionStates"
      rows="3"
      :slim="true"
    >
    </InputGenericTextArea>
    <div class="row d-flex justify-content-end mx-0">
      <button
        type="button"
        @click="$emit('on-note-submit', form.note)"
        class="btn btn-sm btn-primary"
        :disabled="submissionStates.submitting"
      >
        {{ $t('GENERAL.BUTTONS.SUBMIT') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import InputGenericTextArea from "@/modules/together-helpers/components/generic-inputs/GenericTextArea";

export default {
  mixins: [validationMixin],

  name: "NoteInput",

  props: {
    morph: { type: Object, required: true }
  },

  data() {
    return {
      form: {
        note: ""
      },
      submissionStates: {
        submitting: false
      }
    }
  },

  validations: {
    form: {
      note: { required }
    }
  },

  components: {
    InputGenericTextArea
  },

  methods: {
    resetForm: function() {
      this.form.note = "";
    }  
  },
  
  computed: {
    ...mapGetters(["currentUser"]),
  }
}
</script>
<template>
  <a
    href="javascript:void(0)"
    v-if="!legacy"
    @click="destroyEngagement"
    class="btn btn-icon-danger btn-sm btn-text-dark-50 bg-hover-light-danger btn-hover-text-danger rounded font-weight-bolder font-size-xs p-2"
    v-b-tooltip.hover.bottom
    :title="tooltip"
    :disabled="submitting"
  >
    <span class="svg-icon svg-icon-md svg-icon-danger px-0 mx-0">
      <i class="fas fa-trash text-danger fa-lg px-0 mx-0"></i>
    </span>
  </a>
</template>

<script>
import { DELETE_BLOG_RESPONSES } from "@/core/services/store/activity/activity_blog_responses.module";
import { DELETE_DIARY_RESPONSES } from "@/core/services/store/activity/activity_diary_responses.module";
import { DELETE_DISCUSSION_RESPONSES } from "@/core/services/store/activity/activity_discussion_responses.module";
import { DELETE_SURVEY_RESPONSES } from "@/core/services/store/activity/activity_survey_responses.module";
import { DELETE_PICTURE_BOOK_RESPONSES } from "@/core/services/store/activity/activity_picture_book_responses.module";
import { DELETE_IDEASTORM_RESPONSES } from "@/core/services/store/activity/activity_ideastorm_responses.module";
import { DELETE_EXTERNAL_RESPONSES } from "@/modules/together-helpers/vuex/activity/response/activity_external_responses.module";
import { DELETE_MARKUP_RESPONSES } from "@/core/services/store/activity/activity_markup_responses.module";
import { DELETE_POLL_RESPONSES } from "@/core/services/store/activity/activity_poll_responses.module";
import { DELETE_REPLY_ENGAGEMENTS } from "@/core/services/store/activity/activity_reply_engagements.module";
import { STRIP_ACTIVITY_RESPONSE, STRIP_ACTIVITY_ENGAGEMENT, REPLACE_REPLY_ENGAGEMENT } from "@/core/services/store/project/project_stream.module";

export default {
  name: "ResponseDestroy",

  props: {
    type: { type: String, required: true },
    response: { type: Object, required: true },
    activityId: { type: Number, required: true },
    baseResponse: { type: Number, required: true },
    baseMorph: { type: String, required: false },
    baseMorphId: { type: Number, required: false },
    baseQuestionPosition: { type: Number, required: false, default: 0 },
    legacy: { type: Boolean, required: false, default: false }
  },

  data() {
    return {
      submitting: false
    }
  },

  methods: {
    destroyEngagement: function() {

      if (this.submitting) {
        return;
      }

      this.submitting = true;

      this.$swal.fire({
        title: `${this.$t("SWEETALERTS.DELETE_RESPONSE_CHALLENGE.TITLE")}`,
        text: `${this.$t("SWEETALERTS.DELETE_RESPONSE_CHALLENGE.MESSAGE")}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `${this.$t("SWEETALERTS.DELETE_RESPONSE_CHALLENGE.CONFIRM")}`,
        cancelButtonText: `${this.$t("GENERAL.TEXT.CANCEL")}`,
      }).then((result) => {
        if (result.isConfirmed) {
          let payload = { activityId: this.activityId, id: this.response.id, activity_id: this.activityId };
          this.$store.dispatch(this.deleteAction, payload).then(() => {
            if (this.type == 'ActivityResponseReply') {
              if (this.response.repliable_type != "ActivityResponseReply") {
                payload = {
                  type: "replies",
                  engagementId: this.response.id,
                  activityResponseId: this.baseResponse,
                  baseMorph: this.baseMorph,
                  baseQuestionPosition: this.baseQuestionPosition
                };
                this.$store.dispatch(STRIP_ACTIVITY_ENGAGEMENT, payload);                
              } else {
                payload = {
                  activityId: this.response.activity_id,
                  activityResponseId: this.baseResponse,
                  params: {
                    repliable_type: this.baseMorph,
                    repliable_id: this.baseMorphId                    
                  }
                };
                this.$store.dispatch(REPLACE_REPLY_ENGAGEMENT, payload);
              }
            } else {
              this.$store.dispatch(STRIP_ACTIVITY_RESPONSE, this.response.activity_response_id);
            }
            this.$swal.fire({
              title: `${this.$t("SWEETALERTS.DELETE_RESPONSE_SUCCESS.TITLE")}`,
              text: `${this.$t("SWEETALERTS.DELETE_RESPONSE_SUCCESS.MESSAGE")}`,
              icon: 'success',
              confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
            })
          })
          .catch(() => {
            this.$swal.fire({
              title: `${this.$t("GENERAL.TEXT.VAGUE_ERROR_TITLE")}`,
              text: `${this.$t("SWEETALERTS.DELETE_RESPONSE_FAIL.MESSAGE")}`,
              icon: 'error',
              confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
            })
          })
        }
      });

      this.submitting = false;
    }
  },

  computed: {
    tooltip: function() {
      let tooltip = `${this.$t("REVIEW.STREAM.DESTROY")}`;

      return tooltip;
    },
    deleteAction: function() {
      let action = null;

      switch (this.type) {
        case "ActivityBlogResponse":
          action = DELETE_BLOG_RESPONSES;
          break;
        case "ActivityDiaryResponse":
          action = DELETE_DIARY_RESPONSES;
          break;
        case "ActivityDiscussionResponse":
          action = DELETE_DISCUSSION_RESPONSES;
          break;
        case "ActivitySurveyResponse":
          action = DELETE_SURVEY_RESPONSES;
          break;
        case "ActivityPictureBookResponse":
          action = DELETE_PICTURE_BOOK_RESPONSES;
          break;
        case "ActivityIdeastormResponse":
          action = DELETE_IDEASTORM_RESPONSES;
          break;
        case "ActivityExternalResponse":
          action = DELETE_EXTERNAL_RESPONSES;
          break;
        case "ActivityMarkupResponse":
          action = DELETE_MARKUP_RESPONSES;
          break;
        case "ActivityPollResponse":
          action = DELETE_POLL_RESPONSES;
          break;
        case "ActivityResponseReply":
          action = DELETE_REPLY_ENGAGEMENTS;
          break;
      }

      return action;
    }
  }
}
</script>
<template>
  <div class="row d-flex justify-content-between mx-0" style="min-height: 30px" :class="{ 'mt-10' : !slim }">
    <div class="row d-flex justify-content-start mx-0">
      <EngagementSentiment
        v-if="sentiment"
        :sentimentableType="type"
        :sentimentableId="response.id"
        :sentiments="response.sentiments"
        :activityId="activityId"
        :legacy="isLegacy"
      >
      </EngagementSentiment>

      <EngagementReply
        v-if="reply"
        :replies="response.replies"
        :activityId="activityId"
        :replyState="replyState"
        @toggle_replies="$emit('toggle_replies')"
        @toggle_reply_input="$emit('toggle_reply_input')"
        :legacy="isLegacy"
      >
      </EngagementReply>

      <EngagementFavourite
        v-if="favourite"
        :favouritableType="type"
        :favouritableId="response.id"
        :favourites="response.favourites"
        :activityId="activityId"
        :legacy="isLegacy"
      >
      </EngagementFavourite>

      <EngagementNotepad
        v-if="notepad"
        :morphType="type"
        :morphId="response.id"
        :activityId="activityId"
        :response="response"
        @toggleNotepad="$emit('toggleNotepad',$event)"
        :legacy="isLegacy"
      >
      </EngagementNotepad>

      <EngagementNotepad
        v-if="notepad"
        :morphType="type"
        :morphId="response.id"
        :activityId="activityId"
        :response="response"
        type="tags"
        @toggleNotepad="$emit('toggleNotepad',$event)"
        :legacy="isLegacy"
      >
      </EngagementNotepad>

      <div v-if="reply && !isLegacy" class="pt-1">
        <a
          v-if="!replyState.showReplyInput && currentUser.relation_to_project != 'observer'"
          href="javascript:void(0)"
          class="text-primary ml-2 font-size-sm text-muted"
          @click="$emit('toggle_reply_input')"
        >
          {{ $t('GENERAL.BUTTONS.ADD_COMMENT') }}
        </a>
      </div>
      
    </div>
    <div v-if="destroy">
      <EngagementDestroy
        v-if="currentUser.relation_to_project != 'observer'"
        v-show="hovering"
        :type="type"
        :response="response"
        :activityId="activityId"
        :baseResponse="baseResponse"
        :baseMorph="baseMorph"
        :baseMorphId="baseMorphId"
        :baseQuestionPosition="baseQuestionPosition"
        :legacy="isLegacy"
      >
      </EngagementDestroy>
    </div>

  </div>
</template>

<script>
import {
  mapState,
  mapGetters
} from "vuex";

import EngagementSentiment from "./engagement/Sentiment";
import EngagementReply from "./engagement/Reply";
import EngagementFavourite from "./engagement/Favourite";
import EngagementDestroy from "./actions/Destroy";
import EngagementNotepad from "./engagement/Notepad";

export default {
  name: "InlineEngagement",

  props: {
    type: { type: String, required: true },
    sentiment: { type: Boolean, required: true },
    reply: { type: Boolean, required: true },
    favourite: { type: Boolean, required: true },
    destroy: { type: Boolean, required: true },
    notepad: { type: Boolean, required: true },
    response: { type: Object, required: true },
    activityId: { type: Number, required: true },
    replyState: { type: Object, required: false },
    hovering: { type: Boolean, required: true },
    baseResponse: { type: Number, required: true },
    baseMorph: { type: String, required: false },
    baseMorphId: { type: Number, required: false },
    slim: { type: Boolean, required: false, default: false },
    baseQuestionPosition: { type: Number, required: false, default: 0 }
  },

  data() {
    return {
      replyData: {
        count: 0,
        myReplies: 0
      },
      replies: []
    }
  },

  created() {
    if (this.reply) {
      this.replies = this.response.replies;
    }
  },

  methods: {
    removeReply: function(val) {
      let newReplies = this.replies.filter(function( reply ) {
        return reply.id !== val;
      });
      this.replies = newReplies;
    }
  },

  components: {
    EngagementSentiment,
    EngagementReply,
    EngagementFavourite,
    EngagementDestroy,
    EngagementNotepad
  },

  computed: {
    ...mapState({
      legacy: state => state.projectStream.legacy
    }),
    ...mapGetters([
      "currentUser"
    ]),
    isLegacy: function() {
      if (this.legacy == 1) {
        return true;
      } else {
        return false;
      };
    }
  }
};
</script>

<template>
  <div ref="stream-participant-select">
    <b-dropdown
      :text="computedButtonText"
      variant="outline-primary"
      :class="calculatedClassList"
      size="sm"
      v-b-tooltip.hover.top
      :title="$t('REVIEW.STREAM.FILTER_PARTICIPANTS_TOOLTIP')"
      no-caret
      :disabled="reloadingResponses"
    >
      <input
        v-model="search"
        type="text"
        :placeholder="$t('GENERAL.INPUT.SEARCH')"
        class="form-control form-control-solid py-3 px-5 mb-5"
        style="height:35px !important; margin-top:4px !important"
      />
      <div :style="'height:' + calculatedScrollHeight + 'px'">
        <vuescroll>
          <template v-for="(projectUser, i) in computedProjectUsers">
            <b-form-group
              :key="'participant-' + ((i + 1) * resets)"
              class="mb-0 mx-2"
              :id="'project_user-check-group' + projectUser.id"
              :label="calculateSRLabel(projectUser)"
              :label-sr-only="true"
              :label-for="'project_user-check-group' + projectUser.id"
            >
              <b-form-checkbox
                :id="'project_user-check' + projectUser.id"
                value="1"
                unchecked-value="0"
                size="lg"
                @change="onChange($event, projectUser)"
              >
                <p class="pt-1 mb-2">
                  <AnonymisedUsername :user="projectUser"></AnonymisedUsername>
                </p>
              </b-form-checkbox>
            </b-form-group>
          </template>
        </vuescroll>
      </div>
      <p v-if="computedProjectUsers.length == 0" class="mx-2">{{ $t('REVIEW.STREAM.NO_SEARCH_PLACEHOLDER') }}</p>
      <div class="w-100 d-flex justify-content-center mt-3">
        <a
          v-if="participantCount > 0"
          href="javascript:void(0)"
          @click="filterReset('participants'); resets += projectUsers.length"
          class="text-center font-size-sm"
        >
          {{ $t('REVIEW.TEXT_ANALYSIS.RESET') }}
        </a>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from  "vuex";
import { mapMutations } from 'vuex'
import vuescroll from 'vuescroll';

export default {
  name: "ParticipantSelect",

  props: {
    filter: { type: Object, required: true },
    reloadingResponses: { type: Boolean, required: true }
  },

  data() {
    return {
      search: "",
      checkedParticipants: [],
      resets: 1
    }
  },

  components: {
    vuescroll
  },

  mounted() {
    this.onReset();
    this.setList(this.computedParticipantFilterList);
  },

  methods: {
    setList: function(participants) {
      participants.forEach(function (participant) {
        document.getElementById('project_user-check' + participant.id).click();
      });
    },
    onReset: function(initial = false) {
      this.filterReset('participants');
      this.resets = (initial) ? 1 : this.resets += this.projectUsers.length;  
    },
    onChange: function(event, projectUser) {
      let payload = { key: 'participants', id: projectUser.id, display: projectUser.display_name };
      (event == 1) ? this.filterAdd(payload) : this.filterStrip(payload);
    },
    ...mapMutations({
      filterAdd: 'addToFilter',
      filterStrip: 'stripFromFilter',
      filterReset: 'resetFilterList'
    }),
    calculateSRLabel: function(projectUser) {
      if (this.currentUser.projectProfile != undefined && this.currentUser.projectProfile.system_project_role_id == 2) {
        return "participant_" + projectUser.id;
      } else {
        return projectUser.display_name;
      }
    }
  },

  watch:{
    'filter.reset': function (newVal, oldVal) {
      if (oldVal == false && newVal == true) {
        this.onReset();
      }
    }
  },


  computed: {
    ...mapGetters([
      "projectInfo",
      "projectUsers",
      "streamFilter",
      "currentUser"
    ]),
    ...mapState({
      legacy: state => state.projectStream.legacy
    }),
    computedButtonText: function() {
      if (this.filter.participants.length == 0) {
        return `${this.$t('REVIEW.STREAM.FILTER_PARTICIPANTS')}`;
      } else if (this.filter.participants.length == 1) {
        return this.filter.participants[0].display;
      } else {
        return this.filter.participants.length + ' ' + `${this.$t('REVIEW.STREAM.FILTER_PARTICIPANTS')}`;
      }
    },
    participantCount: function() {
      return this.filter.participants.length;
    },
    calculatedClassList: function() {
      let classList = "";

      if (this.computedProjectUsers.length == 0 || this.computedProjectUsers.length == 1) {
        classList = classList + "sf-height-1";
      } else if (this.computedProjectUsers.length > 6) {
        classList = classList + "sf-height-6";
      } else {
        classList = classList + "sf-height-" + this.computedProjectUsers.length;
      }

      if (this.participantCount == 0) {
        classList = classList + " null";
      } else {
        classList = classList + " selected";
      }

      return classList;
    },
    calculatedScrollHeight: function() {
      if (this.computedProjectUsers.length == 1 || this.computedProjectUsers.length == 0) {
        return 28.75;
      } else if (this.computedProjectUsers.length >= 6) {
        return 28.75 * 6;
      } else {
        return this.computedProjectUsers.length * 28.75;
      }
    },
    computedProjectUsers: function() {
      var self = this;
      if (self.search.length == 0) {
        return self.projectUsers;
      } else {
        return self.projectUsers.filter(function( user ) {
          return user.name.toLowerCase().includes(self.search.toLowerCase()) ||
          user.calculatedEmail.toLowerCase().includes(self.search.toLowerCase()) ||
          user.display_name.toLowerCase().includes(self.search.toLocaleLowerCase())
        });
      }
    },
    computedParticipantFilterList: function() {
      return this.streamFilter.participants;
    }
  },
}
</script>

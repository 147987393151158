<template>
  <div>

    <b-spinner
      v-if="loadingComplete == false"
      variant="primary"
      label="Spinning"
      class="card-loader"
    >
    </b-spinner>

    <!--begin::Navigation-->
    <ul v-if="loadingComplete && !error.active" class="nav nav-tabs nav-tabs-line nav-tabs-line-2x">
      <li class="nav-item" @click="tab = 1">
        <a
          class="nav-link"
          :class="{ 'active' : tab == 1}"
          data-toggle="tab"
          href="javascript:void(0)"
        >
          {{ $t('REVIEW.STREAM.FILTER_NOTES') }}
        </a>
      </li>
      <li class="nav-item" @click="tab = 2">
        <a
          class="nav-link"
          :class="{ 'active' : tab == 2}"
          data-toggle="tab"
          href="javascript:void(0)"
        >
          {{ $t('REVIEW.STREAM.FILTER_TAGS') }}
        </a>
      </li>
    </ul>
    <!--end::Navigation-->

    <!--begin::Notes-->
    <div class="h-100" v-if="tab == 1 && loadingComplete && !error.active">
      <div class="d-flex align-items-between flex-column h-100 pt-5 pb-10">
        <vuescroll>
          <template v-for="(note, i) in computedNotes">
            <Note
              :key="i"
              :note="note"
              :activityId="morph.activityId"
              :newSchema="newSchema"
              :baseResponse="morph.baseResponse"
              @note-removed="notes = notes.filter(function( note ) { return note.id !== $event });"
            >
            </Note>
          </template>
        </vuescroll>
        <NoteInput
          ref="note-input"
          v-if="!legacy"
          :morph="morph"
          @on-note-submit="submitNote"
        >
        </NoteInput>
      </div>
    </div>
    <!--end::Notes-->

    <!--begin::Tags-->
    <div v-if="tab == 2 && loadingComplete && !error.active">
      <CustomTags
        :tags="customTags"
        :morph="morph"
        :newSchema="newSchema"
        @on-tag-submit="submitTag"
        @on-tag-removed="removeTag"
      >
      </CustomTags>
      <ProjectTags
        v-if="!legacy && !newSchema"
        :tags="projectTags"
        :morph="morph"
        @tag-added="tags.push($event)"
        @tag-removed="tags = tags.filter(function( tag ) { return tag.id !== $event });"
      >
      </ProjectTags>
    </div>
    <!--end::Tags-->

  </div>
</template>

<script>
import {
  mapGetters,
  mapActions
} from "vuex";
import { mapState } from "vuex";
import vuescroll from 'vuescroll';

import { POPULATE_LEGACY_NOTE_ENGAGEMENTS_LIST} from "@/core/services/store/activity/activity_note_engagements.module";
import { POPULATE_LEGACY_TAG_ENGAGEMENTS_LIST} from "@/core/services/store/activity/activity_tag_engagements.module";
import { POPULATE_PROJECT_TAG_LIST } from "@/core/services/store/project/project_tags.module";

import Note from "./Note";
import NoteInput from "./NoteInput";
import CustomTags from "./CustomTags";
import ProjectTags from "./ProjectTags"

import LoadingCompleteHelper from "@/helpers/LoadingCompleteHelper";

export default {
  name: "NotepadContainer",

  data() {
    return {
      tab: 1,
      pageLoader: {
        componentsRequired: 3,
        componentsCompleted: 0
      },
      error: {
        active: false,
        message: ""
      },
      morph: {
        type: null,
        id: null,
        activityId: null,
        baseResponse: null
      },
      tags: [],
      notes: []
    }
  },

  components: {
    vuescroll,
    Note,
    NoteInput,
    CustomTags,
    ProjectTags
  },

  mounted() {
    this.fetchProjectTags();
  },

  methods: {
    setTab: function(tab) {
      this.tab = tab;
    },
    setMorph: function(type, id, activityId, baseResponse) {
      this.morph = {
        type: type,
        id: id,
        activityId: activityId,
        baseResponse: baseResponse
      };
    },
    fetchEngagements: function() {
      if (this.legacy) {
        this.fetchLegacyNoteEngagements();
        this.fetchLegacyTagEngagements();
      } else {
        this.fetchEngagementsCategory('note');
        this.fetchEngagementsCategory('tag');
      }
    },
    fetchEngagementsCategory: function(type) {
      let action = null;
      let payload = null;

      if (this.newSchema) {
        action = this.genericResource;
        payload = {
          route: `api/v1/engagement_responses/${this.morph.baseResponse.id}/engagement_interactions`,
          params: {
            paginate: false,
            engagement_interaction_type: this.morph.type,
            engagement_interaction_id: this.morph.id
          },
          filters: {
            system_interaction_type_id: (type === 'note') ? 2 : 5
          },
          relations: {
            user: 'with'
          }
        };
      } else if (type === 'note') {
        action = this.populateActivityNoteEngagementsList;
        payload = {
          activityId: this.morph.activityId,
          params: {
            notable_type: this.morph.type,
            notable_id: this.morph.id,
            user: 'with'
          }
        };
      } else if (type === 'tag') {
        action = this.populateActivityTagEngagementsList;
        payload = {
          activityId: this.morph.activityId,
          params: {
            taggable_type: this.morph.type,
            taggable_id: this.morph.id,
            user: 'with'
          }
        };
      }

      action(payload).then((res) => {
        switch (type) {
          case 'note':
            this.notes = (this.newSchema) ? res._embedded.engagement_interactions : res;
            break;
          case 'tag':
            this.tags = (this.newSchema) ? res._embedded.engagement_interactions : res;
            break;
        }

        this.pageLoader.componentsCompleted++;
      });
    },
    fetchLegacyNoteEngagements: function() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          notable_id: this.morph.id
        }
      };

      this.$store
        .dispatch(POPULATE_LEGACY_NOTE_ENGAGEMENTS_LIST, payload)
        .then((res) => {
          this.notes = res;
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the legacy notes, please check back later or contact the helpdesk";
        })
    },
    fetchLegacyTagEngagements: function() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          taggable_id: this.morph.id
        }
      };

      this.$store
        .dispatch(POPULATE_LEGACY_TAG_ENGAGEMENTS_LIST, payload)
        .then((res) => {
          this.tags = res;
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the legacy tags, please check back later or contact the helpdesk";
        })
    },
    fetchProjectTags: function() {
      if (!this.legacy) {
        let payload = {
          projectId: this.projectInfo.id
        };

        this.$store
          .dispatch(POPULATE_PROJECT_TAG_LIST, payload)
          .then(() => {
            this.pageLoader.componentsCompleted++;
          })
          .catch(() => {
            this.error.active = true;
            this.error.message =
              "We're having some issues retrieving the project tags, please check back later or contact the helpdesk";
          })
      } else {
        this.tags = [];
      };
    },
    removeTag: function(tag) {
      let tagModel = this.tags.filter(function(tagObject) {
        return tagObject.value === tag
      });

      if (tagModel.length === 0) {
        return;
      }

      let payload = {
        engagement_response_id: this.morph.baseResponse.id,
        engagement_interaction_id: tagModel[0].id
      };

      this.deleteEngagementInteraction(payload).then(() => {
        this.tags = this.tags.filter(function(tag) {
          return tag.id != tagModel[0].id;
        });
      });
    },
    submitTag: function(tag) {
      let action = this.createEngagementInteraction;
      let payload = {
        value: tag,
        engagement_response_id: this.morph.baseResponse.id,
        engagement_interaction_type: this.morph.type,
        engagement_interaction_id: this.morph.id,
        system_interaction_type_id: 5
      }

      action(payload).then((res) => {
        this.tags.push(res);
      })
    },
    submitNote: function(note) {
      let action = null;
      let payload = null;

      if (this.newSchema) {
        action = this.createEngagementInteraction;
        payload = {
          value: note,
          engagement_response_id: this.morph.baseResponse.id,
          engagement_interaction_type: this.morph.type,
          engagement_interaction_id: this.morph.id,
          system_interaction_type_id: 2
        }
      } else {
        action = this.createNoteEngagements;
        payload = {
          activityId: this.morph.activityId,
          data: {
            notable_type: this.morph.type,
            notable_id: this.morph.id,
            note: note
          }
        };
      }

      action(payload).then((res) => {
        if (!this.newSchema) {
          res.user = this.currentUser;
          this.$emit('note-added',res);    
        }

        this.notes.push(res);
        this.$refs['note-input'].resetForm();
      })
    },
    ...mapActions({
      genericResource: "genericResource",
      populateActivityNoteEngagementsList: "populateActivityNoteEngagementsList",
      populateActivityTagEngagementsList: "populateActivityTagEngagementsList",
      createNoteEngagements: "createNoteEngagements",
      createEngagementInteraction: "createEngagementInteraction",
      deleteEngagementInteraction: "deleteEngagementInteraction"
    })
  },

  watch: {
    morph: {
      handler(val) {
        if (val.type != null && val.id != null) {
          this.notes = [];
          this.pageLoader.componentsCompleted-=2;
          this.fetchEngagements();
        } else {
          this.pageLoader.componentsCompleted+=2;
        }
      },
      deep: true,
      immediate: true
    }
  },

  computed: {
    ...mapGetters(["projectInfo"]),

    ...mapState({
      legacy: state => state.projectStream.legacy
    }),

    loadingComplete: function() {
      return LoadingCompleteHelper.loadingComplete(this.pageLoader, this.error);
    },

    customTags: function() {
      if (this.newSchema) {
        return this.tags;
      } else {
        return this.tags.filter(function( tag ) {
          return tag.project_tag_id == null;
        });        
      }
    },
    projectTags: function() {
      return this.tags.filter(function( tag ) {
        return tag.project_tag_id != undefined;
      });
    },
    computedNotes: function() {
      return this.notes.filter((obj, index, self) =>
        index === self.findIndex((o) => o.id === obj.id)
      );
    },
    newSchema: function() {
      return (this.morph.type === 'EngagementResponse' || this.morph.type === 'EngagementInteraction');
    }
  }
};
</script>

<template>
  <div class="p-5">
    <div class="barChart p-10" ref="multipleChoice"></div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

export default {
  name: "ResponseChartMultipleChoice",

  props: {
    chartData: { type: Array, required: true },
    index: { type: Number, required: true }
  },

  mounted() {
    this.generateChart();
  },

  methods: {
    generateChart: function() {
      let chart = am4core.create(this.$refs.multipleChoice, am4charts.XYChart);

      chart.paddingRight = 20;
      chart.cursor = new am4charts.XYCursor();

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "choice";
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.maxPrecision = 0;

      var series = chart.series.push(new am4charts.ColumnSeries());
      series.columns.template.fill = am4core.color("#00297a");
      series.columns.template.stroke = am4core.color("#00297a");
      series.columns.template.tooltipText = "Choice: {choice}\nValue: {value}";
      series.dataFields.valueY = "value";
      series.dataFields.categoryX = "choice";

      var label = categoryAxis.renderer.labels.template;
      label.wrap = true;
      label.maxWidth = 120;
      label.rotation = -90;
      categoryAxis.renderer.minGridDistance = 30;

      chart.data = this.chartData;
      
      this.$emit('chart-generated',chart);
      this.chartAdd({ index: this.index, chart: chart });
    },
    ...mapMutations({
      chartAdd: 'addToChart',
    }),
  }
}
</script>

<style scoped>
  .barChart {
    width: 100%;
    height: 500px;
  }
</style>

<template>
  <div v-if="showContainer" class="row mx-0 stream-response-mark-up" :id="'markup-container-response-' + response.id">
    <div
      v-if="aggregated === false"
      class="d-flex justify-content-between flex-wrap w-100 mb-15"
    >
      <div class="col-xl-7 px-0 mb-0">
        <b-form-group id="mark-up-questions-select" class="w-100 px-0 mb-0">
          <b-form-select
            v-model="selectedQuestion"
            :options="computedSelectQuestions"
            class="form-control form-control-solid py-4 px-6 h-auto"
          ></b-form-select>
        </b-form-group>
      </div>

      <div class="col-xl-5 px-0">
        <div class="row mx-0 px-0 d-flex justify-content-end align-items-center h-100">
          <a
            href="javascript:void(0)"
            class="text-muted"
            @click="$emit('open-chart-modal', { activityId: activityId, question: null, markupQuestion: selectedQuestion })"
          >
            {{ $t('REVIEW.STREAM.VIEW_CHART') }}
          </a>
        </div>
      </div>

    </div>

    <div
      class="w-100 mb-5 d-flex justify-content-center position-relative"
      :class="(aggregated === true) ? 'mb-10' : 'mb-0'"
    >
      <!-- Mark Up Image -->
      <b-img
        :src="image"
        v-if="isMarkUpImage"
        :style="computedStyle"
        ref="markUpImage"
        @load="loadedImage()"
      ></b-img>
      <!-- Mark Up Image -->

      <!--begin::Mark Up Video -->
      <div class="mark-up-video" v-else>
        <video-player
          class="video-player-box"
          ref="videoPlayer"
          :options="playerOptions"
          :playsinline="true"
          @loadeddata="onPlayerLoadeddata($event)"
          @timeupdate="onPlayerTimeupdate($event)"
          @ready="playerReadied"
        >
        </video-player>
      </div>
      <!-- Mark Up Video -->

      <div
        :class="{
          'mark-up-video-overlay': !isMarkUpImage,
          'mark-up-image-overlay': isMarkUpImage
        }"
        :show="mediaLoaded"
        :style="isMarkUpImage ? overlaySize : ''"
      >
        <div v-if="!isMarkUpImage" class="prefix-time">
          {{ formattedCurrentTime }}
        </div>
        <b-progress
          v-if="!isMarkUpImage"
          height="2px"
          :value="currentVideoProgress"
        ></b-progress>

        <MarkUpPins
          v-for="index in totalPinsForThisQuestions()"
          :key="index"
          :pinsColor="pinsColor(index)"
          :maxY="maxY"
          :maxX="maxX"
          :maxTime="videoDuration"
          :pin="pinsForThisQuestions(index)"
          :isMarkUpImage="isMarkUpImage"
          @clickedPin="displayPinDetail($event)"
        ></MarkUpPins>

        <div v-if="!isMarkUpImage" class="suffix-time">
          {{ formattedVideoDuration }}
        </div>
      </div>
    </div>

    <div class="w-100 mb-5 d-flex" v-if="selectedPin !== false">
      <MarkUpPinsDetail
        :allQuestion="questions"
        :pinDetail="getPinDetail(selectedPin)"
        :isMarkUpImage="isMarkUpImage"
        @playVideoByPin="changeVideoPlayByPin($event)"
      ></MarkUpPinsDetail>
    </div>
  </div>
</template>
<script>
import MarkUpPins from "@/view/components/stream-responses/components/MarkUpPins.vue";
import MarkUpPinsDetail from "@/view/components/stream-responses/components/MarkUpPinsDetail.vue";
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
export default {
  name: "StreamMarkup",

  props: {
    activityId: { type: Number, required: true },
    response: { type: Object, required: true },
    questions: { type: Array, required: true },
    media: { type: Array, required: true },
    aggregated: { type: Boolean, required: false, default: false }
  },

  components: {
    MarkUpPins,
    MarkUpPinsDetail,
    videoPlayer
  },

  data() {
    return {
      showContainer: false,
      imageLoaded: false,
      videoLoaded: false,
      selectedQuestion: 0,
      videoDuration: 0,
      currentVideoProgress: 0,
      formattedCurrentTime: "00:00",
      formattedVideoDuration: "00:00",
      selectedPin: false,
      maxWidth: 0,
      maxHeight: 0
    };
  },

  mounted() {
    let options = this.computedSelectQuestions[0];
    this.selectedQuestion = options.value;

    this.showContainer = true;
    this.$nextTick(function() {
      this.calculateDimensions();
    })
  },

  methods: {
    calculateDimensions: function() {
      let container = document.querySelector('#markup-container-response-' + this.response.id);
      let maxWidth = (container.offsetWidth / 2);
      if (this.media[0].width > maxWidth) {
        let newRatio = maxWidth / this.media[0].width;
        this.maxWidth = maxWidth
        this.maxHeight = this.media[0].height * newRatio;
      } else {
        this.maxWidth = this.media[0].width;
        this.maxHeight = this.media[0].height;
      }
    },
    loadedImage() {
      this.imageLoaded = true;
    },
    totalPinsForThisQuestions() {
      if (this.selectedQuestion == 0) {
        return this.response.markup_question_responses.length;
      } else {
        let index = this.computedPairingQuestionAndPins.findIndex(
          q => q.question_id === this.selectedQuestion
        );

        if (index !== -1) {
          return this.computedPairingQuestionAndPins[index].pins.length;
        } else {
          return 0;
        }
      }
    },
    pinsForThisQuestions(pinIndex) {
      if (this.selectedQuestion == 0) {
        return this.response.markup_question_responses[pinIndex - 1];
      } else {
        let index = this.computedPairingQuestionAndPins.findIndex(
          q => q.question_id === this.selectedQuestion
        );

        if (index !== -1) {
          return this.computedPairingQuestionAndPins[index].pins[pinIndex - 1];
        } else {
          return null;
        }
      }
    },
    playerReadied() {
      this.videoLoaded = true;
    },
    getSecondsToTimeString(number, needHours = false) {
      var sec_num = parseInt(number, 10); // don't forget the second param
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - hours * 3600) / 60);
      var seconds = sec_num - hours * 3600 - minutes * 60;

      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return needHours
        ? hours + ":" + minutes + ":" + seconds
        : minutes + ":" + seconds;
    },
    onPlayerLoadeddata(player) {
      this.videoDuration = player.duration();
      this.formattedVideoDuration = this.getSecondsToTimeString(
        this.videoDuration
      );
    },
    onPlayerTimeupdate(player) {
      let currentTime = player.currentTime();
      this.formattedCurrentTime = this.getSecondsToTimeString(currentTime);
      this.currentVideoProgress = Math.floor(
        (currentTime / this.videoDuration) * 100
      );
    },
    changeVideoPlayByPin($event) {
      this.markUpVideoPlayer.currentTime($event.pinTimestamp);
    },
    getSampleColor(number) {
      let sampleColor = [
        "primary",
        "success",
        "info",
        "warning",
        "danger",
        "dark"
      ];
      let index = parseInt(number) % sampleColor.length;
      let labelColor = sampleColor[index];
      return labelColor;
    },
    pinsColor(index) {
      return this.getSampleColor(index);
    },
    displayPinDetail(pinDetail) {
      if (this.selectedPin !== pinDetail.pinId) {
        this.selectedPin = pinDetail.pinId;
      } else {
        this.selectedPin = false;
      }
    },
    getPinDetail(pinId) {
      let response = this.response.markup_question_responses;
      if (response) {
        let found = response.find(pin => pin.id === pinId);
        return found;
      } else {
        return false;
      }
    }
  },

  watch: {
    selectedQuestion: {
      handler() {
        this.selectedPin = false;
      },
      deep: true
    }
  },

  computed: {
    isMarkUpImage: function() {
      let asset = this.media[0];
      if (asset.type == 1) {
        return false;
      } else if (asset.type == 2) {
        return true;
      }
      return false;
    },
    image() {
      return this.isMarkUpImage ? this.media[0].signedUrl : null;
    },
    maxY: function() {
      return this.imageLoaded ? this.$refs.markUpImage.clientHeight : 0;
    },
    maxX: function() {
      return this.imageLoaded ? this.$refs.markUpImage.clientWidth : 0;
    },
    overlaySize: function() {
      return `width: ${this.maxX}px; height: ${this.maxY}px;`;
    },
    computedSelectQuestions() {
      let options = [
        {
          value: 0,
          text: this.$t("REVIEW.STREAM.MARKUP_SELECT_ALL_LABEL")
        }
      ];
      this.questions.forEach((question, index) => {
        let regex = /(<([^>]+)>)/gi;
        let strippedHtmlQuestion = question.question.replace(regex, "");
        options.push({
          value: question.id,
          text: `Q ${index + 1}. ${strippedHtmlQuestion}`
        });
      });
      return options;
    },
    computedPairingQuestionAndPins() {
      let answerByQuestion = [];
      let response = this.response.markup_question_responses;
      if (response) {
        response.forEach(pin => {
          let found = this.questions.find(
            questions => questions.id == pin.activity_markup_question_id
          );
          if (found !== undefined) {
            let index = answerByQuestion.findIndex(
              q => q.question_id === pin.activity_markup_question_id
            );

            if (index == -1) {
              answerByQuestion.push({
                pins: [pin],
                question_id: pin.activity_markup_question_id
              });
            } else {
              answerByQuestion[index].pins.push(pin);
            }
          }
        });
      }
      return answerByQuestion;
    },
    markUpVideoPlayer() {
      return this.$refs.videoPlayer.player;
    },

    playerOptions: function() {
      if (!this.isMarkUpImage) {
        let asset = this.media[0];
        return {
          height: "435",
          width: "580",
          language: "en",
          sources: [
            {
              type: "video/webm",
              src: asset.signedUrl
            }
          ],
          poster: asset.signedThumbUrl
        };
      } else {
        return null;
      }
    },
    mediaLoaded() {
      return this.imageLoaded || this.videoLoaded;
    },
    computedStyle: function() {
      return "width: " + this.maxWidth + "px; height: " + this.maxHeight + "px";
    },
  }
};
</script>

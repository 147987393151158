<template>
  <div class="mark-up-pins" :style="pinPositionStyle" @click="clickedPin()">
    <span
      v-if="isMarkUpImage"
      :class="`svg-icon svg-icon-4x svg-icon-${pinsColor}`"
    >
      <inline-svg src="media/svg/icons/Map/Marker2.svg" />
    </span>
    <span
      v-else
      v-b-tooltip.hover.bottom.v-dark
      :title="`@ ${pinTime}`"
      :class="
        `svg-icon svg-icon-sm svg-icon-${pinsColor} mark-up-video-pin border-${pinsColor}`
      "
    >
      <inline-svg src="media/svg/icons/Design/Circle.svg" />
    </span>
  </div>
</template>
<script>
export default {
  name: "MarkUpPins",

  props: {
    pinsColor: { type: String, required: true },
    maxY: { type: Number, required: true },
    maxX: { type: Number, required: true },
    maxTime: { type: Number, required: true },
    pin: { type: Object, required: true },
    isMarkUpImage: { type: Boolean, required: true }
  },

  data() {
    return {
      pinPositionStyle: `left: 0px; top: 0px; display:none;`
    };
  },

  watch: {
    pin: {
      handler() {
        this.setPinPosition();
      },
      deep: true
    },
    positionX: {
      handler() {
        this.setPinPosition();
      },
      deep: true
    },
    positionY: {
      handler() {
        this.setPinPosition();
      },
      deep: true
    },
    maxTime: {
      handler() {
        this.setPinPosition();
      },
      deep: true
    }
  },

  mounted() {
    this.setPinPosition();
  },

  methods: {
    setPinPosition() {
      if (this.isMarkUpImage) {
        let width = this.positionX - 26;
        let height = this.positionY - 52;
        return (this.pinPositionStyle = `left: ${width}px; top: ${height}px;`);
      } else {
        let leftPos = Math.floor((this.pin.timestamp / this.maxTime) * 491);
        return (this.pinPositionStyle = `left: ${leftPos}px; top: 0px;`);
      }
    },
    getSecondsToTimeString(number, needHours = false) {
      var sec_num = parseInt(number, 10); // don't forget the second param
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - hours * 3600) / 60);
      var seconds = sec_num - hours * 3600 - minutes * 60;

      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return needHours
        ? hours + ":" + minutes + ":" + seconds
        : minutes + ":" + seconds;
    },
    clickedPin() {
      let pin = {
        responseId: this.responseId,
        pinId: this.pin.id
      };
      this.$emit("clickedPin", pin);
    }
  },

  computed: {
    responseId() {
      return this.pin.activity_markup_response_id;
    },
    positionX() {
      let ratio_x = this.pin.pin_x;
      let X = ratio_x * this.maxX;
      return X;
    },
    positionY() {
      let ratio_y = this.pin.pin_y;
      let Y = ratio_y * this.maxY;
      return Y;
    },
    pinTime() {
      if (this.pin.timestamp !== null && !this.isMarkUpImage) {
        let time = this.getSecondsToTimeString(this.pin.timestamp);
        return time;
      } else {
        return "00:00";
      }
    }
  }
};
</script>

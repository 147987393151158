<template>
  <div class="row d-flex justify-content-between mx-0 mt-10 pl-5 pr-0">
    <p v-if="responseCount > 0" class="mb-0 text-muted pt-4">Displaying {{ responseCount }} Responses</p>
    <InputGenericDropdown
      v-if="responseCount > 0"
      id="input-stream-sort"
      class="col-4"
      :model="$v.filter.sort"
      :options="sortOptions"
      optionLabel="name"
      value="id"
      :errorMessage="$t('FORMS.ERROR.REQUIRED')"
    >
    </InputGenericDropdown>
    <div v-if="responseCount == 0 && loadingComplete && !pageLoader.fetchingMoreResponses && !pageLoader.reloadingResponses" class="w-100">
      <b-alert show variant="light">
        <div class="text-center">
          No Responses Found
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import { mapGetters } from  "vuex";
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import InputGenericDropdown from "@/modules/together-helpers/components/generic-inputs/GenericDropdown";

export default {
  mixins: [validationMixin],

  name: "StreamSort",

  props: {
    pageLoader: { type: Object, required: true },
    loadingComplete: { type: Boolean, required: true }
  },

  components: {
    InputGenericDropdown
  },

  validations: {
    filter: {
      sort: { required },
    }
  },

  computed: {
    ...mapGetters([
      'streamResults'
    ]),

    ...mapState({
      filter: state => state.projectStream.filter
    }),

    responseCount: function() {
      let count = 0;

      this.streamResults.responses.forEach(function(chunk) {
        count += chunk.length;
      });

      return count;
    },

    sortOptions: function() {
      return [
        {id: 1, name: "Newest First"},
        {id: 2, name: "Oldest First"},
        {id: 3, name: "Most Liked"},
      ]
    }
  }
}
</script>
<template>
  <div>
    <b-dropdown
      :text="computedButtonText"
      variant="outline-primary"
      :class="calculatedClassList"
      size="sm"
      v-b-tooltip.hover.top
      :title="$t('REVIEW.STREAM.FILTER_TAGS_TOOLTIP')"
      no-caret
      :disabled="reloadingResponses"
    >
      <input
        v-model="search"
        type="text"
        :placeholder="$t('GENERAL.INPUT.SEARCH')"
        class="form-control form-control-solid py-3 px-5 mb-5"
        style="height:35px !important; margin-top:4px !important"
      />
      <div :style="'height:' + calculatedScrollHeight + 'px'">
        <vuescroll>
          <template v-for="(projectTag, i) in computedTags">
            <b-form-group
              :key="i"
              class="mb-0 mx-2"
              :id="'project-tag-check-group' + projectTag.id"
              :label="projectTag.tag"
              :label-sr-only="true"
              :label-for="'project-tag-check-group' + projectTag.id"
            >
              <b-form-checkbox
                :id="'project-tag-check' + projectTag.id"
                value="1"
                unchecked-value="0"
                size="lg"
                @change="emitChange($event, projectTag)"
              >
                <p class="pt-1 mb-2">{{ projectTag.tag }}</p>
              </b-form-checkbox>
            </b-form-group>
          </template>
        </vuescroll>
      </div>
      <p v-if="computedTags.length == 0" class="mx-2">{{ $t('REVIEW.STREAM.NO_SEARCH_PLACEHOLDER') }}</p>
      <div class="w-100 d-flex justify-content-center mt-3">
        <a
          v-if="tagCount > 0"
          href="javascript:void(0)"
          @click="clearTags()"
          class="text-center font-size-sm"
        >
          {{ $t('REVIEW.TEXT_ANALYSIS.RESET') }}
        </a>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from  "vuex";
import vuescroll from 'vuescroll';

export default {
  name: "TagSelect",

  props: {
    filter: { type: Object, required: true },
    reloadingResponses: { type: Boolean, required: true }
  },

  data() {
    return {
      search: "",
      checkedTags: []
    }
  },

  components: {
    vuescroll
  },

  methods: {
    clearTags: function() {
      this.checkedTags.forEach(function (tagId) {
        document.getElementById('project-tag-check' + tagId).click();
      });
      this.$emit('tag_filter_changed',[]);
    },
    emitChange: function(event, projectTag)
    {
      if (event == 1) {
        let payload = {
          id: projectTag.id,
          display: projectTag.tag
        };
        let newTagArray = this.filter.tags;
        newTagArray.push(payload);
        this.$emit('tag_filter_changed',newTagArray)
        this.checkedTags.push(projectTag.id);
      } else {
        let newTagArray = this.filter.tags.filter(function( obj ) { return obj.id !== projectTag.id });
        this.$emit('tag_filter_changed',newTagArray);
        const index = this.checkedTags.indexOf(projectTag.id);
        if (index > -1) {
          this.checkedTags.splice(index, 1);
        }
      }
    }
  },

  watch:{
    'filter.reset': function (newVal, oldVal){
      if (oldVal == false && newVal == true) {
        this.clearTags();
      }
    }
  },

  computed: {
    ...mapState({
      legacy: state => state.projectStream.legacy
    }),
    ...mapGetters([
      "projectTagList",
      "projectCustomTagList",
      "legacyProjectCustomTagList",
      "projectEngagementTagList"
    ]),
    computedButtonText: function() {
      if (this.filter.tags.length == 0) {
        return `${this.$t('REVIEW.STREAM.FILTER_TAGS')}`;
      } else if (this.filter.tags.length == 1) {
        return this.filter.tags[0].display;
      } else {
        return this.filter.tags.length + ' ' + `${this.$t('REVIEW.STREAM.FILTER_TAGS')}`;
      }
    },
    tagCount: function() {
      return this.filter.tags.length;
    },
    calculatedClassList: function() {
      let classList = "";

      if (this.computedTags.length == 0 || this.computedTags.length == 1) {
        classList = classList + "sf-height-1";
      } else if (this.computedTags.length > 6) {
        classList = classList + "sf-height-6";
      } else {
        classList = classList + "sf-height-" + this.computedTags.length;
      }

      if (this.tagCount == 0) {
        classList = classList + " null";
      } else {
        classList = classList + " selected";
      }

      return classList;
    },
    calculatedScrollHeight: function() {
      if (this.computedTags.length == 1 || this.computedTags.length == 0) {
        return 28.75;
      } else if (this.computedTags.length >= 6) {
        return 28.75 * 6;
      } else {
        return this.computedTags.length * 28.75;
      }
    },
    computedTags: function() {
      let computedTags = this.legacy ? [] : this.projectTagList;

      let customTagCount = 1;
      let engagementTagCount = 1;

      if (this.legacy) {
        this.legacyProjectCustomTagList.forEach(customTag => {
          if (computedTags.filter(e => e.tag === customTag).length == 0) {
            computedTags.push({id: 'customTag' + customTagCount, tag: customTag});
            customTagCount++;
          }
        });
      } else {
        this.projectCustomTagList.forEach(customTag => {
          if (computedTags.filter(e => e.tag === customTag).length == 0) {
            computedTags.push({id: 'customTag' + customTagCount, tag: customTag});
            customTagCount++;
          }
        });

        this.projectEngagementTagList.forEach(engagementTag => {
          if (computedTags.filter(e => e.tag === engagementTag.value).length == 0) {
            computedTags.push({id: `engagementTag` + engagementTagCount, tag: engagementTag.value});
            engagementTagCount++;
          }
        })
      }
           

      let search = this.search;
      if (search.length == 0) {
        return computedTags;
      } else {
        return computedTags.filter(function( tag ) {
          return tag.tag.toLowerCase().includes(search.toLowerCase())
        });
      }
    }
  },
}
</script>

<template>
  <div
    class="d-flex flex-column mb-5 px-0"
    :class="{ 'align-items-end ml-15' : isMyNote, 'align-items-start mr-15' : !isMyNote }"
  >
    <div class="px-2">
      <div class="d-flex align-items-center" v-if="isMyNote">
        <a class="text-dark-75 text-hover-primary font-weight-bold mr-3">
          <AnonymisedUsername :user="note.user"></AnonymisedUsername>
        </a>
        <UserThumbnail :user="note.user" size="35"></UserThumbnail>
      </div>
      <div class="d-flex align-items-center" v-else>
        <UserThumbnail :user="note.user" size="35"></UserThumbnail>
        <a class="text-dark-75 text-hover-primary font-weight-bold ml-3">
          <AnonymisedUsername :user="note.user"></AnonymisedUsername>
        </a>
      </div>
    </div>
    <div
      class="mt-2 rounded p-5 font-size-sm w-100 text-left"
      :class="{ 'bg-primary text-white' : isMyNote, 'bg-light text-dark-50' : !isMyNote }"
    >
      {{ note[(newSchema) ? 'value' : 'note'] }}
    </div>
    <div class="d-flex justify-content-between w-100 px-2 pt-1">
      <a
        v-if="isMyNote"
        href="javascript:void(0)"
        @click="(newSchema) ? deleteNewSchemaNote() : deleteNote()"
        class="text-hover-danger font-size-sm text-danger"
      >
        Delete
      </a>
      <p class="mb-0 font-size-sm text-muted">
        <GenericDatetimeDisplay
          :long="true"
          :date="note.created_at"
          :createdAt="true"
          :lastUpdated="false"
          :relative="true"
        >
        </GenericDatetimeDisplay>
      </p>
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapActions
} from "vuex";

import UserThumbnail from "@/modules/together-helpers/components/generic-displays/GenericUserThumbnail";
import GenericDatetimeDisplay from "@/modules/together-helpers/components/generic-displays/GenericDatetimeDisplay"

export default {
  name: 'Note',

  props: {
    note: { type: Object, required: true },
    activityId: { type: Number, required: true },
    newSchema: { type: Boolean, required: false, default: false },
    baseResponse: { type: Object, required: false, default: null }
  },

  components: {
    UserThumbnail,
    GenericDatetimeDisplay
  },

  methods: {
    deleteNote: function() {
      let payload = {
        activityId: this.activityId,
        activityNoteEngagementId: this.note.id
      };

      this.deleteNoteEngagements(payload)
        .then(() => {
          this.$emit('note-removed',this.note.id);
        })
        .finally(() => {
          this.submissionStates.submitting = false;
        })
    },
    deleteNewSchemaNote: function() {
      let payload = {
        engagement_response_id: this.baseResponse.id,
        engagement_interaction_id: this.note.id
      };

      this.deleteEngagementInteraction(payload)
        .then(() => {
          this.$emit('note-removed', this.note.id);
        }).finally(() => {
          this.submissionStates.submitting = false;
        })
    },
    ...mapActions({
      deleteNoteEngagements: "deleteNoteEngagements",
      deleteEngagementInteraction: "deleteEngagementInteraction"
    })
  },

  computed: {
    ...mapGetters(["currentUser"]),

    isMyNote: function() {
      return (this.note.user_id == this.currentUser.id) ? true : false;
    }
  }
};
</script>

<template>
  <b-form-checkbox
    class="checkbox"
    v-model="filter.new"
    name="check-button"
    button
    button-variant="outline-primary"
    size="sm"
    v-b-tooltip.hover.top
    :title="$t('REVIEW.STREAM.FILTER_NEW_TOOLTIP')"
    :disabled="reloadingResponses || isLegacy"
  >
    {{ $t('REVIEW.STREAM.FILTER_NEW') }}
  </b-form-checkbox>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "NewToggle",
  
  props: {
    filter: { type: Object, required: true },
    reloadingResponses: { type: Boolean, required: true }
  },

  computed: {
    ...mapState({
      legacy: state => state.projectStream.legacy
    }),
    isLegacy: function() {
      if (this.legacy == 1) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<template>
  <div
    v-on:mouseover="isHovering = true"
    v-on:mouseleave="isHovering = false"
    style="max-width:150px"
  >
    <a
      href="javascript:void(0)"
      class="symbol symbol-45 symbol-light"
      @click="openLightbox"
    >
      <div class="symbol-label" style="width:150px; height:150px">
        <img
          :src="calculatedLink"
          style="width:150px; height:150px; object-fit:cover"
        />
      </div>
    </a>
    <div v-if="showToolbar" class="row d-flex justify-content-between mx-0">
      <div class="col-xl-9 px-0">
        <p class="font-size-sm">{{ projectAsset.original_filename }}</p>
      </div>
      <div class="col-xl-3 px-0 text-right">
        <a
          href="javascript:void(0)"
          @click="emitThumbDeletion()"
          v-if="isHovering"
        >
          <i class="fas fa-times-circle text-hover-danger"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageThumb",

  props: ["projectAsset", "index", "showToolbar"],

  data() {
    return {
      isHovering: false
    };
  },

  methods: {
    openLightbox: function() {
      this.$emit("open_lightbox", this.index);
    },
    emitThumbDeletion: function() {
      let payload = {
        index: this.index,
        projectAssetId: this.projectAsset.id,
        filename: this.projectAsset.original_filename
      };
      this.$emit("thumb_deleted", payload);
    }
  },

  computed: {
    calculatedLink: function() {
      if (this.projectAsset.signedAvatarUrl != undefined) {
        return this.projectAsset.signedAvatarUrl;
      } else {
        return this.projectAsset.signedUrl;
      }
    }
  }
};
</script>

<template>
  <div class="p-5">
    <div class="pieChart p-10" ref="singleChoice"></div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

export default {
  name: "ResponseChartSingleChoice",

  props: {
    chartData: { type: Array, required: true },
    index: { type: Number, required: true }
  },

  mounted() {
    this.generateChart();
  },

  methods: {
    generateChart: function() {
      let chart = am4core.create(this.$refs.singleChoice, am4charts.PieChart);

      let pieSeries = chart.series.push(new am4charts.PieSeries());

      pieSeries.dataFields.value = "value";
      pieSeries.dataFields.category = "choice";

      pieSeries.labels.template.disabled = true;
      pieSeries.ticks.template.disabled = true;

      var colorSet = new am4core.ColorSet();

      colorSet.list = ["#00297a", "#2acdff", "#e40049", "#b6003a", "#1BC5BD"].map(function(color) {
        return new am4core.color(color);
      });

      pieSeries.colors = colorSet;

      chart.data = this.chartData;

      chart.legend = new am4charts.Legend();
      
      this.$emit('chart-generated',chart);
      this.chartAdd({ index: this.index, chart: chart });
    },
    ...mapMutations({
      chartAdd: 'addToChart',
    }),
  }
}
</script>

<style scoped>
  .pieChart {
    width: 100%;
    height: 500px;
  }
</style>
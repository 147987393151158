<template>
  <div v-if="showContainer" :id="'markup-container-pin-' + index" class="d-flex justify-content-center stream-response-mark-up">
    <div class="d-flex justify-content-center flex-wrap">
      <div class="w-100 mb-0 d-flex justify-content-center position-relative">
        <b-img
          :src="chartData.stimuli[0].projectAsset.signedUrl"
          style="object-fit:cover"
          :style="computedStyle"
        ></b-img>

        <div class="mark-up-image-overlay" :style="computedStyle">
          <MarkUpPins
            v-for="(pin, index) in chartData.responses"
            :key="index"
            :pinsColor="pinsColor(index)"
            :maxY="maxHeight"
            :maxX="maxWidth"
            :maxTime="0"
            :pin="pin"
            :isMarkUpImage="true"
            @clickedPin="displayPinDetail($event)"
          ></MarkUpPins>
        </div>
      </div>

      <div class="w-100 mb-5 d-flex" v-if="selectedPin !== false">
        <MarkUpPinsDetail
          :allQuestion="[chartData.question]"
          :pinDetail="getPinDetail(selectedPin)"
          :isMarkUpImage="true"
        ></MarkUpPinsDetail>
      </div>
    </div>
  </div>
</template>

<script>
import MarkUpPins from "@/view/components/stream-responses/components/MarkUpPins.vue";
import MarkUpPinsDetail from "@/view/components/stream-responses/components/MarkUpPinsDetail.vue";

export default {
  name: "MarkupPins",

  props: {
    chartData: { type: Object, required: true },
    index: { type: Number, required: true }
  },

  data() {
    return {
      showContainer: false,
      selectedPin: false,
      maxWidth: 0,
      maxHeight: 0
    }
  },

  components: {
    MarkUpPins,
    MarkUpPinsDetail
  },

  mounted() {
    this.showContainer = true;
    this.$nextTick(function() {
      this.calculateDimensions();
    })
  },

  methods: {
    calculateDimensions: function() {
      let container = document.querySelector('#markup-container-pin-' + this.index);
      let maxWidth = (container.offsetWidth / 2);
      if (this.chartData.stimuli[0].projectAsset.width > maxWidth) {
        let newRatio = maxWidth / this.chartData.stimuli[0].projectAsset.width;
        this.maxWidth = maxWidth
        this.maxHeight = this.chartData.stimuli[0].projectAsset.height * newRatio;
      } else {
        this.maxWidth = this.chartData.stimuli[0].projectAsset.width;
        this.maxHeight = this.chartData.stimuli[0].projectAsset.height;
      }
    },
    getSampleColor(number) {
      let sampleColor = [
        "primary",
        "success",
        "info",
        "warning",
        "danger",
        "dark"
      ];
      let index = parseInt(number) % sampleColor.length;
      let labelColor = sampleColor[index];
      return labelColor;
    },
    pinsColor(index) {
      return this.getSampleColor(index);
    },
    displayPinDetail(pinDetail) {
      if (this.selectedPin !== pinDetail.pinId) {
        this.selectedPin = pinDetail.pinId;
      } else {
        this.selectedPin = false;
      }
    },
    getPinDetail(pinId) {
      return this.chartData.responses.filter(function( response ) {
        return response.id == pinId;
      })[0];
    }
  },

  computed: {
    computedStyle: function() {
      return "width: " + this.maxWidth + "px; height: " + this.maxHeight + "px";
    },
  }
}
</script>
<template>
  <div v-if="showContainer" :id="'markup-container-heat-' + index" class="d-flex justify-content-center">
    <heatmapjs-vue
      :ref="'markup-plugin-' + index"
      class="heatmapjs-container"
      :max="100"
      :min="0"
      :data="pinData"
      :style="computedStyle"
    >
    </heatmapjs-vue>
  </div>
</template>

<script>
export default {
  name: "MarkupHeatmap",

  props: {
    chartData: { type: Object, required: true },
    index: { type: Number, required: true }
  },

  data() {
    return {
      showContainer: false,
      pinData: [],
      maxWidth: 0,
      maxHeight: 0
    }
  },

  mounted() {
    this.showContainer = true;
    this.$nextTick(function() {
      this.calculateDimensions();
      this.calculatePinData();
    })
  },

  methods: {
    calculateDimensions: function() {
      let container = document.querySelector('#markup-container-heat-' + this.index);
      let maxWidth = (container.offsetWidth / 2);
      if (this.chartData.stimuli[0].projectAsset.width > maxWidth) {
        let newRatio = maxWidth / this.chartData.stimuli[0].projectAsset.width;
        this.maxWidth = maxWidth
        this.maxHeight = this.chartData.stimuli[0].projectAsset.height * newRatio;
      } else {
        this.maxWidth = this.chartData.stimuli[0].projectAsset.width;
        this.maxHeight = this.chartData.stimuli[0].projectAsset.height;
      }
    },
    calculatePinData: function() {
      let data = [];

      this.chartData.responses.forEach(response => {
        let x = Math.round(this.maxWidth * response.pin_x);
        let y = Math.round(this.maxHeight * response.pin_y);

        let item = {
          x: x,
          y: y,
          value: 50,
          radius: 25
        }
        data.push(item);
      });

      this.pinData = data;
    }
  },

  computed: {
    computedStyle: function() {
      return "background-image: url('" + this.chartData.stimuli[0].projectAsset.signedUrl + "'); width: " + this.maxWidth + "px; height: " + this.maxHeight + "px; background-size: contain";
    },
  }

}
</script>
<template>
  <div class="p-5">
    <div class="rankingChart" ref="ranking"></div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

export default {
  name: "ResponseChartRanking",

  props: {
    chartData: { type: Array, required: true },
    index: { type: Number, required: true }
  },

  mounted() {
    this.generateChart();
  },

  methods: {
    generateChart: function() {
      let chart = am4core.create(this.$refs.ranking, am4charts.XYChart);
      chart.maskBullets = false;

      var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      var yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

      yAxis.dataFields.category = "rank";
      xAxis.renderer.minGridDistance = 40;
      xAxis.dataFields.category = "choice";

      xAxis.renderer.grid.template.disabled = true;
      yAxis.renderer.grid.template.disabled = true;
      xAxis.renderer.axisFills.template.disabled = true;
      yAxis.renderer.axisFills.template.disabled = true;
      yAxis.renderer.ticks.template.disabled = true;
      xAxis.renderer.ticks.template.disabled = true;

      yAxis.renderer.inversed = true;

      var series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.categoryY = "rank";
      series.dataFields.categoryX = "choice";
      series.dataFields.value = "value";
      series.columns.template.disabled = true;
      series.columns.template.fill = am4core.color("#00297a");
      series.sequencedInterpolation = true;
      series.defaultState.transitionDuration = 3000;

      var bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.tooltipText = "Choice: {choice}\nRank: {rank}\nValue: {value}";
      bullet.strokeWidth = 3;
      bullet.stroke = am4core.color("#ffffff");
      bullet.strokeOpacity = 0;

      bullet.adapter.add("tooltipY", (tooltipY, target) => {
        return -target.circle.radius + 1;
      })

      series.heatRules.push({property:"radius", target:bullet.circle, min:2, max:40});

      bullet.hiddenState.properties.scale = 0.01;
      bullet.hiddenState.properties.opacity = 1;

      var hoverState = bullet.states.create("hover");
      hoverState.properties.strokeOpacity = 1;

      chart.data = this.chartData;

      this.$emit('chart-generated',chart);
      this.chartAdd({ index: this.index, chart: chart });
    },
    ...mapMutations({
      chartAdd: 'addToChart',
    }),
  }
}
</script>

<style scoped>
  .rankingChart {
    width: 100%;
    height: 500px;
  }
</style>